import 'gentelella/vendors/datatables.net-bs/css/dataTables.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-buttons-bs/css/buttons.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-fixedheader-bs/css/fixedHeader.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-responsive-bs/css/responsive.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-scroller-bs/css/scroller.bootstrap.min.css';

import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'datatables.net-fixedheader';
import 'datatables.net-keytable';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'datatables.net-scroller';

import React, { Component } from "react";
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ITEMS_PER_PAGE } from "../components/Constants";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router';
import RingLoaderComponent from '../components/RingLoaderComponent';


export default class NotificationList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
        }
    }
    componentWillMount() {
        this._loadList();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    componentDidMount() {
        $(document).trigger('TInitPanelToolBox');
    }
    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`./admin/admin_notifications/${ITEMS_PER_PAGE}/${this.state.activePage}`, {

                /* params: {
                    // status: this.state.status,
                    type: this.state.type,
                    q: this.state.q,
                } */
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            this.setState({
                loading: false,
                items: response.data.data.notification,
                total: response.data.data.count
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    assignDriver(item) {
        if (item.orderId.order_type !== 'sb') {
            document.location.href = `./admin#/reports/orders/assigndriver/${item.orderId.orderId}/1`
        } else {
            document.location.href = `./admin#/reports/businessSubOrders/${item.orderId._id}`
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }
    _renderRow(item, i) {
        if (item.type) {
            if (item.type == "assign" || item.type == "verification" || item.type == "Friday Payment Release.") {
                return (
                    <tr key={i}>
                        <td className={"text-center"}>{item.title}</td>
                        <td className={"text-center"}>{moment(item.created_at).format("YYYY/MM/DD HH:mm")}</td>

                        {item.type == "assign" ?
                            <td>
                                    <Link className="btn btn-success" style={{ color: "black" }} to={{
                                        pathname: item.orderId.order_type !== 'sb' ? `/reports/orders/assigndriver/${item.orderId.orderId}` : `/reports/businessSubOrders/${item.orderId._id}`,
                                        state: { moduleNum: item.orderId.order_type !== 'sb' ? 1 : "", notification_id: item._id }
                                    }}>
                                        Assign Driver
                                    </Link>
                            </td> : item.type == "verification" ?
                                <td>
                                    {(item.user !== null && item.user !== undefined) ?
                                        <Link style={{ marginLeft: "10px" }} className="btn btn-success" to={`/drivers/${item.user._id}`}>View Profile</Link>
                                        :
                                        "-"
                                    }
                                </td> :
                                <td>-</td>
                        }
                    </tr>
                );
            }
        } else {
            return (
                <tr key={i}>
                    <td className={"text-center"}>{item.title}</td>
                    <td className={"text-center"}>{item.created_at ? `${moment(item.created_at).format("YYYY/MM/DD HH:mm")}` : "-"}</td>
                    <td className={"text-center"}>-</td>
                </tr>
            );
        }

    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="x_panel">
                            <div className="x_title">
                                <h2>Notification</h2>
                                <div className={"title_right"}>
                                    <div className={"col-md-2 col-sm-2 col-xs-12 form-group pull-right top_search"}>
                                        <div className={"form-group"}>
                                            <a href="#/notificationCreate" className="btn btn-success" >Create</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"x_content"}>
                                {this.state.total > 0 ? (
                                    <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th className={"text-center"}>Notification</th>
                                                <th className={"text-center"}>Date</th>
                                                <th className={"text-center"}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map((item, i) => {
                                                return this._renderRow(item, i);
                                            })}
                                        </tbody>
                                    </table>
                                ) :
                                    <h4 style={{ marginLeft: "700px" }}>No Data Found</h4>
                                }
                            </div>
                        </div>

                        {!this.state.loading && <div>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                items={Math.ceil(this.state.total / ITEMS_PER_PAGE)}
                                maxButtons={5}
                                activePage={this.state.activePage}
                                onSelect={this.handlePagination.bind(this)}
                            />
                        </div>}

                    </div>

                    {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoaderComponent loading={this.state.loading}/>
                    </div>}
                </div>
            </div>
        );
    }
};