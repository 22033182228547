import 'gentelella/vendors/datatables.net-bs/css/dataTables.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-buttons-bs/css/buttons.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-fixedheader-bs/css/fixedHeader.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-responsive-bs/css/responsive.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-scroller-bs/css/scroller.bootstrap.min.css';

import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'datatables.net-fixedheader';
import 'datatables.net-keytable';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'datatables.net-scroller';

import React, { Component,createRef } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ITEMS_PER_PAGE } from "../components/Constants";
import { Link } from 'react-router';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import StarRatings from 'react-star-ratings';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import { formatDate, parseDate } from 'react-day-picker/moment';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class ManageReviews extends Component {

    constructor(props) {
        super(props);
        this.datePickerFrom = createRef();
        this.datePickerTo = createRef();
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            status: "active",
            type: "user",
            q: "",
            rating: 0,
            items: [],
            filter: {
                start_date: null,
                end_date: null,
                start_date_str: null,
                end_date_str: null
            },
            breadcrumbObj:[
                {
                    title:'Manage Review',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentWillMount() {
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }

    componentDidMount() {
        this._loadList();
        $(document).trigger('TInitPanelToolBox');
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`./admin/reviews/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.result.error.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.reviews,
                total: response.data.data.count
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    

    async _searchList() {
        this.setState({
            loading: true,
        });
        try {
            if(this.state.filter.start_date_str != null && this.state.filter.end_date_str != null) {

            
            let response = await axios.get(`./admin/reviews/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                
                params: {
                    start_date:this.state.filter.start_date_str,
                    end_date:this.state.filter.end_date_str
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.result.error.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.reviews,
                total: response.data.data.count
            });
        } else if (this.state.filter.start_date_str == null && this.state.filter.end_date_str != null) {
            this.setState({
                loading: false
            });
            toast.error("Please select valid start date");
        } else if(this.state.filter.start_date_str != null && this.state.filter.end_date == null) {this.setState({
            loading: false
        });
            toast.error("Please select valid end date")
        } else if (this.state.filter.start_date_str == null && this.state.filter.end_date_str == null) {
            this.setState({
                loading: false
            });
            toast.error("Please select valid start date and end date");
        }

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    async _clearDate(){
        this.datePickerFrom.current.input.value = null;
        this.datePickerFrom.current.state.value = null;
        this.datePickerTo.current.state.value = null;
        this.datePickerTo.current.input.value = null;
        this.setState({
            filter:{
                start_date:null,
                end_date:null,
                start_date_str:null,
                end_date_str:null
            }
        })
        this._loadList();
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td className={"text-center"}>{item.owner ? item.owner : '-'}</td>
                <td className={"text-center"}>{moment(item.posted_date).format("YYYY/MM/DD")}</td>
                <td className={"text-center"}>{item.booking_id ? item.booking_id : '-'}</td>
                <td className={"text-center"}>
                    <StarRatings
                      rating={item.rating}
                      starRatedColor="#00E0B0"
                      numberOfStars={5}
                      name='rating'
                      starDimension="20px"
                   />
                </td>
                <td className={"text-center"}>
                    <Link to={`/managereviews/${item._id}`}><i className={"fa fa-eye"} /></Link>
                    <Button bsStyle="link" onClick={() => { this.deleteItem(item._id); }} ><i className={"fa fa-trash"} /></Button>

                </td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <div>
                <h2 style={{ marginTop: '10px' }}><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>
            </div>
        );
    }


    async deleteItem(id) {
        if (confirm('Delete this item?')) {
            this.setState({
                loading: true
            });
            try {
                let response = await axios.delete(`./admin/review/${id}`, {
                    params: {}
                });
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                }
                this._loadList();
            } catch (e) {
                this.setState({
                    loading: false
                });
                toast.error(e.message);
            }
        }
    }

    render() {
        var oneYearAgo = new Date();
        oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>

                                {this._renderTitle()}

                                <div className={"clearfix"}></div>
                                <div className={"title_right"}>
                                <div className={"col-lg-8 form-group pull-right"}>
                                    <div className={"row"}>
                                        <div className={"col-md-4"}>
                                            <div className={"form-group"}>
                                                <span className="InputFromTo-to">
                                                    <DayPickerInput
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        ref={this.datePickerFrom}
                                                        // value={this.state.filter.start_date_str}
                                                        formatDate={formatDate}
                                                        format={'YYYY/MM/DD'}
                                                        parseDate={parseDate}
                                                        placeholder={"YYYY/MM/DD"}
                                                        dayPickerProps={{
                                                            disabledDays: {
                                                                before: oneYearAgo,
                                                                after: Date.parse(this.state.filter.end_date) ? this.state.filter.end_date : new Date(),
                                                            },
                                                        }}
                                                        inputProps={{
                                                            className: "form-control col-md-7 col-xs-12"
                                                        }}
                                                        onDayChange={(from) => {
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    filter: {
                                                                        ...previousState.filter,
                                                                        start_date: from,
                                                                        start_date_str: moment(from).format("YYYY-MM-DD")
                                                                    }
                                                                };
                                                            });
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <div className={"form-group"}>
                                                <span className="InputFromTo-to">
                                                    <DayPickerInput
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        ref={this.datePickerTo}
                                                        // value={this.state.filter.end_date_str}
                                                        formatDate={formatDate}
                                                        format={'YYYY/MM/DD'}
                                                        parseDate={parseDate}
                                                        placeholder={"YYYY/MM/DD"}
                                                        dayPickerProps={{
                                                            disabledDays: {
                                                                before: Date.parse(this.state.filter.start_date) ? this.state.filter.start_date : oneYearAgo,
                                                                after: new Date(),
                                                            },
                                                        }}
                                                        inputProps={{
                                                            className: "form-control col-md-7 col-xs-12"
                                                        }}
                                                        onDayChange={(from) => {
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    filter: {
                                                                        ...previousState.filter,
                                                                        end_date: from,
                                                                        end_date_str:moment(from).format("YYYY-MM-DD")
                                                                    }
                                                                };
                                                            });
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"row"} style={{textAlign:"left"}}>
                                            <div className={"col-md-2"}>
                                               <Button onClick={(start, end) => this.setState({
                                                    activePage:1,
                                                },() => {this._searchList(start, end)})} style={{ marginLeft: '5px', marginBottom: "30px" }} bsStyle="success">Search</Button>
                                            </div>
                                            <div className={"col-md-2"}>
                                               <Button onClick={(start, end) => this.setState({
                                                    activePage:1,
                                                },() => {this._clearDate(start, end)})} style={{ marginLeft: '5px', marginBottom: "30px" }} bsStyle="success">Clear</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                              {this.state.items.length > 0 ? (
                            <div className={"x_content"}>
                            {/* {this.state.total > 0 ? ( */}
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th className={"text-center"}>User</th>
                                            <th className={"text-center"}>Posted Date</th>
                                            <th className={"text-center"}>Booking ID</th>
                                            <th className={"text-center"}>Ratings</th>
                                            <th className={"text-center"}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.items.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}
                                    </tbody>
                                </table>
                                   {/* ):
                                   <h4 style={{marginLeft:"700px"}}>No Data Found</h4>
                                } */}
                            </div>
                            ): <h4 style={{marginLeft:"700px"}}>No Data Found</h4>}
                        </div>
                        {!this.state.loading && <div>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                items={Math.ceil(this.state.total / ITEMS_PER_PAGE)}
                                maxButtons={5}
                                activePage={this.state.activePage}
                                onSelect={this.handlePagination.bind(this)}
                            />
                        </div>}
                    </div>
                    {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoaderComponent loading={this.state.loading}/>
                    </div>}
                </div>
            </div>
        );
    }

}