import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { InfoWindow } from 'google-maps-react';
import axios from 'axios';
import pin from '../../../images/contact_map_point.png'
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import RingLoaderComponent from '../components/RingLoaderComponent';

export async function DriverRequest(text, orderId, moduleNum, subOrderId, notification_id) {
  
  if (moduleNum === 2) {
    let response = await axios.post(`./admin/smallBusinessDrivers/${orderId}`, { driverId: [text._id], subOrderId: subOrderId, notification_id: notification_id ? notification_id : '' });
    if (!!response.data.errors && response.data.errors.length > 0) {
      response.data.errors.map((err, i) => {
        toast.error(err);
      });
    } else {
      toast.success("Driver has been assigned successfully.");
      window.location.href = './admin#/reports/businessOrders'
    }
  } else {

    let warnResponse = await axios.post(`./admin/drivers/${orderId}/${text._id}`, {notification_id: notification_id ? notification_id : ''});
    if (warnResponse.data.data.code == 0) {
      if (!!warnResponse.data.errors && warnResponse.data.errors.length > 0) {
        warnResponse.data.errors.map((err, i) => {
          toast.error(err);
        });
      } else {
        if (confirm(warnResponse.data.data.message)) {
          let response = await axios.post(`./admin/drivers/${orderId}`, [text._id], {notification_id: notification_id ? notification_id : ''})
          if (!!response.data.errors && response.data.errors.length > 0) {
            response.data.errors.map((err, i) => {
              toast.error(err);
            });
          } else {
            toast.success("Driver has been assigned successfully.");
            window.location.href = './admin#/reports/orders'
          }
        }
      }
    } else {
      let response = await axios.post(`./admin/drivers/${orderId}`, [text._id], {notification_id: notification_id ? notification_id : ''})
      if (!!response.data.errors && response.data.errors.length > 0) {
        response.data.errors.map((err, i) => {
          toast.error(err);
        });
      } else {
        toast.success("Driver has been assigned successfully.");
        window.location.href = './admin#/reports/orders'
      }
    }
  }
}

const AnyReactComponent = ({ text, orderId, moduleNum, subOrderId, notification_id}) => {

  return (
    <div className="contact">
      <img style={{ width: "30px", height: "35px" }} src={pin} />
      <div className="driver-detail">
        <h4>{text.first_name}</h4>
        <p>{text.vehicle.type ? text.vehicle.type.name : ''} : <span>{text.vehicle ? text.vehicle.name : ''}-{text.vehicle ? text.vehicle.number : ''}</span></p>
        <i className="fa fa-star"><span>{text.rating}</span></i>
        <div className="button-wrapper">
          <Button type="button" onClick={() => DriverRequest(text, orderId, moduleNum, subOrderId, notification_id)}>Request Driver</Button>
        </div>
      </div>
    </div>
  )
};

class DriverAssign extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      first_name: [],
      loading: true,
      loadingOne: false,
      user_location: {
        center: {
          lat: 34.052235,
          lng: -118.243683
        },
        zoom: 11
      }
    }
  }

  static defaultProps = {
    center: {
      lat: 34.052235,
      lng: -118.243683
    },
    zoom: 11
  };

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (this.state.loadingOne == false) {
            this.setState({
              user_location: {
                center: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                }
              },
              loadingOne: false,
              loading: false
            });

          }
        }, () => {
          this.setState({
            loadingOne: true,
            loading: false
          })
        });
    }
    this.driverList()
  }

  async driverList() {
    let ID = this.props.ID
    let response = await axios.get(`./admin/map_drivers/${ID}`)
    this.setState({
      items: response.data.data.map((driver) => driver),
    });


  }

  render() {
    const marker = this.state.items;
    let coordinates = this.state.user_location.center;
    return (
      <div>
        <div className={"row"}>
          <div className={"col-md-12 col-sm-12 col-xs-12"}>
            <div className={"x_panel"}>
              <div className={"x_content"}>
                <div style={{ height: '100vh', width: '100%' }}>
                  {this.state.loading == false && <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_KEY }}
                    defaultCenter={this.state.loadingOne == true ? this.props.center : coordinates}
                    defaultZoom={this.props.zoom}
                  >
                    {marker.map((m, i) => {
                      return <AnyReactComponent lat={m.location.coordinates[1]} lng={m.location.coordinates[0]} key={i} text={m} orderId={this.props.ID} moduleNum={this.props.moduleNumber} subOrderId={this.props.subOrderId} notification_id={this.props.notification_id ? this.props.notification_id : ''} />
                    }
                    )}
                  </GoogleMapReact>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading && <div className={"css-jxiqlq-wrap"}>
        <RingLoaderComponent loading={this.state.loading}/>
        </div>}
      </div>
    );
  }
}

export default DriverAssign