import React, { Component, createRef } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination, } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import { ITEMS_PER_PAGE } from "../components/Constants";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import DriverAssign from './AssignDriver';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class AssignDriversList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            q: "",
            items: [],
            vehicleItems: [],
            kmStatus: "",
            vehicle_type: "",
            switchtab: 1,
            signleValue: 1,
            filter: {
                status: "1",
            },
            _id: [],
            breadcrumbObj: [
                {
                    title: this.props.location.state.moduleNum == 1 ? 'Manage Bookings' : 'Manage Business Bookings ',
                    breadCrumbLink: this.props.location.state.moduleNum == 1 ? './admin#/reports/orders/' : './admin#/reports/businessOrders',
                    active: false
                },
                {
                    title: 'Assign Driver',
                    breadCrumbLink: '',
                    active: true

                }
            ]

        };
    }

    componentWillMount() {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    componentDidMount() {
        this.vehicleTypeList();
        this._loadList();
    }




    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let ID = this.props.params.id
            let response = await axios.get(`./admin/drivers/${ID}/${ITEMS_PER_PAGE}/${this.state.activePage}`);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            this.setState({
                loading: false,
                items: response.data.data.drivers,
                total: response.data.data.count
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    async bulkAssign(e) {
        this.setState({
            loading: true
        });
        try {
            let orderId = this.props.params.id
            this.state.releaseDisable = true;
            const ID = e ? e._id.split(" ") : this.state._id
            if (e && this.state.signleValue == 0) {
                if (this.props.location.state.moduleNum === 2) {
                    let response = await axios.post(`./admin/smallBusinessDrivers/${orderId}`, { driverId: ID, subOrderId: this.props.location.state.subOrderId, notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : '' });
                    if (!!response.data.errors && response.data.errors.length > 0) {
                        response.data.errors.map((err, i) => {
                            toast.error(err);
                        });
                    } else {
                        setTimeout(() => {
                            // window.location.reload()
                            this.setState({
                                loading: true
                            });
                            history.push("/reports/businessOrders")
                        }, 3000)
                        toast.success("Driver has been assigned successfully.");
                    }
                } else {
                    let response = await axios.post(`./admin/drivers/${orderId}/${ID}`, {notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : ''});
                    if (!!response.data.errors && response.data.errors.length > 0) {
                        response.data.errors.map((err, i) => {
                            toast.error(err);
                        });
                    } else {
                        if (response.data.data.code == 0) {
                            if (confirm(response.data.data.message)) {
                                let response = await axios.post(`./admin/drivers/${orderId}`, ID,{notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : ''})
                                if (!!response.data.errors && response.data.errors.length > 0) {
                                    response.data.errors.map((err, i) => {
                                        toast.error(err);
                                    });
                                } else {
                                    setTimeout(() => {
                                        // window.location.reload()
                                        this.setState({
                                            loading: true
                                        });
                                        history.push("/reports/orders/")
                                    }, 3000)
                                    toast.success("Driver has been assigned successfully.");
                                }
                            } else {
                                this.setState({
                                    loading: false
                                });
                            }
                        } else {
                            let response = await axios.post(`./admin/drivers/${orderId}`, ID, {notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : ''})
                            if (!!response.data.errors && response.data.errors.length > 0) {
                                response.data.errors.map((err, i) => {
                                    toast.error(err);
                                });
                            } else {
                                setTimeout(() => {
                                    // window.location.reload()
                                    this.setState({
                                        loading: true
                                    });
                                    history.push("/reports/orders/")
                                }, 3000)
                                toast.success("Driver has been assigned successfully.");
                            }
                        }
                    }
                }
            } else {
                if (ID.length > 0) {
                    if (this.props.location.state.moduleNum === 2) {
                        let response = await axios.post(`./admin/smallBusinessDrivers/${orderId}`, { driverId: ID, subOrderId: this.props.location.state.subOrderId, notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : '' });
                        if (!!response.data.errors && response.data.errors.length > 0) {
                            response.data.errors.map((err, i) => {
                                toast.error(err);
                            });
                        } else {
                            setTimeout(() => {
                                // window.location.reload()
                                this.setState({
                                    loading: true
                                });
                                history.push("/reports/businessOrders")
                            }, 3000)
                            toast.success("Driver has been assigned successfully.");
                        }
                    } else {
                        let response = await axios.post(`./admin/drivers/${orderId}`, ID, {notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : ''})
                        if (!!response.data.errors && response.data.errors.length > 0) {
                            response.data.errors.map((err, i) => {
                                toast.error(err);
                            });
                        } else {
                            setTimeout(() => {
                                // window.location.reload()
                                this.setState({
                                    loading: true
                                });
                                history.push("/reports/orders/")
                            }, 3000)
                            toast.success("Driver has been assigned successfully.");
                        }

                    }
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.error("Please select driver!")
                }
            }
        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    async vehicleTypeList() {
        let response = await axios.get(`./admin/vehicles`);
        if (!!response.data.errors && response.data.errors.length > 0) {
            response.data.errors.map((err, i) => {
                toast.error(err);
            });
        }

        this.setState({
            vehicleItems: response.data.data,
        });
    }

    checkid = (e, value) => {
        if (e.target.checked) {
            //append to array
            this.setState(previousState => {
                return {
                    _id: [...previousState._id, value]
                }
            })
        } else {
            //remove from array
            this.setState({
                _id: this.state._id.filter(function (val) { return val !== value })
            })
        }
    }
    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td>
                    <input
                        type="checkbox"
                        key={item._id}
                        onChange={(e) => this.checkid(e, item._id)}
                    />
                </td>
                <td>{item.first_name ? item.first_name : '-'}</td>
                <td>{item.vehicle.name ? item.vehicle.name : '-'}</td>
                <td>{item.vehicle.number ? item.vehicle.number : '-'}</td>
                <td>{item.vehicle.type ? item.vehicle.type.name : '-'}</td>
                <td>{item.miles ? item.miles : '-'}</td>
                <td>{<Button type="button" onClick={() => { this.state.signleValue = 0; this.bulkAssign(item); }} bsStyle="success">Request Driver</Button>}</td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
        );
    }

    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>
                            {this._renderTitle()}
                            <div className={"clearfix"}></div>
                            <div className={"title_right"}>
                                <div className={"col-lg-12 form-group pull-right no-padding"} style={{ textAlign: "right", maxWidth: "100%" }}>

                                    <div className={"row"}>
                                        {this.state.switchtab == 1 && <div className='col-md-4' style={{ textAlign: "left" }} >
                                            <button style={{ marginLeft: "0", marginTop: "10px" }} type='button' onClick={() => { this.state.signleValue = 1; this.bulkAssign(); }} className='btn btn-success'>Bulk Assign</button>
                                        </div>}
                                        {/* <div className={"col-md-4 no-padding"}>
                                        <div className={"form-group"} style={{ display:"flex",alignItems:"center",justifyContent:"end" }}>
                                                <label style={{ margin: "0",paddingRight:"15px",color:"white",fontSize:"16px"}}>Vehicle Filter</label>
                                            <select
                                                value={this.state.vehicle_type}
                                                onChange={event => {
                                                    event.persist();
                                                    this.setState(previousState => {
                                                        return {
                                                            ...previousState,
                                                            activePage: 1,
                                                            vehicle_type: event.target.value
                                                        };
                                                    }, () => {
                                                        this._loadList()
                                                    })
                                                }}
                                            >
                                                <option value="">All</option>
                                                {this.state.vehicleItems.map((vehicleData, index) => {
                                                   return <option value={vehicleData._id} key={index}>{vehicleData.name}</option>
                                                })}

                                            </select>
                                        </div>
                                    </div> */}
                                        {/* <div className={"col-md-4 no-padding"}>
                                        <div className={"form-group"} style={{ display:"flex",alignItems:"center",justifyContent:"end" }}>
                                                <label style={{ margin: "0",paddingRight:"15px",color:"white",fontSize:"16px"}}>Miles Filter</label>
                                            <select
                                                value={this.state.kmStatus}
                                                onChange={event => {
                                                    event.persist();
                                                    this.setState(previousState => {
                                                        return {
                                                            ...previousState,
                                                            activePage: 1,
                                                            kmStatus: event.target.value
                                                        };
                                                    }, () => {
                                                        this._loadList()
                                                    })
                                                }}
                                            >
                                                <option value="">All</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                

                                            </select>
                                        </div>
                                    </div> */}
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-2 pull-right'>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6' style={{ textAlign: 'center', fontSize: "18px", color: "white", cursor: 'pointer', borderRight: "1px solid var(--gray)" }}>
                                    <div className={this.state.switchtab == 1 ? 'driveractiveTab' : 'drivernoActivetab'} onClick={() => {
                                        this.setState({
                                            switchtab: 1
                                        })
                                    }}>Assign Driver List</div>
                                </div>
                                <div className='col-md-6' style={{ textAlign: 'center', fontSize: "18px", color: "white", cursor: 'pointer' }}>
                                    <div className={this.state.switchtab != 1 ? 'driveractiveTab' : 'drivernoActivetab'} onClick={() => {
                                        this.setState({
                                            switchtab: 2
                                        })
                                    }}>Map Assign Driver</div>
                                </div>
                            </div>
                            <div className={"clearfix"}></div>
                        </div>

                        {this.state.switchtab == 1 ?
                            this.state.items.length > 0 ? (
                                <div className={"x_content"}>
                                    <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap table_scroll order_table"} cellSpacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Select</th>
                                                <th>Driver Name</th>
                                                <th>Vehicle Name</th>
                                                <th>Vehicle Number</th>
                                                <th>Vehicle Type</th>
                                                <th>Miles</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map((item, i) => {
                                                return this._renderRow(item, i);
                                            })}
                                        </tbody>
                                    </table>


                                </div>
                            ) : <h4 style={{ marginLeft: "700px" }}>No Data Found</h4> : <DriverAssign ID={this.props.params.id} moduleNumber={this.props.location.state.moduleNum} subOrderId={this.props.location.state.subOrderId} notification_id={this.props.location.state.notification_id ? this.props.location.state.notification_id : ''} />}
                    </div>

                    {(!this.state.loading && this.state.switchtab == 1) ? <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.ceil(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div> : ""}
                    {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoaderComponent loading={this.state.loading}/>
                    </div>}
                </div>
            </div>
        );
    }

}