import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { RingLoader } from 'react-spinners';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import countryCodeList from '../country-code-list.json';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';

export default class DriverFormPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            n: !!props.params.n,
            user: {
                first_name: "",
                email: "",
                full_number: " ",
                pay_type: "",
                phone: "",
                status: "active",
                country_isd_code: " ",
                new_balance: 0,
                new_courierLimit: 0,
                visible: false,
                in_progress: false,
                linxFee: 0,
                linx_business_commission: {
                    fee_type: 'amount',
                    fee_value: 0
                },
                vehicle: {
                    model: " ",
                    name: " ",
                    number: " ",
                    color: " ",
                    image_front: " ",
                    image_back: " ",
                    image_side: " ",
                    license_number: " ",
                    category:{
                        name: ""
                    },
                    type:{
                        name: "",
                    },
                },
                license:{
                    DOB:"",
                }
            },
            breadcrumbObj:[
                {
                    title:'Drivers',
                    breadCrumbLink:'./admin#/drivers',
                    active:false
                },
                {
                    title:'Edit Driver',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });

        if (!!this.state.id && this.state.id !== "new") {
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/user/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.data,
                        /* vehicle:{
                            compatibility: response.data.data.vehicle.category.name,
                            type: response.data.data.vehicle.type.name
                        } */
                    }
                };
            });
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _preUpload(event) {
        if (this.fileInput.files.length === 0) {
            return;
        }
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        let formdata = new FormData();
        formdata.append('image', this.fileInput.files[0]);
        try {
            let response = await axios.post(`./admin/ajax/uploads_img`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                this.fileInput.value = '';
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        user: {
                            ...previousState.user,
                            avatar_url: response.data.data.image_url,
                            avatar: response.data.data.image
                        }
                    };
                });
            }
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            this.state.user.new_courierLimit = parseFloat(this.state.user.new_courierLimit);
            this.state.user.new_balance = parseFloat(this.state.user.new_balance);
            this.state.user.linx_business_commission.fee_value = parseInt(this.state.user.linx_business_commission.fee_value);
            this.state.user.country_isd_code = this.state.user.country_isd_code;
            let response = await axios.post(`./admin/user/`, this.state.user);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                if (this.state.id === "new") {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item created!");
                        history.push('/users/' + response.data.data._id);
                    });
                } else {
                    if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                        response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
                    }
                    response.data.data.visible = !!response.data.data.visible;
                    response.data.data.in_progress = !!response.data.data.in_progress;
                    response.data.data.country_isd_code = this.state.user.country_isd_code;

                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Driver updated successfully");
                        history.push('/drivers')
                    });
                }
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _resetFee() {
        if (confirm("Reset Any Delivery fee ?")) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: true
                };
            });
            try {
                let response = await axios.post(`/admin/user/reset_linx_fee/${this.state.id}`, {});
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            }
                        };
                    }, () => {
                        toast.success("LINX fee reset!");
                    });
                }
            } catch (e) {
                if (!!e.response.data.errors && e.response.data.errors.length > 0) {
                    e.response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    toast.error(e.message);
                }
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false
                    };
                });
            }
        }
    }

    handleCountryCodeDrowdownChange(_e) {
        const { user } = this.state;
        user.country_isd_code = _e.target.value;
        this.setState({ user })

    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                <div className='col-md-2'>
                                <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>    
                                <h2>
                                    {this.state.user._id && <b>Driver ID : {this.state.user.linxid} </b>}
                                    {!this.state.user._id && <h2 className='badge'>New Driver</h2>}
                                </h2>
                                </div>
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left driver-vehicle-detail"}>

                                    <div className={"row"}>

                                        <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                            <div className={"col-md-6"}>
                                                <h2>Driver Detail</h2>

                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Full Name</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            type="text"
                                                            required="required"
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            value={this.state.user.first_name}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            first_name: event.target.value
                                                                        }
                                                                    };
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Email</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            name="email"
                                                            value={this.state.user.email}
                                                            onChange={event => {

                                                                event.persist();
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            email: event.target.value
                                                                        }
                                                                    };
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Country Code</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <select
                                                            value={this.state.user.country_isd_code}
                                                            onChange={(e) => { this.handleCountryCodeDrowdownChange(e) }}
                                                            name="country_isd_code"
                                                            className={"form-control col-md-7 col-xs-12"}
                                                        >

                                                            {countryCodeList.map((item, index) => {
                                                                return <option key={index} value={item.country_isd_code} >{item.country_isd_code}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Phone</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="number"
                                                            name="phone"
                                                            value={this.state.user.phone}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            phone: event.target.value
                                                                        }
                                                                    };
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Status</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <select
                                                            className={"form-control"}
                                                            value={this.state.user.status}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            status: event.target.value
                                                                        }
                                                                    };
                                                                })
                                                            }}
                                                        >
                                                            <option value="active">Active</option>
                                                            <option value="blocked">Inactive</option>
                                                            <option value="review">Review</option>

                                                        </select >
                                                    </div >
                                                </div >
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>DOB</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            name="DOB"
                                                            value={this.state.user.license.DOB ? this.state.user.license.DOB : '-'}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            DOB: event.target.value
                                                                        }
                                                                    };
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div >
                                            <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                                <h2>Vehicle Detail</h2>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Make</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            value={this.state.user.vehicle.name ? this.state.user.vehicle.name : '-'}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => ({
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            name: event.target.value
                                                                        }

                                                                    }

                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Identification Number</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            value={this.state.user.vehicle.number ? this.state.user.vehicle.number : '-'}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => ({
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            number: event.target.value
                                                                        }

                                                                    }

                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Model</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            value={this.state.user.vehicle.model ? this.state.user.vehicle.model : '-'}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => ({
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            model: event.target.value
                                                                        }

                                                                    }

                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Color</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            value={this.state.user.vehicle.color ? this.state.user.vehicle.color : '-'}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => ({
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            color: event.target.value
                                                                        }

                                                                    }

                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Licence plate</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            value={this.state.user.vehicle.license_number ? this.state.user.vehicle.license_number : '-'}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => ({
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            license_number: event.target.value
                                                                        }

                                                                    }

                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}> Type</label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            value={this.state.user.vehicle.type ? this.state.user.vehicle.type.name : "-" }
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => ({
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            type:{
                                                                                name: event.target.value
                                                                            } 
                                                                        }

                                                                    }

                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Compatibility </label>
                                                    <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                        <input
                                                            disabled
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="text"
                                                            value={this.state.user.vehicle.category ? this.state.user.vehicle.category.name : '-'}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => ({
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            category:{
                                                                                name:event.target.value
                                                                            } 
                                                                        }

                                                                    }

                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div >
                                        </div>
                                    </div >
                                    <div className={"form-group"}>
                                        <div className={"col-lg-12"} style={{ textAlign: "right", paddingTop: "20px" }}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                        </div>
                                    </div>

                                </form >
                            </div >
                        </div >
                    </div >
                </div >

                {/* {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoader
                            style={{ margin: 'auto' }}
                            color={'#0019bc'}
                            loading={this.state.loading}
                        />
                    </div>
                } */}
            </div >
        );
    }

}
