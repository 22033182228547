import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const chart_gauge_settings = {
    lines: 12,
    angle: 0,
    lineWidth: 0.4,
    pointer: {
        length: 0.75,
        strokeWidth: 0.042,
        color: '#1D212A'
    },
    limitMax: 'false',
    colorStart: '#1ABC9C',
    colorStop: '#1ABC9C',
    strokeColor: '#F0F3F3',
    generateGradient: true
};

export default class NowData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            couriers: 0,
            customers: 0,
            total: 0,
            je: 0,
            ce: 0,
            orders: 0,
            avarageCourierRating: 0,
            avarageCustomerRating: 0
        };
    }

    componentDidMount() {
        this._loadList();
    }

    init() {
        if (typeof (Chart) === 'undefined') { return; }


        if ($('.canvasDoughnut').length) {

            let chart_doughnut_settings = {
                type: 'doughnut',
                tooltipFillColor: "rgba(51, 51, 51, 0.55)",
                data: {
                    labels: [
                        "Any Delivery",
                        "Courier",
                    ],
                    datasets: [{
                        data: [this.state.je.toFixed(0), this.state.ce.toFixed(0)],
                        backgroundColor: [
                            "#3498DB",
                            "#1ABB9C",
                        ],
                        hoverBackgroundColor: [
                            "#3086c0",
                            "#15967d",
                        ]
                    }]
                },
                options: {
                    legend: true,
                    responsive: true,
                    tooltip: true,
                    labels: false
                }
            };

            $('.canvasDoughnut').each(function () {

                let chart_element = $(this);
                let chart_doughnut = new Chart(chart_element, chart_doughnut_settings);

            });

        }
        if (typeof (Gauge) === 'undefined') { return; }

        if ($('#chart_gauge_01').length) {

            let chart_gauge_01_elem = document.getElementById('chart_gauge_01');
            let chart_gauge_01 = new Gauge(chart_gauge_01_elem).setOptions(chart_gauge_settings);

            chart_gauge_01.maxValue = 5;
            chart_gauge_01.animationSpeed = 32;
            chart_gauge_01.set(this.state.avarageCourierRating);
            chart_gauge_01.setTextField(document.getElementById("gauge-text1"));

        }
        if ($('#chart_gauge_02').length) {

            let chart_gauge_02_elem = document.getElementById('chart_gauge_02');
            let chart_gauge_02 = new Gauge(chart_gauge_02_elem).setOptions(chart_gauge_settings);

            chart_gauge_02.maxValue = 5;
            chart_gauge_02.animationSpeed = 32;
            chart_gauge_02.set(this.state.avarageCustomerRating);
            chart_gauge_02.setTextField(document.getElementById("gauge-text2"));

        }
    }

    async _loadList() {
        this.setState({
            loading: true,
        });
        try {
            let response = await axios.get(`./admin/statistics/now`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                couriers: response.data.data.couriers,
                customers: response.data.data.customers,
                total: response.data.data.total,
                je: response.data.data.je,
                ce: response.data.data.ce,
                avarageCourierRating: response.data.data.avarageCourierRating,
                avarageCustomerRating: response.data.data.avarageCustomerRating,
                orders: response.data.data.orders
            });

            this.init();

        } catch (e) {
            this.setState({
                loading: false,
            });
            toast.error(e.message);
        }
    }

    render() {
        let percent_couriers = this.state.couriers / (this.state.total / 100);
        let percent_customers = this.state.customers / (this.state.total / 100);
        let w1 = {
            width: percent_couriers + "%"
        };
        let w2 = {
            width: percent_customers + "%"
        };
        let w3 = {
            width: (percent_customers + percent_couriers) + "%"
        };
        return (
            <div className={"row"}>

                {/* <div className={"col-md-4 col-sm-4 col-xs-12"}>
                    <div className={"x_panel tile fixed_height_320"}>
                        <div className={"x_content"}>
                            <h4>Activity users now</h4>
                            <div className={"widget_summary"}>
                                <div className={"w_left w_25"}>
                                    <span>Customers</span>
                                </div>
                                <div className={"w_center w_55"}>
                                    <div className={"progress"}>
                                        <div className={"progress-bar bg-green"} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={w2}>
                                            <span className={"sr-only"}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"w_right w_20"}>
                                    <span>{this.state.customers}</span>
                                </div>
                                <div className={"clearfix"}></div>
                            </div>

                            <div className={"widget_summary"}>
                                <div className={"w_left w_25"}>
                                    <span>Couriers</span>
                                </div>
                                <div className={"w_center w_55"}>
                                    <div className={"progress"}>
                                        <div className={"progress-bar bg-green"} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={w1}>
                                            <span className={"sr-only"}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"w_right w_20"}>
                                    <span>{this.state.couriers}</span>
                                </div>
                                <div className={"clearfix"}></div>
                            </div>

                            <div className={"widget_summary"}>
                                <div className={"w_left w_25"}>
                                    <span>All users</span>
                                </div>
                                <div className={"w_center w_55"}>
                                    <div className={"progress"}>
                                        <div className={"progress-bar bg-green"} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={w3}>
                                            <span className={"sr-only"}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"w_right w_20"}>
                                    <span>{this.state.couriers + this.state.customers}</span>
                                </div>
                                <div className={"clearfix"}></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={"col-md-4 col-sm-4 col-xs-12"}>
                    <div className={"x_panel tile fixed_height_320 overflow_hidden"}>
                        <div className={"x_content"}>
                            <table className={""} style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                    <th style={{width: "37%"}}>
                                        <p>Active orders now</p>
                                    </th>
                                    <th>
                                        <div className={"col-lg-12"}>
                                            <p className={""}>Orders: {this.state.orders}</p>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <canvas className={"canvasDoughnut"} height="140" width="140" style={{margin: 15}}></canvas>
                                    </td>
                                    <td>
                                        <table className={"tile_info"}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <p><i className={"fa fa-square blue"}></i>Any Delivery earning</p>
                                                </td>
                                                <td>{this.state.je.toFixed(2)} SAR</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><i className={"fa fa-square green"}></i>Courier earning</p>
                                                </td>
                                                <td>{this.state.ce.toFixed(2)} SAR</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className={"col-md-4 col-sm-4 col-xs-12"}>
                    <div className={"x_panel tile fixed_height_320"}>

                        <div className={"x_content"}>
                            <div className={"dashboard-widget-content"}>
                                <div className={"sidebar-widget"}>
                                    <h4>Average Customer Rating (now)</h4>
                                    <canvas width="150" height="80" id="chart_gauge_01" className={""} style={{width: 160, height: 100}}></canvas>
                                    <div className={"goal-wrapper"}>
                                        <span id="gauge-text1" className={"gauge-value pull-left"}>0</span>
                                        <span className={"gauge-value pull-left"}></span>
                                        <span id="goal-text1" className={"goal-value pull-right"}>5.00</span>
                                    </div>
                                </div>
                                <div className={"sidebar-widget"}>
                                    <h4>Average Courier Rating (now)</h4>
                                    <canvas width="150" height="80" id="chart_gauge_02" className={""} style={{width: 160, height: 100}}></canvas>
                                    <div className={"goal-wrapper"}>
                                        <span id="gauge-text2" className={"gauge-value pull-left"}>0</span>
                                        <span className={"gauge-value pull-left"}></span>
                                        <span id="goal-text2" className={"goal-value pull-right"}>5.00</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        );
    }
}