import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { RingLoader } from 'react-spinners';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import moment from "moment/moment";
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

const Name = {
    wait_accept_delivery: "on the way",
    in_progress: "in progress",
    finished: "completed",
    wait_pick_up: "wait for pick up",
    new: "new",
    hold: "schedule",
    canceled: "cancelled"

}

export default class OrderDetailPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            model: {},
            ready: false,
            breadcrumbObj: [
                {
                    title: 'Customers',
                    breadCrumbLink: './admin#/customers',
                    active: false
                },
                {
                    title: 'Order Details',
                    breadCrumbLink: '',
                    active: true
                }

            ]
        };
    }

    componentDidMount() {
        this._load();
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/orders/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.state.model = response.data.data;

            this.setState(previousState => {

                return {
                    ...previousState,
                    loading: false,
                    model: this.state.model,
                    ready: true
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <div className="row">
                                    {(this.state.ready && !!this.state.model && this.state.model.order_type === 'sb') &&
                                        this.state.model.small_business_recipient_orders.map((data, index) => (
                                            <div className="col-sm-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form data-parsley-validate className={"form-horizontal form-label-left"}>
                                                            <div className={"row"}>
                                                                <table className={"table table-striped table-bordered detail-view"}>
                                                                    <tbody>

                                                                        <tr>
                                                                            <th>Order id</th>
                                                                            <td>{this.state.model.orderId}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th>Full Name</th>
                                                                            <td>{this.state.model.owner_name}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th>Email</th>
                                                                            <td>{this.state.model.owner.email}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th>Order date & time</th>
                                                                            <td>{moment(this.state.model.created_at).format("YYYY/MM/DD")} - {moment(this.state.model.created_at).format("HH:mm")}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th>Pick Up</th>
                                                                            <td>{this.state.model.owner_address !== '' ? this.state.model.owner_address : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Final Destination</th>
                                                                            <td>{data.recipient_address !== '' ? data.recipient_address : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Shipping Destination</th>
                                                                            <td>
                                                                                {"-"}
                                                                            </td >
                                                                        </tr >

                                                                        <tr>
                                                                            <th>Driver Name</th>
                                                                            <td>{data.courier ? data.courier.first_name : "-"}</td>
                                                                        </tr >
                                                                        <tr>
                                                                            <th>Driver Vehicle Model</th>
                                                                            <td>{data.courier ? data.courier.vehicle.model : "-"}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th>Driver Fee</th>
                                                                            <td>{data.serviceFee ? `$${(data.serviceFee).toFixed(2)}` : '-'}</td>
                                                                        </tr>

                                                                        {/* <tr>
                <th>Package Size</th>
                <td>10*10*10</td>
            </tr> */}

                                                                        {/* <tr>
                <th>Cost of package	</th>
                <td>90</td>
            </tr> */}

                                                                        <tr>
                                                                            <th>Admin Fare</th>
                                                                            <td>{data.earning ? `$${(data.earning).toFixed(2)}` : '-'}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th>Total</th>
                                                                            <td>{data.cost ? `$${(data.cost).toFixed(2)}` : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Status</th>
                                                                            <td>{Name[this.state.model.status]}</td>
                                                                        </tr>
                                                                    </tbody >
                                                                </table >

                                                            </div >



                                                            {this.state.ready && !this.state.model && <h3>Info not found</h3>}

                                                            <div className={"ln_solid"}></div>
                                                            {/* <div className={"form-group"}>
                                                        <div className={"col-lg-6"}>
                                                            <Button onClick={() => {
                                                                history.go(-1);
                                                            }} bsStyle="success">Cancel</Button>
                                                        </div>
                                                        <div className={"col-lg-6"}>

                                                        </div>
                                                    </div> */}

                                                        </form >
                                                    </div>
                                                </div>
                                            </div>))}
                                </div>
                                <br />
                                {(this.state.ready && !!this.state.model && this.state.model.order_type !== 'sb') && <form data-parsley-validate className={"form-horizontal form-label-left"}>

                                    {this.state.ready && !!this.state.model && <div className={"row"}>

                                        <table className={"table table-striped table-bordered detail-view"}>
                                            <tbody>

                                                <tr>
                                                    <th>Order id</th>
                                                    <td>{this.state.model.orderId}</td>
                                                </tr>

                                                <tr>
                                                    <th>Full Name</th>
                                                    <td>{this.state.model.owner_name}</td>
                                                </tr>

                                                <tr>
                                                    <th>Email</th>
                                                    <td>{this.state.model.owner.email}</td>
                                                </tr>

                                                <tr>
                                                    <th>Order date & time</th>
                                                    <td>{moment(this.state.model.created_at).format("YYYY/MM/DD")} - {moment(this.state.model.created_at).format("HH:mm")}</td>
                                                </tr>

                                                <tr>
                                                    <th>Pick Up</th>
                                                    <td>{this.state.model.owner_address !== '' ? this.state.model.owner_address : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Final Destination</th>
                                                    <td>{this.state.model.recipient_address !== '' ? this.state.model.recipient_address : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Shipping Destination</th>
                                                    <td>
                                                        {this.state.model.shipping_store_address !== '' ? this.state.model.shipping_store_address : '-'}
                                                    </td >
                                                </tr >

                                                <tr>
                                                    <th>Driver Name</th>
                                                    <td>{this.state.model.courier ? this.state.model.courier.first_name : "-"}</td>
                                                </tr >

                                                <tr>
                                                    <th>Driver Vehicle Model</th>
                                                    <td>{this.state.model.courier ? this.state.model.courier.vehicle.model : "-"}</td>
                                                </tr>

                                                <tr>
                                                    <th>Driver Fee</th>
                                                    <td>{this.state.model.serviceFee ? `$${(this.state.model.serviceFee).toFixed(2)}` : '-'}</td>
                                                </tr>

                                                {/* <tr>
                                                    <th>Package Size</th>
                                                    <td>10*10*10</td>
                                                </tr> */}

                                                {/* <tr>
                                                    <th>Cost of package	</th>
                                                    <td>90</td>
                                                </tr> */}

                                                <tr>
                                                    <th>Admin Fare</th>
                                                    <td>{this.state.model.earning ? `$${(this.state.model.earning).toFixed(2)}` : '-'}</td>
                                                </tr>

                                                <tr>
                                                    <th>Total</th>
                                                    <td>{this.state.model.paid_amount ? `$${(this.state.model.paid_amount).toFixed(2)}` : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{Name[this.state.model.status]}</td>
                                                </tr>
                                            </tbody >
                                        </table >

                                    </div >}

                                    {this.state.ready && !this.state.model && <h3>Info not found</h3>}

                                    <div className={"ln_solid"}></div>
                                    {/* <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                        </div>
                                        <div className={"col-lg-6"}>

                                        </div>
                                    </div> */}

                                </form >}
                                <div className={"form-group"}>
                                    <div className={"col-lg-6"}>
                                        <Button onClick={() => {
                                            history.go(-1);
                                        }} bsStyle="success">Cancel</Button>
                                    </div>
                                    <div className={"col-lg-6"}>

                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}