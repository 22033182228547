import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import axios from "axios";
import CustomBreadCrumbs from "../components/CustomBreadcrumbs";

export default class EditSearchRadius extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            radiusmile: 5,
            adminSearchRadiusMile: 100,
            radiusValidation: false,
            adminSearchRadiusValidation: false,
            breadcrumbObj:[
                {
                    title:'Manage Search Radius',
                    breadCrumbLink:'./admin#/settingradius',
                    active:false
                },
                {
                    title: 'Edit Radius',
                    breadCrumbLink:'',
                    active:true
                    
                }
            ]

        }
    }
    componentDidMount() {
        this.setState({
            radiusmile: this.props.params.radius,
            adminSearchRadiusMile: this.props.params.adminradius
        })
    }

    async _save() {
        this.setState({
            loading: true
        });
        try {
            let ID = this.props.params.id;
            if(this.state.radiusmile > 1000 || this.state.radiusmile <= 0) {
                this.state.radiusValidation = true;
            } else {
                this.state.radiusValidation = false
            }
            if(this.state.adminSearchRadiusMile > 1000 || this.state.adminSearchRadiusMile <= 0) {
                this.state.adminSearchRadiusValidation = true;
            } else {
                this.state.adminSearchRadiusValidation = false
            }
            if(this.state.radiusmile != null && this.state.radiusmile != "" && this.state.radiusmile.length > 0 && this.state.radiusValidation === false && this.state.adminSearchRadiusMile != null && this.state.adminSearchRadiusMile != "" && this.state.adminSearchRadiusMile.length > 0 && this.state.adminSearchRadiusValidation === false){
                let response = await axios.put(`./admin/admin_settings/${ID}`, {miles: this.state.radiusmile, admin_assign_miles: this.state.adminSearchRadiusMile});
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.success("Miles Update Successfully");
                    window.location.href = "./admin#/settingradius";
                }
            } else {
                toast.error("Please enter search raduis value must be between 1 to 1000");
            }
                

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }


    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>
                            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>
                            <div className={"clearfix"}></div>
                        </div>
                        <div className={"x_content"}>
                            <br/>
                            <form data-parsley-validate className={"form-horizontal form-label-left"}>
                                    <div className={"row"}>
                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Application Search Radius Miles</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="number"
                                                        name='raduis_miles'
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.radiusmile}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    radiusmile: event.target.value
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Admin Search Radius Miles</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="number"
                                                        name='raduis_miles'
                                                        required="required"
                                                        className={"form-group col-md-7 col-xs-12"}
                                                        value={this.state.adminSearchRadiusMile}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    adminSearchRadiusMile: event.target.value
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                            <Button bsStyle="success" onClick={this._save.bind(this)}>Submit</Button>
                                        </div>
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}