import React, {Component} from 'react';
import { render } from 'react-dom';
import {Button, Modal, Alert, Pagination} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import {Checkbox, Radio} from 'react-icheck';
import {history} from '../index';
import moment from 'moment';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';


export default class PromoViewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            promo: {
                amount: null,
                percentage: null, 
                forCourier: false,
                status:false,
                code:"",
                created_at:""
            },
            ready: false,
            breadcrumbObj:[
                {
                    title:'Promocode',
                    breadCrumbLink:'./admin#/promo',
                    active:false
                },
                {
                    title:'View Promocode',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });
        if(!!this.state.id && this.state.id !== "new"){
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    ready: true
                };
            });
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/promocode/${this.state.id}`, {
                params: {}
            });

            if(!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    promo: response.data.data,
                    ready: true
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                 <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left"}>

                                    {this.state.ready && !!this.state.promo && <div className={"row"}>

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                        <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Promocode</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.promo.code}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    promo: {
                                                                        ...previousState.promo,
                                                                        code: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Type</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.promo.amount ? `${this.state.promo.amount} $` : `${this.state.promo.percentage} %` }
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    promo: {
                                                                        ...previousState.promo,
                                                                        amount: event.target.value
                                                                    }
                                                                };
                                                            })}}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Status</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <select
                                                        disabled
                                                        className={"form-control"}
                                                        value={this.state.promo.status}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    promo: {
                                                                        ...previousState.promo,
                                                                        status: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    >
                                                        <option value="true">Active</option>
                                                        <option value="false">Inactive</option>
                                                    </select >
                                                </div >
                                            </div >
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Created on</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={moment(this.state.promo.created_at).format(("DD/MM/YYYY"))}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    promo: {
                                                                        ...previousState.promo,
                                                                        created_at: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>}

                                    {this.state.ready && !this.state.promo && <h3>Info not found</h3> }

                                    <div className={"ln_solid"}></div>
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <a className={"btn btn-primary"} href={"#/promo/"}>Cancel</a>
                                        </div>
                                    <div className={"col-lg-6"}>

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}
            </div>
        );
    }
    
}