const initialState = {
    notifications: []
};

export default function notifications(state = initialState, action) {
    switch (action.type) {
        case 'ADD_NOTIFICATION':
            state.notifications.push(action.payload.notification);
            return {
                ...state,
                notifications: state.notifications
            };
        case 'REMOVE_NOTIFICATION':
            state.notifications = [];
            return {
                ...state,
                notifications: state.notifications
            };
        default:
            return state;
    }
}