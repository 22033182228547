import React, { Component } from 'react';
import { removeNotifications } from "../actions/notificationActions";
import { history, store } from "../index";

export default class NotificationBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            notifications: []
        };
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            this.setState(previousState => {
                return {
                    ...previousState,
                    notifications: store.getState().notifications.notifications
                };
            });
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    _onNotificationClick(v, k) {
        store.dispatch(removeNotifications(k));
        history.push('/users/' + v.user_id);
    }

    render() {
        return (
            <div>
                {this.state.notifications.map((v, k, a) => {
                    return (
                        <div className={"alert alert-danger alert-dismissible fade in"} onClick={() => { this._onNotificationClick(v, k); }}>
                            <strong>{v.title}</strong>
                        </div>
                    );
                })}
            </div>
        );
    }
}
