import React, {Component} from 'react';
import {render} from 'react-dom';
import {ToastContainer, toast} from 'react-toastify';
// import { Link } from "react-router-dom";
import axios from "axios/index";
import LINX from '../assests/main-logo.png';

var regExp = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
export default class ForgotPassword extends Component{

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            type: '',
            isError: {
                email: ''
            }
        };
    }

    async submit () {
        this.setState(previousState => {
            return {
                ...previousState,
                // loading: true
            };
        });
        const { email, isError } = this.state

        email === "" ? isError.email = "Email Address is required" : ""
        try {
            this.state = {
                params:{
                    email:this.state.email,
                    type:"user"
                }
            }
            let response = await axios.post(`../api/forgot`, this.state);
            if(!!response.data.result.error && response.data.result.error.length > 0 ) {
                response.data.result.error.map((err, i) => {
                    toast.error(err.message);
                });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        // loading: false,
                        email: '',
                        type: ' '
                    };
                }, () => {
                    toast.success("Link has been generated in your email.");
                    // setTimeout(() => {
                    //     document.location.href = '/admin/reset-password';
                    // }, 1000);
                });
            }
        } catch (e) {
            if(!!e.response && !!e.response.data.result.error && e.response.data.result.error.length > 0) {
                e.response.data.result.error.map((err, i) => {
                    toast.error(err.message);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    // loading: false
                };
            });
        }
    }
    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        const { email } = this.state

        let isError = { ...this.state.isError };
        switch (name) {
            case "email":
                email.length === 1 ? isError.email = " " :
                    isError.email = regExp.test(value)
                        ? ""
                        : "Email address is invalid";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    };

    render() {
        const { isError } = this.state;
        return (
            <div className="form-signin">
                <img style={{width:"150px",height:"52px",marginBottom: "49px",marginLeft: "63px"}} src={LINX} />
                <h2 className="form-signin-heading">Forgot Password</h2>
                <label className="sr-only">Email address</label>
                <input
                    type="email"
                    className="form-control"
                    placeholder="Email address"
                    name="email"
                    required
                    autoFocus
                    value={this.state.email}
                    onChange={event => {
                        event.persist();
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                email: event.target.value
                            };
                        })
                        this.formValChange(event)
                    }
                    }
                />
                {isError.email.length > 0 ? <p className='text-danger'>{isError.email}</p> : ""}
                 
                        <button className="btn btn-lg btn-primary btn-block" onClick={() => {
                            this.submit();
                        }}>Submit</button>
                        <a style={{ color: "#fff",marginTop:"15px",display:"block",textAlign:"right",fontFamily:"Gilroy-Regular",letterSpacing:"1px" }} href="./login">Back to Login.</a>
                <ToastContainer />
            </div>
    );
    }

    }
