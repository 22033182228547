import React, {Component} from 'react';
import { render } from 'react-dom';
import {Button, Modal, Alert, Pagination} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { RingLoader } from 'react-spinners';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import {Checkbox, Radio} from 'react-icheck';
import {history} from '../index';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class PromoFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            promo: {
                amount: 0,
                percentage: 0, 
                forCourier: false,
                status:"active",
                code: "",
            },
            ready: false,
            characterError: false,
            amountError: false,
            breadcrumbObj:[
                {
                    title:'Promocode',
                    breadCrumbLink:'./admin#/promo',
                    active:false
                },
                {
                    title:props.params.id == 'new'? 'Add Promocode' : "Edit Promocode",
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });
        if(!!this.state.id && this.state.id !== "new"){
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    ready: true
                };
            });
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/promocode/${this.state.id}`, {
                params: {}
            });

            if(!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    promo: {
                        amount: response.data.data.amount,
                        code: response.data.data.code,
                        percentage: response.data.data.percentage, 
                        forCourier: response.data.data.forCourier,
                        status:response.data.data.status},
                    ready: true,
                    // status:response.data.data.status
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save(e) {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            this.state.promo.amount = parseInt(this.state.promo.amount);
           if(this.state.id !== "new"){
            this.state.promo['_id'] = this.state.id
           }
           this.state.promo.percentage = isNaN(this.state.promo.percentage) ? 0 : this.state.promo.percentage;
           this.state.promo.amount = isNaN(this.state.promo.amount) ? 0 : this.state.promo.amount;
           
           if(this.state.promo.code.length < 4 || this.state.promo.code.length > 6 ) {
            this.state.characterError = true;
           } else {
            this.state.characterError = false;
           }
           if((this.state.promo.percentage < 0 || this.state.promo.percentage > 99) || (this.state.promo.amount < 0 || this.state.promo.amount > 99)) {
            this.state.amountError = true;
           } else {
            this.state.amountError = false;
           }
            // this.state.promo.status =  this.state.promo.status === "true" ? true:false
            if(this.state.promo.code != null &&  this.state.promo.code.trim() !== '' && this.state.characterError === false && this.state.amountError === false ){
            let response = await axios.post(`./admin/promocode`, this.state.promo);

            if(!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                if(this.state.id === "new"){
                this.setState(previousState => {

                    return {
                        ...previousState,
                        loading: false,
                        promo: response.data.data
                        // id: this.state.id,

                    };
                    
                }, () => {
                    toast.success("Promocode Created Successfully");
                    history.push('/promo');
                });
            }
            else{
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        promo: response.data.data,
                        id:response.data.data._id
                    };
                }, () => {
                    toast.success("Promocode Updated Successfully.");
                    history.push('/promo');
                })
            }
            }
        } else {
            this.setState({
                loading: false
            })
            if(this.state.characterError === true) {
                toast.error("Please set promo code  min 4 to max 6 character")
            } else if(this.state.amountError === true) {
                toast.error("please set value between 1 to 99")
            } else {
                toast.error("Please enter promo code.");
            }
        }
        } catch (e) {
            if(!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                {this.state.id !== 'new' &&  <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>}
                                {this.state.id === 'new' &&  <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>}
                                <div className={"clearfix"}>
                                <div className="form-group" style={{marginTop:"5px"}}>
                                <select
                                  style={{marginLeft:"15px"}}
                                   // className={"form-control"}
                                  value={this.state.promo.status}
                                  onChange={event => {
                                  event.persist();
                                  this.setState(previousState => {
                                 return {  
                                       ...previousState,
                                    promo:{
                                    ...previousState.promo,
                                    status: event.target.value
                                        }
                                     };
                                 }, )

                               }}
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                               </select>
                            </div>

                         </div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left add_promocode"}>
                                <div className="row">

                                    {this.state.ready && !!this.state.promo && <div className={"row"}>

                                        <div className={"col-md-12 col-sm-6 col-xs-6"}>
                                            <div className={"form-group"}>
                                            <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Promo Code</label>
                                            <div className={"col-md-4 col-sm-6 col-xs-12 input-icons"}>
                                                    <input
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.promo.code}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    promo: {
                                                                        ...previousState.promo,
                                                                        code: event.target.value
                                                                    }
                                                                };
                                                            })}}
                                                    />
                                                 </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Type</label>
                                                <div className={"col-md-4 col-sm-6 col-xs-12 input-icons"}>
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.promo.amount}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    promo: {
                                                                        ...previousState.promo,
                                                                        amount: event.target.value
                                                                    }
                                                                };
                                                            })}}
                                                    />
                                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                                 </div>
                                               <div class="col-md-1">
                                                    <h5>Or</h5>
                                                </div>
                                                <div className="col-md-4 col-sm-6 col-xs-12 input-icons">
                                                <input
                                                        type="number"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.promo.percentage}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    promo: {
                                                                        ...previousState.promo,
                                                                        percentage: parseInt(event.target.value)
                                                                    }
                                                                };
                                                            })}}
                                                    />
                                                    <i class="fa fa-percent" aria-hidden="true"></i>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.ready && !this.state.promo && <h3>Info not found</h3> }

                                    <div className={"ln_solid"}></div>
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <a className={"btn btn-primary"} href={"#/promo/"}>Cancel</a>
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                        </div>
                                        <div className={"col-lg-6"}>

                                        </div>
                                    </div>
                                 </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}
            </div>
        );
    }
    
}