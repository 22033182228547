import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class AdminProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            n: !!props.params.n,
            user: {
                first_name: "",
                last_name: "",
                email: "",
                pay_type: "",
                phone: "",
                status: "review",
                role: "user",
                type: "user",
                new_password: "",
                new_balance: 0,
                new_courierLimit: 0,
                visible: false,
                in_progress: false,
                linxFee: 0,
                avatar_url: ""
            },
            isError: {
                flagError: false
            }
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });

        this._load();
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/profile`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.data
                    }
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _preUpload(event) {
        if (this.fileInput.files.length === 0) {
            return;
        }
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        let formdata = new FormData();
        formdata.append('image', this.fileInput.files[0]);
        try {
            let response = await axios.post(`./admin/ajax/uploads_img`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                this.fileInput.value = '';
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        user: {
                            ...previousState.user,
                            avatar_url: response.data.data.image_url,
                            avatar: response.data.data.image
                        }
                    };
                });
            }
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save() {
        let regExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });

        if(this.state.user.new_password.length > 0) {
        if(regExp.test(this.state.user.new_password)){
            this.state.isError.flagError = false;
        } else {
            toast.error('Password must have at least eight characters, at least one uppercase letter, one lowercase letter, one number and one special character');
            this.state.isError.flagError = true;
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
        }
        try {
            this.state.user.new_courierLimit = parseFloat(this.state.user.new_courierLimit);
            this.state.user.new_balance = parseFloat(this.state.user.new_balance);

            if(this.state.isError.flagError === false){
            let response = await axios.post(`./admin/user/`, this.state.user);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                if (this.state.id === "new") {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item created!");
                        history.push('/users/' + response.data.data._id);
                    });
                } else {
                    if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                        response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
                    }
                    response.data.data.visible = !!response.data.data.visible;
                    response.data.data.in_progress = !!response.data.data.in_progress;
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Your profile is updated.");
                        history.push('/');

                    });
                }
            }
        }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    };

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                <h2>My profile</h2>
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left"}>

                                    <div className={"row"}>

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>First Name <span className={"required"}>*</span>
                                                </label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.user.first_name}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        first_name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Last Name <span className={"required"}>*</span>
                                                </label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="text"
                                                        name="last-name"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.user.last_name}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        last_name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Email</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="email"
                                                        value={this.state.user.email}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        email: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Phone</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="phone"
                                                        value={this.state.user.phone}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        phone: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>New password
                                                </label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"date-picker form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        value={this.state.user.new_password}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        new_password: event.target.value
                                                                    }
                                                                };
                                                            })  
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className={"col-md-6 col-sm-6 col-xs-6"}>

                                            <div className={"form-group"}>
                                                <div className={"text-center"}>
                                                    {this.state.user.avatar !== "" && <img className={"img-circle profile_img"} style={{ maxHeight: 100, width: "auto", margin: 20 }} src={this.state.user.avatar_url} />}
                                                    {this.state.user.avatar === "" && <img className={"img-circle profile_img"} style={{ maxHeight: 100, width: "auto", margin: 20 }} src="/images/empty.png" />}
                                                </div>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Photo</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="file"
                                                        name="avatar"
                                                        ref={input => {
                                                            this.fileInput = input;
                                                        }}
                                                        onChange={this._preUpload.bind(this)}
                                                    />
                                                </div>
                                            </div>


                                            <div className={"ln_solid"}></div>

                                        </div> */}

                                    </div>

                                    <div className={"ln_solid"}></div>
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            {!this.state.n && <a className={"btn btn-primary"} href={"#/"}>Cancel</a>}
                                            {!!this.state.n && <a className={"btn btn-primary"} href={"#/"}>Cancel</a>}
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                        </div>
                                        <div className={"col-lg-6"}>

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}
            </div>
        );
    }

}