import React, { Component, createRef } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
/* import { RingLoader } from 'react-spinners'; */
import axios from 'axios';
import moment from 'moment';
import { ITEMS_PER_PAGE } from "../../components/Constants";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import CustomBreadCrumbs from '../../components/CustomBreadcrumbs';
import 'react-day-picker/lib/style.css';
import 'icheck/skins/all.css';
import { Link } from 'react-router';
export default class SmallBusinessSubOrderList extends Component {

    constructor(props) {
        super(props);
        this.datePickerFrom = createRef();
        this.datePickerTo = createRef();
        this.state = {
            id: props.params.id,
            loading: true,
            activePage: 1,
            total: 0,
            q: "",
            model: {},
            items: [],
            orderDeatils: {},
            releaseDisable: false,
            filter: {
                status: "6",
                start_date: null,
                end_date: null,
                start_date_str: null,
                end_date_str: null,
            },
            bookingId: 1,
            user: 1,
            trip_date: 1,
            vehicle_type: 1,
            payment_status: 1,
            sortData: '',
            sortAble: false,
            _id: [],
            breadcrumbObj: [
                {
                    title: 'Manage Business',
                    breadCrumbLink: './admin#/reports/businessOrders',
                    active: false
                },
                {
                    title: 'Manage Business Sub Orders',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        };
    }

    componentWillMount() {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    componentDidMount() {
        this._loadList();
    }


    async bulkrelease(e) {
        this.setState({
            loading: true
        });
        try {
            this.state.releaseDisable = true;
            const ID = e ? e._id.split(" ") : this.state._id

            let response = await axios.post(`./admin/realsePayments`, ID);

            if (!!response.data.errors && response.data.errors.length > 0) {
                this.state.releaseDisable = false;
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            else {
                toast.success("Paypment has been done successfully.")
                this.state.releaseDisable = false;
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState({
                loading: false,
                releaseDisable: false,
            });
        }
    }

    async _loadList() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/orders/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.state.model = response.data.data.small_business_recipient_orders;
            this.state.orderDeatils = response.data


            this.setState(previousState => {

                return {
                    ...previousState,
                    loading: false,
                    model: this.state.model,
                    orderDeatils: this.state.orderDeatils,
                    ready: true
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    checkid = (e, value) => {
        if (e.target.checked) {
            //append to array
            this.setState(previousState => {
                return {
                    _id: [...previousState._id, value]
                }
            })
        } else {

            //remove from array
            this.setState({
                _id: this.state._id.filter(function (val) { return val !== value })
            })
        }
    }

    /* handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    } */

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td>
                    <input
                        type="checkbox"
                        key={item._id}
                        disabled={(item.order_status == "wait_pick_up" || item.order_status == "in_progress" || item.order_status == "wait_accept_delivery" || item.order_status == "canceled" || item.order_status == "finished") ? true : false}
                        // name="releasepayment"
                        //value={item._id}
                        onChange={(e) => this.checkid(e, item._id)}
                    />
                </td>
                <td>{this.state.orderDeatils.data ? this.state.orderDeatils.data.orderId : '-'}</td>
                <td>{this.state.orderDeatils.data ? this.state.orderDeatils.data.owner_name : '-'}</td>
                <td>{this.state.orderDeatils.data ? this.state.orderDeatils.data.owner_address : '-'}</td>
                <td>{item.recipient_address ? item.recipient_address : '-'}</td>
                <td>{moment(item.pickup_date).format("YYYY/MM/DD HH:mm")}</td>
                <td>{item.courier ? item.courier.first_name : '-'}</td>
                <td>
                    {(item.order_status == "wait_pick_up" || item.order_status == "in_progress" || item.order_status == "wait_accept_delivery" || item.order_status == "canceled" || item.order_status == "finished") ? "-" :
                        <Link className="btn btn-success" style={{ color: "black" }} to={{
                            pathname: `/reports/orders/assigndriver/${this.state.orderDeatils.data.orderId}`,
                            state: { subOrderId: [item._id], moduleNum: 2, notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : '' }
                        }}>
                            Assign Driver
                        </Link>
                    }
                </td>
                <td>{item.paid_amount ? `$${(item.paid_amount).toFixed(2)}` : '-'}</td>
                <td>{item.order_status == "new" || item.order_status == "hold" ? "Unassigned" : item.order_status == "wait_pick_up" || item.order_status == "in_progress" || item.order_status == "wait_accept_delivery" ? "Assigned" : item.order_status == "canceled" ? "Cancelled" : item.order_status == "finished" ? "Completed" : '-'}</td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
        );
    }

    render() {
        var oneYearAgo = new Date();
        oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>

                            {this._renderTitle()}

                            <div className={"title_right"} style={{ minHeight: '89.42px' }}>

                            </div>
                            <div className={"clearfix"}></div>
                            <div className={"title_right"}>
                                <div className={"col-lg-3 form-group pull-right no-padding"} style={{ textAlign: "right", maxWidth: "100%" }}>

                                    <div className={""}>
                                        <div className={"col-md-12 no-padding"}>

                                            {(this.state.orderDeatils.data) &&
                                                <Link className={`btn btn-success ${this.state._id.length <= 1 ? 'link-disabled-class' : ''}`} style={{ color: "black" }} to={{
                                                    pathname: `/reports/orders/assigndriver/${this.state.orderDeatils.data.orderId}`,
                                                    state: { subOrderId: this.state._id, moduleNum: 2, notification_id: this.props.location.state.notification_id ? this.props.location.state.notification_id : '' }
                                                }}>
                                                    Assign Driver
                                                </Link>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className={"clearfix"}></div>
                        </div>


                        {(this.state.model && this.state.model.length > 0) ? (
                            <div className={"x_content"}>
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap table_scroll order_table"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>Booking ID</th>
                                            <th>User</th>
                                            <th>Pick up location</th>
                                            <th>Destination location</th>
                                            <th>Trip date/time</th>
                                            <th>Assigned To</th>
                                            <th>Assigned Driver Action</th>
                                            <th>Total</th>
                                            <th>Order Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.model && this.state.model.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}
                                    </tbody>
                                </table>


                            </div>
                        ) : <h4 style={{ marginLeft: "700px" }}>No Data Found</h4>}
                    </div>

                    {/* {!this.state.loading && <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.ceil(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div>} */}

                </div>

                {/* {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoader
                        style={{ margin: 'auto' }}
                        color={'#0019bc'}
                        loading={this.state.loading}
                    />
                </div>} */}
            </div>
        );
    }

}