import 'gentelella/vendors/datatables.net-bs/css/dataTables.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-buttons-bs/css/buttons.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-fixedheader-bs/css/fixedHeader.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-responsive-bs/css/responsive.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-scroller-bs/css/scroller.bootstrap.min.css';

import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'datatables.net-fixedheader';
import 'datatables.net-keytable';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'datatables.net-scroller';

import React, { Component, createRef } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ITEMS_PER_PAGE } from "../components/Constants";
import { Link } from 'react-router';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import increaseGraph from '../assests/increase-graph-profit.png'
import { formatDate, parseDate } from 'react-day-picker/moment';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class ManageDriverEarningList extends Component {

    constructor(props) {
        super(props);
        this.datePickerFrom = createRef();
        this.datePickerTo = createRef();
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            filter:{
                status: "3",
                start_date: null,
                end_date: null,
                start_date_str: null,
                end_date_str: null
            },
            conRang:false,
            q:"",
            // type: "user",
            // q: "",
            items: []
        };
    }

    componentWillMount() {
       
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }

    componentDidMount() {
        this._loadList();
        $(document).trigger('TInitPanelToolBox');
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        let param = {
            q: this.state.q,
        }
        const { filter } = this.state;
        
        if (filter.status == "6") {
            param['start'] = this.state.filter.start_date_str
            param['end'] = this.state.filter.end_date_str
        } else {            
            this.datePickerFrom.current.input.value  = null;
            this.datePickerFrom.current.state.value = null;
            this.datePickerTo.current.state.value = null;
            this.datePickerTo.current.input.value = null;
            this.state.filter.start_date_str = null;
            this.state.filter.end_date_str = null;
            this.state.filter.end_date = null;
            this.state.filter.start_date = null;
        } 
        /* if(this.state.conRang !== false) {
            param['export'] = 1
        } */
        try {
            if(this.state.conRang !== false) { 
            let exportResponse = await axios.get(`./admin/reports/totalOrdersList/${ITEMS_PER_PAGE}/${this.state.activePage}?export=1&range=${this.state.filter.status}`, {
                
                params: param
            });
            let fileName =moment().format("DDMMYYYhhmmss")
            const link = document.createElement('a');
            // link.href = `http://${exportResponse.data.data.url}`;
            link.href = `${exportResponse.data.data.url}`;
            link.setAttribute('download',`${fileName}_earning_report.csv`);
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false
            });
        } else {
            let response = await axios.get(`./admin/reports/totalOrdersList/${ITEMS_PER_PAGE}/${this.state.activePage}?range=${this.state.filter.status}`, {
                
                params: param
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.order,
                total: response.data.data.count
            });
        }
        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    startDateEndDateValidation(){
        if(this.state.filter.start_date_str != null && this.state.filter.end_date_str != null){
            this._loadList();
        } else if(this.state.filter.start_date_str == null && this.state.filter.end_date_str != null) {
            toast.error("Please select valid start date");
        } else if(this.state.filter.start_date_str != null && this.state.filter.end_date_str == null) {
            toast.error("Please select valid end date");
        } else if(this.state.filter.start_date_str == null && this.state.filter.end_date_str == null) {
            toast.error("Please select valid start date and end date");
        }
    }

    async _clearDate(){
        this.datePickerFrom.current.input.value = null;
        this.datePickerFrom.current.state.value = null;
        this.datePickerTo.current.state.value = null;
        this.datePickerTo.current.input.value = null;
        this.setState({
            loading: false,
            filter:{
                start_date:null,
                end_date:null,
                start_date_str:null,
                end_date_str:null,
                status:"3"
            }
        })
        this._loadList();
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey,
            conRang:false
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td className={"text-center"}>{item.orderId}</td>
                <td className={"text-center"}>{item.owner_name}</td>
                <td className={"text-center"}>{moment(item.created_at).format("MM/DD/YYYY HH:mm")}</td>
                <td className={"text-center"}>{item.cost ? (item.cost).toFixed(2) : '-'}</td>
                <td className={"text-center"}>{item.serviceFee ? (item.serviceFee).toFixed(2) : '-'}</td>
                <td className={"text-center"}>{item.earning ? (item.earning).toFixed(2) : '-'}</td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <div>
                <h2 style={{ marginTop: '10px',color:"#00e0b0" }}>Manage Earnings</h2>
            </div>
        );
    }

    render() {
        var oneYearAgo = new Date();
        oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel manage-booking"}>
                            <div className={"x_title"}>

                                {this._renderTitle()}
                               
                                <div className={"col-lg-2 form-group pull-right no-padding"} style={{ textAlign: "right", maxWidth: "165px" }}>
                                    <div className={""}>
                                        <div className={"col-md-12 no-padding"}>
                                            <div className={"form-group"}>
                                                <select
                                                    value={this.state.filter.status}
                                                    onChange={event => {
                                                        event.persist();
                                                        this.setState(previousState => {
                                                            return {
                                                                ...previousState,
                                                                conRang:false,
                                                                activePage: 1,
                                                                filter: {
                                                                    ...previousState.filter,
                                                                    status: event.target.value,
                                                                }
                                                            };
                                                        }, () => {
                                                            this._loadList()
                                                        })
                                                    }}
                                                >
                                                    <option value="1">Today</option>
                                                    <option value="2">This Week</option>
                                                    <option value="3">Last Week</option>
                                                    <option value="4">This Month</option>
                                                    <option value="5">Last Month</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    <div className={"col-lg-8 form-group pull-right"} style={{ textAlign: "right", display: "flex", justifyContent: "right" }}>
                        <div className={"row"}>
                            <div className={"col-md-4"}>
                                <div className={"form-group"}>
                                    <span className="InputFromTo-to">

                                        <DayPickerInput
                                            className={"form-control col-md-7 col-xs-12"}
                                            ref={this.datePickerFrom}
                                            formatDate={formatDate}
                                            format={'YYYY/MM/DD'}
                                            parseDate={parseDate}
                                            placeholder={"YYYY/MM/DD"}
                                            dayPickerProps={{
                                                disabledDays: {
                                                    before: oneYearAgo,
                                                    after: Date.parse(this.state.filter.end_date) ? this.state.filter.end_date : new Date(),
                                                },
                                            }}
                                            inputProps={{
                                                className: "form-control col-md-7 col-xs-12"
                                            }}

                                            onDayChange={(from) => {
                                                this.setState(previousState => {
                                                    return {
                                                        ...previousState,
                                                        conRang:false, 
                                                        filter: {
                                                            ...previousState.filter,
                                                            status: "6",
                                                            start_date: from,
                                                            start_date_str: moment(from).format("YYYY-MM-DD")
                                                        }
                                                    };
                                                });
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className={"form-group"}>
                                    <span className="InputFromTo-to">
                                        <DayPickerInput
                                            
                                            className={"form-control col-md-7 col-xs-12"}
                                            ref={this.datePickerTo}                                            
                                            formatDate={formatDate}
                                            format={'YYYY/MM/DD'}
                                            parseDate={parseDate}
                                            placeholder={"YYYY/MM/DD"}
                                            dayPickerProps={{
                                                disabledDays: {
                                                    before: Date.parse(this.state.filter.start_date) ? this.state.filter.start_date : oneYearAgo,
                                                    after: new Date(),
                                                },
                                            }}
                                            inputProps={{
                                                className: "form-control col-md-7 col-xs-12"
                                            }}
                                            onDayChange={(from) => {
                                                this.setState(previousState => {
                                                    return {
                                                        ...previousState,
                                                        conRang:false,
                                                        filter: {
                                                            ...previousState.filter,
                                                            status: "6",
                                                            end_date: from,
                                                            end_date_str: moment(from).format("YYYY-MM-DD")
                                                        }
                                                    };
                                                });
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className={"col-md-2"} style={{ textAlign: "left" }}>
                                <div className={"form-group"}>
                                    <Button onClick={() => this.setState({
                                                                conRang:false,
                                                                activePage: 1,
                                                        }, () => {
                                                            this.startDateEndDateValidation()
                                                        })} style={{ marginLeft: '5px', marginBottom: "30px" }} bsStyle="success">Search</Button>
                                </div>
                            </div>
                            <div className={"col-md-2"} style={{ textAlign: "left" }}>
                                <div className={"form-group"}>
                                    <Button onClick={() => this.setState({
                                                                conRang:false,
                                                                activePage: 1,
                                                                filter:{
                                                                    status:"3"
                                                                }
                                                        }, () => {
                                                            this._clearDate()
                                                        })} style={{ marginLeft: '5px', marginBottom: "30px" }} bsStyle="success">Clear</Button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <button className={"btn btn-success"} type="button" onClick={() => {
                            this.setState({
                                conRang:true,
                            }, () => {
                                this._loadList()
                            });
                            }}>Export</button>
                        </div>
                    </div>

                                
                                <div className={"title_right"}>
                                    <div className={"col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search"}>
                                        <a href="#/manageearnings"><img src={increaseGraph} /></a>
                                        <div className={"input-group"}>
                                            <input
                                                type="text"
                                                className={"form-control"}
                                                placeholder="Search by Name or Booking ID"
                                                value={this.state.q}
                                                onChange={event => {
                                                    this.setState({
                                                        conRang:false,
                                                        activePage: 1,
                                                        q: event.target.value,
                                                    }, () => {
                                                        this._loadList()
                                                    });
                                                }}
                                            />
                                            <span className={"input-group-btn"}>
                                                <button className={"btn btn-default"} type="button" onClick={() => {
                                                    this.setState({
                                                        conRang:false,
                                                        activePage: 1,
                                                        q: "",
                                                    }, () => {
                                                        this._loadList()
                                                    });
                                                }}>Cancel</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                            {this.state.total > 0 ? (
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th className={"text-center"}>Booking ID</th>
                                            <th className={"text-center"}>User</th>
                                            <th className={"text-center"}>Boooking Date</th>
                                            <th className={"text-center"}>Total fare($)</th>
                                            <th className={"text-center"}>Driver's fare($)</th>
                                            <th className={"text-center"}>Your fare($)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.items.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}
                                    </tbody>
                                </table>
                                   ):
                                   <h4 style={{marginLeft:"700px"}}>No Data Found</h4>
                                }
                            </div>
                        </div>

                        {!this.state.loading && <div>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                items={Math.ceil(this.state.total / ITEMS_PER_PAGE)}
                                maxButtons={5}
                                activePage={this.state.activePage}
                                onSelect={this.handlePagination.bind(this)}
                            />
                        </div>}

                    </div>

                    {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoaderComponent loading={this.state.loading}/>
                    </div>}
                </div>
            </div>
        );
    }

}