import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';


class AddHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            question: "",
            isError: {
                question: '',
            },
            breadcrumbObj: [
                {
                    title: 'Help',
                    breadCrumbLink: './admin#/help',
                    active: false
                },
                {
                    title: 'Add Question',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        }
    }


    async _save(e) {

        try {
            this.state = {
                question: this.state.question
            }
            let response = await axios.post(`./admin/add_help_question`, this.state);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        question: response.data.data.question

                    };

                });
                toast.success("Question Created Successfully");
                window.location.href = '#/help/'
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                };
            });
        }
        // }

    }




    formValChange = e => {
        e.preventDefault();

        const { name, value } = e.target


        const { question, isError } = this.state

        // if (value.length < 6 && value.length > 10) {
        //     isError.question = 'Question must be between 6 to 200 char'
        // }
        // switch (name) {
        //     case "question":
        //         question.length === 6 ? isError.question = "Question must have atleast 5 char." :
        //             isError.question =
        //             value.length > 8 ? "Question must be less then 200 char." : "";
        //         break;
        //     default:
        //         break;
        // }
        this.setState({
            isError,
            [name]: value
        })
    };


    render() {
        const { isError } = this.state;
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left add_promocode add_questions"}>
                                    <div className="row">

                                        <div className={"row"}>

                                            <div className={"col-md-12 col-sm-6 col-xs-6"}>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Question</label>

                                                    <textarea
                                                        type="text"
                                                        required="required"
                                                        placeholder='Enter your question.'
                                                        name="question"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.question}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    question: event.target.value
                                                                };
                                                            })
                                                            // this.formValChange(event)
                                                        }}
                                                    />
                                                    {isError.question && <p className='text-danger'>{isError.question}</p>}

                                                </div>
                                            </div>
                                        </div>


                                        <div className={"ln_solid"}></div>
                                        <div className={"form-group"}>
                                            <div className={"col-lg-6"}>
                                                <a className={"btn btn-primary"} href={"#/help/"}>Cancel</a>
                                                <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                            </div>
                                            <div className={"col-lg-6"}>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}
            </div>
        );
    }

}
export default withRouter(AddHelp)