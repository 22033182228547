import React, { Component, createRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { formatDate, parseDate } from 'react-day-picker/moment';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    CartesianGrid,
    BarChart,
    ResponsiveContainer,
    Bar,
} from "recharts";
import RingLoaderComponent from '../RingLoaderComponent';

export default class ActivityUsers extends Component {



    constructor(props) {
        super(props);
        this.datePickerFrom = createRef();
        this.datePickerTo = createRef();
        this.state = {
            loading: true,
            filter: {
                range: "3",
                start_date: null,
                start_date_str: null,
                end_date_str: null,
                end_date: null,
                a2: []
            },
        };
    }

    componentDidMount() {
        this._loadList();
    }

    async _loadList() {
        this.setState({
            loading: true,
        });
        let param = {
            range: this.state.filter.range
        }

        const { filter } = this.state;

        if (filter.range == "6") {
            param['start'] = this.state.filter.start_date_str
            if (this.state.filter.end_date_str == null) {
                param['end'] = moment().format("YYYY-MM-DD")
            } else {
                param['end'] = this.state.filter.end_date_str
            }
        } else {
            this.datePickerFrom.current.input.value = null;
            this.datePickerFrom.current.state.value = null;
            this.datePickerTo.current.state.value = null;
            this.datePickerTo.current.input.value = null;
            this.state.filter.start_date_str = null;
            this.state.filter.end_date_str = null;
            this.state.filter.end_date = null;
            this.state.filter.start_date = null;
        }
        try {
            let response = await axios.get(`./admin/statistics/users`, {
                params: param

            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
            });
            let a1 = [];
            response.data.data.map((v, k, a) => {
                var t = moment(v.created_at).format("MM/DD")
                const { start_date_str, end_date_str } = this.state;
                if (this.state.filter.range == 1 || (this.state.filter.range == 6)) {
                    t = moment(v.created_at).format("HH:mm")
                }
                if (this.state.filter.range == 6 && start_date_str == end_date_str) {
                    t = moment(v.created_at).format("MM/DD")
                }
                if (this.state.filter.range == 6 && this.state.filter.start_date_str == this.state.filter.end_date_str) {
                    t = moment(v.created_at).format("HH:mm")
                }
                if (this.state.filter.range == 6 && start_date_str !== end_date_str) {
                    t = moment(v.created_at).format("MM/DD")
                }
                a1.push({
                    name: t,
                    earnings: v.serviceFee ? (v.serviceFee).toFixed(2) : '-'
                });
            });
            this.setState({
                a2: a1,
            })
        } catch (e) {
            this.setState({
                loading: false,
            });
            toast.error(e.message);
        }
    }
    startDateEndDateValidation() {
        if (this.state.filter.start_date_str != null && this.state.filter.end_date_str != null) {
            this._loadList();
        } else if (this.state.filter.start_date_str == null && this.state.filter.end_date_str != null) {
            toast.error("Please select valid start date");
        } else if (this.state.filter.start_date_str != null && this.state.filter.end_date_str == null) {
            this._loadList();
        } else if (this.state.filter.start_date_str == null && this.state.filter.end_date_str == null) {
            toast.error("Please select valid start date and end date");
        }
    }

    render() {
        var oneYearAgo = new Date();
        oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);

        const { a2 } = this.state;
        return (
            <div className={"dashboard_graph"}>
                <div className={"x_title"}>
                    <div className={"col-md-3"}>
                        <h3 style={{ color: "#00E0B0" }}>Earnings</h3>
                    </div>
                    <div className={"col-lg-2 form-group pull-right no-padding"} style={{ textAlign: "right", maxWidth: "165px" }}>
                        <div className={""}>
                            <div className={"col-md-12 no-padding"}>
                                <div className={"form-group"}>

                                    <select
                                        value={this.state.filter.range}
                                        onChange={event => {
                                            event.persist();
                                            this.setState(previousState => {
                                                return {
                                                    ...previousState,
                                                    filter: {
                                                        ...previousState.filter,
                                                        range: event.target.value,
                                                    }
                                                };
                                            }, () => {
                                                this._loadList()
                                            })
                                        }}
                                    >
                                        <option value="1">Today</option>
                                        <option value="2">This Week</option>
                                        <option value="3">Last Week</option>
                                        <option value="4">This Month</option>
                                        <option value="5">Last Month</option>

                                    </select>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div className={"col-lg-6 form-group pull-right"} style={{ textAlign: "right", display: "flex", justifyContent: "right" }}>
                        <div className={"row"}>
                            <div className={"col-md-4"}>
                                <div className={"form-group"}>
                                    <span className="InputFromTo-to">

                                        <DayPickerInput
                                            className={"form-control col-md-7 col-xs-12"}
                                            ref={this.datePickerFrom}
                                            formatDate={formatDate}
                                            format={'YYYY/MM/DD'}
                                            parseDate={parseDate}
                                            placeholder={"YYYY/MM/DD"}
                                            dayPickerProps={{
                                                disabledDays: {
                                                    before: oneYearAgo,
                                                    after: Date.parse(this.state.filter.end_date) ? this.state.filter.end_date : new Date(),
                                                },
                                            }}
                                            inputProps={{
                                                className: "form-control col-md-7 col-xs-12"
                                            }}

                                            onDayChange={(from) => {
                                                this.setState(previousState => {
                                                    return {
                                                        ...previousState,
                                                        filter: {
                                                            ...previousState.filter,
                                                            range: "6",
                                                            start_date: from,
                                                            start_date_str: moment(from).format("YYYY-MM-DD")
                                                        }
                                                    };
                                                });
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className={"form-group"}>
                                    <span className="InputFromTo-to">
                                        <DayPickerInput

                                            className={"form-control col-md-7 col-xs-12"}
                                            ref={this.datePickerTo}
                                            formatDate={formatDate}
                                            format={'YYYY/MM/DD'}
                                            parseDate={parseDate}
                                            placeholder={"YYYY/MM/DD"}
                                            dayPickerProps={{
                                                disabledDays: {
                                                    before: Date.parse(this.state.filter.start_date) ? this.state.filter.start_date : oneYearAgo,
                                                    after: new Date(),
                                                },
                                            }}
                                            inputProps={{
                                                className: "form-control col-md-7 col-xs-12"
                                            }}
                                            onDayChange={(from) => {
                                                this.setState(previousState => {
                                                    return {
                                                        ...previousState,
                                                        filter: {
                                                            ...previousState.filter,
                                                            range: "6",
                                                            end_date: from,
                                                            end_date_str: moment(from).format("YYYY-MM-DD")
                                                        }
                                                    };
                                                });
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className={"col-md-3"} style={{ textAlign: "left" }}>
                                <div className={"form-group"}>
                                    <Button onClick={() => this.startDateEndDateValidation()} style={{ marginLeft: '5px', marginBottom: "30px" }} bsStyle="success">Search</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  <ResponsiveContainer width={1600} height={500}> */}
                <div style={{ width: "100%", height: "500px" }} className="graph-section">
                    <BarChart width={1565} height={500} data={a2}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="earnings" fill="#82ca9d" />
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip cursor={{ fill: "grey" }} />
                    </BarChart>
                </div>
                {/*  </ResponsiveContainer> */}

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}

                <div className={"clearfix"} />
            </div>
        );
    }
}