import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class CancellationPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "user",
            loading: true,
            id: props.params.id,
            activePage: 1,
            total: 0,
            q: "",
            items: [],
            filter: {
                vehicleType: "1",
            },
            breadcrumbObj:[
                {
                    title:'Cancellation Policy',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentDidMount() {
        // this._loadList();
       
            this._load();

    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            const  vehicleType =  this.state.filter.vehicleType
            let response = await axios.get(`./admin/cancellationPolicy?type=${vehicleType}`);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                }); 
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    items: response.data.result.data
                };
            });
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td className={"text-center"}>{item.vehicle_type}</td>
                {/* <td className={"text-center"}>{item.time_duration}</td> */}
                <td className={"text-center"}>{item.charges}</td>
                <td className={"text-center"}>
                    <Link to={`/cancellationpolicy/${item._id}`}><i className={"fa fa-pencil"} /></Link>
                </td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>
        );
    }

    render() { 
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>

                            {this._renderTitle()}

                           
                            <div className={"clearfix"}>
                                <div className="form-group" style={{marginTop:"5px"}}>

                            <select
                     style={{marginLeft:"15px"}}
                    // className={"form-control"}
                    value={this.state.filter.vehicleType}
                    onChange={event => {
                        event.persist();
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                filter:{
                                    ...previousState.filter,
                                    vehicleType: event.target.value
                                }
                            };
                        }, () => {
                            this._load()
                        })
                    }}
                >
                    <option value="1">Small</option>
                    <option value="2">Medium</option>
                    <option value="3">Large</option>
                    <option value="4">Extra Large</option>
                    
                </select>
                </div>
                            </div>
                   
                            <div className={"clearfix"}></div>
                        </div>
                        <div className={"x_content"}>
                            {this.state.items.length > 0 ? (
                            <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th className={"text-center"}>Vehicle Type</th>
                                        {/* <th className={"text-center"}>Min Duration</th> */}
                                        <th className={"text-center"}>Charges(%)</th>
                                        <th className={"text-center"}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.items.map((item, i) => {
                                        return this._renderRow(item, i);
                                    })}
                                </tbody>
                            </table>
                              ):
                              <h4 style={{marginLeft:"700px"}}>No Data Found</h4>
                            }    
                        </div>
                    </div>
                </div>

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}
            </div>
        );
    }

}