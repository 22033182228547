import 'gentelella/vendors/datatables.net-bs/css/dataTables.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-buttons-bs/css/buttons.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-fixedheader-bs/css/fixedHeader.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-responsive-bs/css/responsive.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-scroller-bs/css/scroller.bootstrap.min.css';

import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'datatables.net-fixedheader';
import 'datatables.net-keytable';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'datatables.net-scroller';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ITEMS_PER_PAGE } from "../components/Constants";
import { Link } from 'react-router';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class PromoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            q: "",
            items: [],
            breadcrumbObj: [
                {
                    title: 'Promocode',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        };
    }

    componentWillMount() {
        this._loadList();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`./admin/promocodes/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                params: {
                    q: this.state.q,
                    sortKey: "",
                    reverse: ""
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.items,
                total: response.data.data.total_count
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    async deleteItem(id) {
        if (confirm('Delete this item?')) {
            this.setState({
                loading: true
            });
            try {
                let response = await axios.get(`./admin/promocode/delete/${id}`, {
                    params: {}
                });
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                }
                this._loadList();
            } catch (e) {
                this.setState({
                    loading: false
                });
                toast.error(e.message);
            }
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td className={"text-center"}>{item.code}</td>
                <td className={"text-center"}>{item.amount !== 0 ? `${item.amount} $` : `${item.percentage} %`}</td>
                <td className={"text-center"}>{item.status === "active" ? "Active" : "Inactive"}</td>
                <td className={"text-center"}>
                    <Link to={`/promo/${item._id}`}><i className={"fa fa-pencil"} /></Link>
                    <Link style={{ marginLeft: "10px" }} to={`/promoview/${item._id}`}><i className={"fa fa-eye"} /></Link>
                    <Button bsStyle="link" onClick={() => { this.deleteItem(item._id); }} ><i className={"fa fa-trash"} /></Button>
                </td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <div>
                <div className='col'>
                    <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
                </div>
                <div className='col-md-6' style={{ marginTop: "10px" }}>
                    <Button bsStyle="success" href={"#promo/new"}>Create</Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>

                            {this._renderTitle()}

                            <div className={"title_right"}>
                                <div className={"col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search"}>
                                    <div className={"input-group"}>
                                        <input
                                            type="text"
                                            className={"form-control"}
                                            placeholder="Search for..."
                                            value={this.state.q}
                                            onChange={event => {
                                                this.setState({
                                                    activePage: 1,
                                                    q: event.target.value
                                                }, () => {
                                                    this._loadList()
                                                });
                                            }}
                                        />
                                        <span className={"input-group-btn"}>
                                            <button className={"btn btn-default btn-cancel-disable"} type="button" onClick={() => {
                                                this.setState({
                                                    activePage: 1,
                                                    q: ""
                                                }, () => {
                                                    this._loadList()
                                                });
                                            }} disabled={this.state.q.length > 0 ? false : true}>Cancel</button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={"clearfix"}></div>
                        </div>
                        <div className={"x_content"}>
                            {this.state.total > 0 ? (
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th className={"text-center"} style={{ width: "250px" }}>Promocode</th>
                                            <th className={"text-center"} style={{ width: "200px" }}>Type</th>
                                            <th className={"text-center"} style={{ width: "170px" }}>Status</th>
                                            <th className={"text-center"}>Action</th>
                                        </tr >
                                    </thead >
                                    <tbody>
                                        {this.state.items.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}
                                    </tbody>
                                </table >
                            ) :
                                <h4 style={{ marginLeft: "700px" }}>No Data Found</h4>
                            }

                        </div >
                    </div >

                    {!this.state.loading && <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.round(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div>
                    }

                </div>

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}