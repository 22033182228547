import React, { Component, createRef } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
/* import { RingLoader } from 'react-spinners'; */
import axios from 'axios';
import moment from 'moment';
import { ITEMS_PER_PAGE } from "../../components/Constants";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import CustomBreadCrumbs from '../../components/CustomBreadcrumbs';
import 'react-day-picker/lib/style.css';
import 'icheck/skins/all.css';
import { Link } from 'react-router';
export default class SmallBusinessOrderReportPage extends Component {

    constructor(props) {
        super(props);
        this.datePickerFrom = createRef();
        this.datePickerTo = createRef();
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            q: "",
            items: [],
            releaseDisable: false,
            filter: {
                status: "6",
                start_date: null,
                end_date: null,
                start_date_str: null,
                end_date_str: null,
            },
            bookingId: 1,
            user: 1,
            trip_date: 1,
            vehicle_type: 1,
            payment_status: 1,
            sortData: '',
            sortAble: false,
            _id: [],
            breadcrumbObj: [
                {
                    title: 'Manage Business Bookings',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        };
        this.sortingHanlde = this.sortingHanlde.bind(this);
    }

    componentWillMount() {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    componentDidMount() {
        this._loadList();
    }


    async bulkrelease(e) {
        this.setState({
            loading: true
        });
        try {
            this.state.releaseDisable = true;
            const ID = e ? e._id.split(" ") : this.state._id

            let response = await axios.post(`./admin/realsePayments`, ID);

            if (!!response.data.errors && response.data.errors.length > 0) {
                this.state.releaseDisable = false;
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            else {
                toast.success("Paypment has been done successfully.")
                this.state.releaseDisable = false;
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState({
                loading: false,
                releaseDisable: false,
            });
        }
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        let param = {
            status: this.state.filter.status,
            q: this.state.q,
        }

        /* && (this.state.filter.start_date_str != null && this.state.filter.end_date_str != null) */
        if ((this.state.filter.status == 5 || this.state.filter.status == 6)) {

            param['start'] = this.state.filter.start_date_str
            param['end'] = this.state.filter.end_date_str

        } else {
            if (this.state.filter.status != 2 && this.state.filter.status != 4 && this.state.filter.status != 3) {
                this.datePickerFrom.current.input.value = null;
                this.datePickerFrom.current.state.value = null;
                this.datePickerTo.current.state.value = null;
                this.datePickerTo.current.input.value = null;
                this.state.filter.start_date_str = null;
                this.state.filter.end_date_str = null;
                this.state.filter.end_date = null;
                this.state.filter.start_date = null;
            }
        }
        try {
            if (this.state.sortAble == true) {
                /* /reports/smallBusinessOrders/:perPage/:page */
                let response = await axios.get(`./admin/reports/smallBusinessOrders/${ITEMS_PER_PAGE}/${this.state.activePage}${this.state.sortData}`, {
                    params: param
                });
                

                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                }

                this.setState({
                    loading: false,
                    items: response.data.data.items,
                    total: response.data.data.total_count
                });
            } else {
                let response = await axios.get(`./admin/reports/smallBusinessOrders/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                    params: param
                });
                
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                }

                this.setState({
                    loading: false,
                    items: response.data.data.items,
                    total: response.data.data.total_count
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    startDateEndDateValidation() {
        if (this.state.filter.start_date_str != null && this.state.filter.end_date_str != null) {
            this._loadList();
        } else if (this.state.filter.start_date_str == null && this.state.filter.end_date_str != null) {
            toast.error("Please select valid start date");
        } else if (this.state.filter.start_date_str != null && this.state.filter.end_date_str == null) {
            toast.error("Please select valid end date");
        } else if (this.state.filter.start_date_str == null && this.state.filter.end_date_str == null) {
            toast.error("Please select valid start date and end date");
        }
    }

    async _clearDate() {
        this.datePickerFrom.current.input.value = null;
        this.datePickerFrom.current.state.value = null;
        this.datePickerTo.current.state.value = null;
        this.datePickerTo.current.input.value = null;
        this.setState({
            filter: {
                start_date: null,
                end_date: null,
                start_date_str: null,
                end_date_str: null,
                status: "6"
            }
        });
        this._loadList();
    }

    /* assignDriver(item) {
        document.location.href = `./admin#/reports/orders/assigndriver/${item.orderId}`
    } */

    sortingHanlde(data) {
        if (data == "_id") {
            if (this.state.bookingId == 1) {
                this.setState({
                    bookingId: -1,
                    sortAble: true,
                    sortData: `?BookingID=${this.state.bookingId}`
                }, () => {
                    this._loadList()
                });
            } else if (this.state.bookingId == -1) {
                this.setState({
                    bookingId: 1,
                    sortAble: true,
                    sortData: `?BookingID=${this.state.bookingId}`
                }, () => {
                    this._loadList()
                });
            }
        }
        if (data == "user") {
            if (this.state.user == 1) {
                this.setState({
                    user: -1,
                    sortAble: true,
                    sortData: `?user=${this.state.user}`
                }, () => {
                    this._loadList()
                });
            } else if (this.state.user == -1) {
                this.setState({
                    user: 1,
                    sortAble: true,
                    sortData: `?user=${this.state.user}`
                }, () => {
                    this._loadList()
                });
            }
        }

        if (data == "trip_date") {
            if (this.state.trip_date == 1) {
                this.setState({
                    trip_date: -1,
                    sortAble: true,
                    sortData: `?trip_date=${this.state.trip_date}`
                }, () => {
                    this._loadList()
                });
            } else if (this.state.trip_date == -1) {
                this.setState({
                    trip_date: 1,
                    sortAble: true,
                    sortData: `?trip_date=${this.state.trip_date}`
                }, () => {
                    this._loadList()
                });
            }
        }

        if (data == "vehicle_type") {
            if (this.state.vehicle_type == 1) {
                this.setState({
                    vehicle_type: -1,
                    sortAble: true,
                    sortData: `?vehicle_type=${this.state.vehicle_type}`
                }, () => {
                    this._loadList()
                });
            } else if (this.state.vehicle_type == -1) {
                this.setState({
                    vehicle_type: 1,
                    sortAble: true,
                    sortData: `?vehicle_type=${this.state.vehicle_type}`
                }, () => {
                    this._loadList()
                });
            }
        }

        if (data == "payment_status") {
            if (this.state.payment_status == 1) {
                this.setState({
                    payment_status: -1,
                    sortAble: true,
                    sortData: `?payment_status=${this.state.payment_status}`
                }, () => {
                    this._loadList()
                });
            } else if (this.state.payment_status == -1) {
                this.setState({
                    payment_status: 1,
                    sortAble: true,
                    sortData: `?payment_status=${this.state.payment_status}`
                }, () => {
                    this._loadList()
                });
            }
        }
    }

    checkid = (e, value) => {
        if (e.target.checked) {
            //append to array
            this.setState(previousState => {
                return {
                    _id: [...previousState._id, value]
                }
            })
        } else {
            //remove from array
            this.setState({
                _id: this.state._id.filter(function (val) { return val !== value })
            })
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                    <td>
                        <input
                            type="checkbox"
                            key={item._id}
                            disabled={item.paid_by_linx == true || item.status != 'finished' ? true : false}
                            // name="releasepayment"
                            //value={item._id}
                            onChange={(e) => this.checkid(e, item._id)}
                        />
                    </td>
                }
                <td>{item.orderId}</td>
                <td>{item.owner_name}</td>
                <td>{item.owner_address}</td>
                {/* <td>{item.shipping_store_address ? item.shipping_store_address : '-'}</td> */}
                {/* <td>{item.recipient_address}</td> */}
                {/* <td>{item.car_type ? item.car_type.name : '-'}</td> */}

                {/* {(this.state.filter.status == "2" || this.state.filter.status == "6" || this.state.filter.status == "3" || this.state.filter.status == "4" || this.state.filter.status == "5") &&
                    <td>{item.courier ? item.courier.first_name : "-"}</td>
                } */}
                {/* {
                    this.state.filter.status == "4" &&
                    <td>{item.courier ? item.courier.first_name : "-"}</td>

             }  */}
                <td>{moment(item.pickup_date).format("YYYY/MM/DD HH:mm")}</td>
                {/* {(this.state.filter.status == "1" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                    <td>
                        {(item.status == "wait_pick_up" || item.status == "in_progress" || item.status == "wait_accept_delivery" || item.status == "canceled" || item.status == "finished") ? "-" : <Button onClick={() => this.assignDriver(item)} bsStyle="success">Assign Driver</Button>}
                    </td>
                } */}
                {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                    <td>{item.paid_amount ? `$${(item.paid_amount).toFixed(2)}` : '-'}</td>
                }
                {/* {this.state.filter.status == "3" &&
                    <td>{item.courier ? item.courier.first_name : '-'}</td>
                } */}
                {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                    <td>{((item.paid_by_linx == true && item.status == "finished") || ((item.paid_by_linx == false) && (item.status == "canceled" || item.status == "wait_pick_up" || item.status == "in_progress" || item.status == "wait_accept_delivery" || item.status == "new" || item.status == "hold"))) ? (
                        '-'
                    ) :
                        <Button disabled={this.state.releaseDisable} onClick={() => this.bulkrelease(item)} bsStyle="success">Release Payment</Button>
                    }
                    </td>
                }
                {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                    <td>{item.paid_by_linx == false && item.status !== "canceled"  ? "Pending" : item.status == "canceled" ? "Refunded" :  "Payment Released"}</td>
                }
                <td>{item.status == "new" || item.status == "hold" ? "Unassigned" : item.status == "wait_pick_up" || item.status == "in_progress" || item.status == "wait_accept_delivery" ? "In Progress" : item.status == "canceled" ? "Cancelled" : item.status == "finished" ? "Completed" : '-'}</td>
                <td><Link to={{
                    pathname: `/reports/businessSubOrders/${item._id}`,
                    state: {
                        /* orderId: item.orderId,
                        ownerName: item.owner_name,
                        ownerAddress: item.owner_address, */
                        filterStatus: this.state.filter.status
                    }
                }}>
                    <i className={"fa fa-eye"} style={{ cursor: 'pointer' }} /></Link></td>
                {/* <Button disabled={true} onClick={() => this.bulkrelease(item)} bsStyle="success">Release Payment</Button> */}
            </tr>
        );
    }

    _renderTitle() {
        return (
            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
        );
    }

    render() {
        var oneYearAgo = new Date();
        oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>

                            {this._renderTitle()}



                            <div className={"title_right"} style={{ minHeight: '89.42px' }}>
                                <div className={"col-lg-8 form-group pull-right"} >
                                    {(this.state.filter.status == '1' || this.state.filter.status == '5' || this.state.filter.status == "6") ? <div className={"row"}>
                                        <div className={"col-md-4"}>
                                            <div className={"form-group"}>
                                                <span className="InputFromTo-to">
                                                    <DayPickerInput
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        ref={this.datePickerFrom}
                                                        // value={this.state.filter.start_date_str}
                                                        formatDate={formatDate}
                                                        format={'YYYY/MM/DD'}
                                                        parseDate={parseDate}
                                                        placeholder={"YYYY/MM/DD"}
                                                        dayPickerProps={{
                                                            disabledDays: {
                                                                before: oneYearAgo,
                                                                after: Date.parse(this.state.filter.end_date) ? this.state.filter.end_date : new Date(),
                                                            },
                                                        }}
                                                        inputProps={{
                                                            className: "form-control col-md-7 col-xs-12"
                                                        }}
                                                        onDayChange={(from) => {
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    filter: {
                                                                        ...previousState.filter,
                                                                        status: "5",
                                                                        start_date: from,
                                                                        start_date_str: moment(from).format("YYYY-MM-DD")
                                                                    }
                                                                };
                                                            });
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <div className={"form-group"}>
                                                <span className="InputFromTo-to">
                                                    <DayPickerInput
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        ref={this.datePickerTo}
                                                        // value={this.state.filter.end_date_str}
                                                        formatDate={formatDate}
                                                        format={'YYYY/MM/DD'}
                                                        parseDate={parseDate}
                                                        placeholder={"YYYY/MM/DD"}
                                                        dayPickerProps={{
                                                            disabledDays: {
                                                                before: Date.parse(this.state.filter.start_date) ? this.state.filter.start_date : oneYearAgo,
                                                                after: new Date(),
                                                            },
                                                        }}
                                                        inputProps={{
                                                            className: "form-control col-md-7 col-xs-12"
                                                        }}
                                                        onDayChange={(from) => {
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    filter: {
                                                                        ...previousState.filter,
                                                                        status: "5",
                                                                        end_date: from,
                                                                        end_date_str: moment(from).format("YYYY-MM-DD")
                                                                    }
                                                                };
                                                            });
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"row"} style={{ textAlign: "right" }}>
                                            <div className={"col-md-2"}>
                                                <Button onClick={(start, end) => this.setState({
                                                    activePage: 1,
                                                }, () => {
                                                    this.startDateEndDateValidation();

                                                })} style={{ marginLeft: '5px', marginBottom: "0px" }} bsStyle="success">Search</Button>
                                            </div>
                                            <div className={"col-md-2"}>
                                                <Button onClick={(start, end) => this.setState({
                                                    activePage: 1,
                                                    filter: {
                                                        status: "6"
                                                    }
                                                }, () => {
                                                    this._clearDate(start, end);
                                                })} style={{ marginLeft: '5px', marginBottom: "0px" }} bsStyle="success">Clear</Button>
                                            </div>

                                        </div>
                                    </div> : ""}
                                </div>
                            </div>
                            <div className={"clearfix"}></div>
                            <div className={"title_right"}>
                                <div className={"col-lg-3 form-group pull-right no-padding"} style={{ textAlign: "right", maxWidth: "100%" }}>

                                    <div className={""}>
                                        <div className={"col-md-12 no-padding"}>
                                            <div className={"form-group"} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                <label style={{ margin: "0", paddingRight: "15px", color: "white", fontSize: "16px" }}>Order Filter</label>
                                                <select
                                                    value={this.state.filter.status}
                                                    onChange={event => {
                                                        event.persist();
                                                        this.setState(previousState => {
                                                            return {
                                                                ...previousState,
                                                                activePage: 1,
                                                                filter: {
                                                                    ...previousState.filter,
                                                                    status: event.target.value
                                                                },
                                                                sortAble: false
                                                            };
                                                        }, () => {
                                                            this._loadList()
                                                        })
                                                    }}
                                                >
                                                    <option value="6">All</option>
                                                    <option value="1">Unassigned</option>
                                                    <option value="2">Assigned</option>
                                                    <option value="4">Cancelled</option>
                                                    <option value="3">Completed</option>

                                                </select>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className={"col-md-9 col-sm-12 col-xs-12 form-group pull-left top_search"} style={{ margin: "0" }}>
                                    <div className={"input-group"} style={{ margin: "0" }}>
                                        <input
                                            type="text"
                                            className={"form-control"}
                                            placeholder="Search for..."
                                            value={this.state.q}
                                            onChange={event => {
                                                this.setState({
                                                    activePage: 1,
                                                    q: event.target.value,
                                                    filter: {
                                                        status: this.state.filter.status
                                                    }
                                                }, () => {
                                                    this._loadList()
                                                });
                                            }}
                                        />
                                        <span className={"input-group-btn"}>
                                            <button className={"btn btn-default"} type="button" onClick={() => {
                                                this.setState({
                                                    activePage: 1,
                                                    q: ""
                                                }, () => {
                                                    this._loadList()
                                                });
                                            }}>Cancel</button>
                                        </span>
                                    </div>
                                </div>

                                <div className='bulk-release-btn'>
                                    {(this.state.filter.status == "3" || this.state.filter.status == "6") ?
                                        <div className={"form-group"}>
                                            {this.state._id.length > 0 && <Button className='btn-bulk' disabled={this.state.releaseDisable} onClick={() => this.bulkrelease()} bsStyle="success">Bulk Release</Button>}
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div className={"clearfix"}></div>
                        </div>

                        {this.state.items.length > 0 ? (
                            <div className={"x_content"}>
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap table_scroll order_table"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                                                <th>Select</th>
                                            }
                                            <th>Booking ID<button className="btn-sort btn-acen" onClick={() => { this.sortingHanlde("_id") }}><i className={this.state.bookingId == 1 ? "fa fa-chevron-down" : "fa fa-chevron-up"} aria-hidden="true"></i></button></th>
                                            <th>User<button className="btn-sort btn-acen" onClick={() => { this.sortingHanlde("user") }}><i className={this.state.user == 1 ? "fa fa-chevron-down" : "fa fa-chevron-up"} aria-hidden="true"></i></button></th>
                                            <th>Pick up location</th>
                                            {/* <th>Shipping Destination</th> */}
                                            {/* <th>Final Destination</th> */}
                                            {/* <th>Vehicle Type<button className="btn-sort btn-acen" onClick={() => { this.sortingHanlde("vehicle_type") }}><i className={this.state.vehicle_type == 1 ? "fa fa-chevron-down" : "fa fa-chevron-up"} aria-hidden="true"></i></button></th> */}
                                            {/* {(this.state.filter.status == "2" || this.state.filter.status == "4" || this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                                                <th>Assigned To</th>
                                            } */}
                                            {/* {
                                                this.state.filter.status == "4" &&
                                                <th>Assigned To</th>
                                            } */}
                                            <th>Trip date/time<button className="btn-sort btn-acen" onClick={() => { this.sortingHanlde("trip_date") }}><i className={this.state.trip_date == 1 ? "fa fa-chevron-down" : "fa fa-chevron-up"} aria-hidden="true"></i></button></th>
                                            {/* {(this.state.filter.status == "1" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                                                <th>Assigned Driver Action</th>
                                            } */}
                                            {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                                                <th>Total</th>
                                            }
                                            {/* {this.state.filter.status == "3" &&
                                                <th>Driver</th>
                                            } */}
                                            {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                                                <th>Payment Action</th>
                                            }
                                            {(this.state.filter.status == "3" || this.state.filter.status == "6" || this.state.filter.status == "5") &&
                                                <th style={{ minWidth: "200px" }}>Payment Status<button className="btn-sort btn-acen" onClick={() => { this.sortingHanlde("payment_status") }}><i className={this.state.payment_status == 1 ? "fa fa-chevron-down" : "fa fa-chevron-up"} aria-hidden="true"></i></button></th>
                                            }
                                            <th>Order Status</th>
                                            <th>Sub order view</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.items.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}
                                    </tbody>
                                </table>


                            </div>
                        ) : <h4 style={{ marginLeft: "700px" }}>No Data Found</h4>}
                    </div>

                    {!this.state.loading && <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.ceil(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div>}

                </div>

                {/* {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoader
                        style={{ margin: 'auto' }}
                        color={'#0019bc'}
                        loading={this.state.loading}
                    />
                </div>} */}
            </div>
        );
    }

}