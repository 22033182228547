import React, { Component } from 'react';
import logo from '../assests/logonew.png';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios/index";

import appStore from '../assests/app-store.png';
// import '../styles/SiteJs/jquery.min.js';
// import '../styles/SiteJs/owl.carousel.min.js;';
// import '../styles/SiteJs/main';
import googleStore from '../assests/google-store.png';
import n1 from '../assests/n1.png';
import n2 from '../assests/n2.png';
import n3 from '../assests/n3.png';
import n4 from '../assests/n4.png';
// import appIcon from '../assests/app-icon.svg';
import storeApp from '../assests/app-store.png';
import T1 from '../styles/img/T1.png';
import T2 from '../styles/img/T2.png';
import T3 from '../styles/img/T3.png';
import T4 from '../styles/img/T4.png';
import T5 from '../styles/img/T5.png';


var regExp = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

class LinxSite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            question: '',
            message: '',
            isError: {
                email: '',
                name: '',
                message: ''
            }
        }
    };

    async submit() {

        const { email, name, isError } = this.state

        email === "" ? isError.email = "Email Address is required" : ""
        name === "" ? isError.name = "Name is required" : ""
        // message === "" ? isError.message = "Message is required" : ""


        email !== "" && name === ""
            ? isError.name = "Name is required"
            : email === "" && name !== "" ? isError.email = "Email Address is required" : ""
        this.state = {

            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            question: this.state.question ? this.state.question : 'Question 1'

        }
        if (isError.email == "" && isError.name == '') {
            let response = await axios.post(`./admin/question`, this.state);

            this.setState(previousState => {
                return {
                    ...previousState,
                    // loading: false,
                    email: '',
                    name: ' ',
                    message: '',
                    question: ''
                };
            }, () => {
                toast.success("Message has been sent successfully.");
            }
            );
        }
        else {
            toast.error("Email or Name is invalid.");
        }

    }



    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        const { email } = this.state

        let isError = { ...this.state.isError };
        switch (name) {
            case "email":
                email.length === 1 ? isError.email = " " :
                    isError.email = regExp.test(value)
                        ? ""
                        : "Email address is invalid";
                break;
            case "name":
                name.length === 1 ? isError.name = " " :
                    isError.name =
                    value.length < 3 ? "Atleast 3 characters required" : "";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    };

    render() {
        const { isError } = this.state;

        return (
            <div>
                <header id="header" className="fixed-top">
                    <div className="container">
                        <div className="logo float-left">
                            <a href="#intro" class6="scrollto"><img src={logo} alt="" className="img-fluid" /></a>
                        </div>
                        <nav className="main-nav float-right d-none d-lg-block">
                            <ul>
                                <li className="active"><a href="#intro">Home</a></li>
                                <li><a href="#Features">Features</a></li>
                                <li><a href="#faq">FAQ</a></li>
                                <li><a href="#app">Download</a></li>
                                {/* <li><a href="#testimonial">Testimonial</a></li> */}
                                <li><a href="#contact">Contact</a></li>
                            </ul>
                        </nav>
                    </div>
                </header>

                <section id="intro" className="flex-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-5 intro-text order-lg-1 order-2  wow fadeInUp">
                                <h2>ON-DEMAND<br /><span>PROFESSIONAL</span><br />SERVICES.</h2>
                                <h3>Linx is an on-demand pick-up and delivery service providing convenience to daily life. Download the mobile app FREE today.</h3>
                                <div className="application">
                                    <a href="https://apps.apple.com/us/app/linx-package-delivery/id6443557418" className="scrollto"><img src={appStore} className="img-fluid" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.app.package.customer" className="scrollto"><img src={googleStore} className="img-fluid" /></a>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 intro-img order-lg-2 order-1  wow fadeInUp">
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="Features">
                        <div className="container">
                            <header className="section-header wow zoomIn">
                                <h3>FEATURES</h3>
                            </header>
                            <div className="row feature-extra mt-5 mb-5 align-items-center">
                                <div className="col-lg-6 wow fadeInLeft">
                                    <img src={n2} className="img-fluid" alt="" />
                                </div>
                                <div className="col-lg-6 wow fadeInRight pt-5 pt-lg-0">
                                    <div className="feature-info">
                                        <h4>PACKAGE RETURNS</h4>
                                        <p>
                                            Shopping online provides convenience, but sometimes incorrect products are received. This
                                            creates the hassle of package returns. With Linx you can have a professional parcel runner pick
                                            up and deliver items that need returned to a local package return location. Simply upload your
                                            return item’s QR code, choose your pick-up/destination points, and watch Linx work with our
                                            live runner map and step by step notifications.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Features-2">
                            <div className="container">
                                <div className="row feature-extra mt-5 align-items-center">
                                    <div className="col-lg-6 p pt-5 pt-lg-0 order-lg-1 order-2 wow fadeInUp">
                                        <div className="feature-info">
                                            <h4>PEER TO PEER</h4>
                                            <p>
                                                Life is busy and forgetfulness is often a result. With Linx peer to peer you can rest easy knowing
                                                that we are here to pick up and deliver lawful items to your desired peer. The Linx 2 step
                                                verification and notifications flow ensure your lawful item is delivered to the correct and
                                                desired individual.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6  order-lg-2 order-1 text-right wow fadeInUp">
                                        <img src={n1} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container pb-5">
                            <div className="row feature-extra mt-5 align-items-center">
                                <div className="col-lg-6 wow fadeInLeft">
                                    <img src={n3} className="img-fluid" alt="" />
                                </div>
                                <div className="col-lg-6 wow fadeInRight pt-5 pt-lg-0">
                                    <div className="feature-info">
                                        <h4>PACKAGE SHIPPING</h4>
                                        <p>
                                            With Linx package shipping, you will never have to enter a shipping store again. You can now
                                            have your boxed and labeled packages retrieved from your location and delivered to your
                                            desired destination. Once the pickup location, shipping store destination, and final package
                                            destination is entered; your package will be on its way to the receiving party.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Features-2-1" className="mb-0">
                            <div className="container">
                                <div className="row feature-extra mt-5 align-items-center">
                                    <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0 order-lg-1 order-2">
                                        <div className="feature-info">
                                            <h4>STAY CONNECTED</h4>
                                            <p>
                                                Linx keeps you connected with your assigned professional parcel runner via call or chat for any
                                                questions you may have. Notifications are sent every step of the way to keep you updated on
                                                your parcel transaction.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 wow fadeInUp order-lg-2 order-1 text-right">
                                        <img src={n4} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="faq">
                        <div className="container">
                            <header className="section-header wow zoomIn">
                                <h3>FREQUENTLY ASKED QUESTIONS</h3>
                            </header>
                        </div>
                        <div className="container">
                            <div className="faqs_page pt-5 pb-5 wow fadeInUp">
                                <div className="faq_detail">
                                    <div className="faq_questions">
                                        <div className="que_ans open">
                                            <div className="q small_head">
                                                IS MY INFORMATION SECURE?
                                                <div className="border_div">
                                                    <div className="circle">
                                                        <div className="horizontal"></div>
                                                        <div className="vertical"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="a">
                                                <p>Yes, your information is secure with our integrated data encrypted technology that is built
                                                    directly into the mobile application to ensure your information is safe. With this integration,
                                                    your credit card and personal information can’t be compromised.</p>
                                            </div>
                                        </div>
                                        <div className="que_ans close_que">
                                            <div className="q small_head">
                                                WHAT IF MY PACKAGE IS LOST OR STOLEN?
                                                <div className="circle">
                                                    <div className="horizontal"></div>
                                                    <div className="vertical"></div>
                                                </div>
                                            </div>
                                            <div className="a">
                                                <p>With our strict professional parcel runner standards, we provide the safest transportation for
                                                    your valuables. Each parcel runner is required to supply necessary documents along with a full
                                                    background check. If an item is reported lost or stolen, an immediate investigation will be
                                                    launched to locate your item. If your item is not found, you will be financially compensated for
                                                    your lost or stolen item.</p>
                                            </div>
                                        </div>
                                        <div className="que_ans close_que">
                                            <div className="q small_head">
                                                HOW DO I PAY?
                                                <div className="circle">
                                                    <div className="horizontal"></div>
                                                    <div className="vertical"></div>
                                                </div>
                                            </div>
                                            <div className="a">
                                                <p>A one-time debit card entry will allow you to access Linx “Life on demand” services. You can
                                                    add, change, or delete debit cards in your account settings at any time. You will be charged
                                                    upon request for the desired services. The transaction will not be completed until your assigned
                                                    parcel runner confirms the package or item is delivered to the chosen destination.</p>
                                            </div>
                                        </div>
                                        <div className="que_ans close_que">
                                            <div className="q small_head">
                                                CAN I SEND MULTIPLE PACKAGES?
                                                <div className="circle">
                                                    <div className="horizontal"></div>
                                                    <div className="vertical"></div>
                                                </div>
                                            </div>
                                            <div className="a">
                                                <p>Yes, you can send multiple packages or items. Linx has multiple options including package size
                                                    and package count. After making your selection, you will then be prompted to select a vehicle
                                                    type capable of transporting your package/s. Regarding package returns, you will have the
                                                    option to upload multiple return item QR codes for a convenient return transaction.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="app">
                        <div className="container">
                            <div className="row align-items-center wow fadeInUp">
                                <div className="col-md-7 col-12 order-1">
                                    {/* <div className="app-image">
                                        <img src={logo} alt="app-icon" className="img-fluid" />
                                    </div> */}
                                </div>
                                <div className="col-md-5 col-12 text-left order-2">
                                    <div class="app-info">
                                        <header class="section-header wow zoomIn">
                                            <h3 class="app-title text-white font-weight-bold pb-3 pb-0 text-left">Get the App now</h3>
                                        </header>
                                        <p class="app-descr text-white ">Do “Life on Demand”. Download the Linx mobile app today and add convenience to your daily life.</p>
                                        <h4 class="text-white font-weight-bold pb-0 text-left wow zoomIn">For Driver</h4>
                                        <div class="application">
                                            <a href="https://apps.apple.com/us/app/linx-driver/id1612591556" className="scrollto"><img src={storeApp} className="img-fluid" /></a>
                                            <a href="https://play.google.com/store/apps/details?id=com.app.package.driver" className="scrollto"><img src={googleStore} className="img-fluid" /></a>
                                        </div>
                                    </div>
                                    <div class="app-info mt-5">
                                        <h4 class="text-white font-weight-bold pb-0 text-left wow zoomIn">For Customer</h4>
                                        <div class="application">
                                            <a href="https://apps.apple.com/us/app/linx-package-delivery/id6443557418" className="scrollto"><img src={storeApp} className="img-fluid" /></a>
                                            <a href="https://play.google.com/store/apps/details?id=com.app.package.customer" className="scrollto"><img src={googleStore} className="img-fluid" /></a>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section id="testimonial">
                        <div className="container">
                            <header className="section-header wow zoomIn">
                                <h3>Testimonial</h3>
                            </header>
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="owl-carousel testimonials-carousel wow fadeInUp">
                                        <div className="testimonial-item">
                                            <p className="content">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                <br /><br />
                                                <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                                            </p>
                                            <img src={T1} className="testimonial-img" alt="" />
                                            <h3>Saul Goodman</h3>
                                        </div>
                                        <div className="testimonial-item">
                                            <p className="content">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                <br /><br />
                                                <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                                            </p>
                                            <img src={T2} className="testimonial-img" alt="" />
                                            <h3>Sara Wilsson</h3>
                                        </div>
                                        <div className="testimonial-item">
                                            <p className="content">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                <br /><br />
                                                <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                                            </p>
                                            <img src={T3} className="testimonial-img" alt="" />
                                            <h3>Jena Karlis</h3>
                                        </div>
                                        <div className="testimonial-item">
                                            <p className="content">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                <br /><br />
                                                <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                                            </p>
                                            <img src={T4} className="testimonial-img" alt="" />
                                            <h3>Matt Brandon</h3>
                                        </div>
                                        <div className="testimonial-item">
                                            <p className="content">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                <br /><br />
                                                <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                                            </p>
                                            <img src={T5} className="testimonial-img" alt="" />
                                            <h3>John Larson</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section id="contact">
                        <div className="container mt-5">
                            <header className="section-header wow zoomIn">
                                <h3>Contact & Partnership</h3>
                            </header>
                            <div className="form-bg">
                                <div className="row wow fadeInUp">
                                    <div className="form col-lg-7 col-md-12">
                                        <div id="errormessage"></div>
                                        {/* <form action="" method="post" role="form" className="contactForm"> */}
                                        <div className="form-row">
                                            <div className="form-group col-lg-6">
                                                <input
                                                    onChange={event => {
                                                        event.persist();
                                                        this.setState(previousState => {
                                                            return {
                                                                ...previousState,
                                                                name: event.target.value
                                                            };
                                                        })
                                                        this.formValChange(event)
                                                    }} value={this.state.name} type="text" name="name" className="form-control" id="name" placeholder="Fullname"
                                                />
                                                {isError.name.length > 0 ? <p className='text-danger'>{isError.name}</p> : ""}
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <input
                                                    onChange={event => {
                                                        event.persist();
                                                        this.setState(previousState => {
                                                            return {
                                                                ...previousState,
                                                                email: event.target.value
                                                            };
                                                        })
                                                        this.formValChange(event)

                                                    }} value={this.state.email} type="email" className="form-control" name="email" id="email" placeholder="Email Address*"
                                                />
                                                {isError.email.length > 0 ? <p className='text-danger'>{isError.email}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="question" placeholder='Question' value={this.state.question} onChange={(e) => {
                                                this.setState({
                                                    question: e.target.value
                                                })
                                            }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <textarea
                                                onChange={event => {
                                                    event.persist();
                                                    this.setState(previousState => {
                                                        return {
                                                            ...previousState,
                                                            message: event.target.value
                                                        };
                                                    })
                                                }} value={this.state.message} className="form-control" name="message" rows="5" data-rule="required" placeholder="Your message"></textarea>
                                            <div className="validation"></div>
                                        </div>
                                        <div className="text-right">
                                            <button onClick={() => {
                                                this.submit();
                                            }} title="Send Message" className="submit-btn">Send Your Message</button>
                                        </div>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>


                <footer id="footer">
                    <div className="container">
                        <div className="row align-items-center pb-2 border-bottom">
                            <div className="col-md-6">
                                <div className="copyright-menu">
                                    <ul className="pl-0">
                                        <li>
                                            <a href="#faq">FAQs</a> <span className="pipe">|</span>
                                        </li>
                                        <li>
                                            <a href="https://app.websitepolicies.com/policies/view/w6gv9jzl">Privacy Policy</a> <span className="pipe">|</span>
                                        </li>
                                        <li>
                                            <a href="https://app.websitepolicies.com/policies/view/hxfzr7m3">Terms & Conditions</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="float-right text-right">
                                    <div className="footer-social-icons">
                                        <ul className="social-icons">
                                            <li><a href="#intro" className="social-icon"> <i className="fa fa-facebook"></i></a></li>
                                            <li><a href="#intro" className="social-icon"> <i className="fa fa-twitter"></i></a></li>
                                            {/* <li><a href="#intro" className="social-icon"> <i className="fa fa-google-plus"></i></a></li> */}
                                            <li><a href="#intro" className="social-icon"> <i className="fa fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="copyright">
                            &copy; 2022 <a href="#">Linx </a>. All Rights Reserved.
                        </div>
                    </div>
                </footer>
                <ToastContainer />

            </div>
        );

    }
}
export default LinxSite;