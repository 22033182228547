import React, { Component } from 'react';
import logo from '../../assests/logo.png';


class TermsCondition extends Component{
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return(
            <div>
            {/* <header id="header" className="fixed-top">
         <div className="container">
            <div className="logo float-left">
               <a href="#intro" className="scrollto bottom-logo"><img src={logo} alt="" className="img-fluid" /></a>
            </div>
            <nav className="main-nav float-right d-none d-lg-block">
               <ul>
                  <li className="active"><a href="intro">Home</a></li>
                  <li><a href="./index.html#Features">Features</a></li>
                  <li><a href="./index.html#faq">FAQ</a></li>
                  <li><a href="./index.html#app">Download</a></li>
                  <li><a href="./index.html#testimonial">Testimonial</a></li>
                  <li><a href="./index.html#contact">Contact</a></li>
               </ul>
            </nav>
         </div>
      </header> */}

      <main id="main">
         <section className="hero-banner d-flex align-items-center">
           <div className="container text-center wow zoomIn">
               <h2>Terms And Conditions</h2>
               {/* <nav aria-label="breadcrumb" className="banner-breadcrumb">
                   <ol className="breadcrumb">
                       <li className="breadcrumb-item"><a href="#">Home</a></li>
                       <li className="breadcrumb-item active" aria-current="page">Terms And Conditions</li>
                   </ol>
               </nav> */}
           </div>
         </section>
         <section className="paddings">
            <div className="container">
               <div className="row wow fadeInUp">
                  <div className="col-12">
                    <div className="inner-title">Terms And Conditions</div>                
                  </div>
                  <div className="col-12">
                     <div className="privacy-info">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsu</p>
                        <ul>
                           <li>conduct or post personally identifiable information on any person or patient,</li>
                           <li>post defamatory, abusive or threatening comments,</li>
                           <li>promote, advertise, offer to sell or buy any commercial products or services, without prior contract and authorization of&nbsp;<strong>Recovery and Retreats</strong>&nbsp;as the site owners.</li>
                           <li>post any inappropriate, obscene, illegal content,</li>
                           <li>upload any files containing programs, scripts, or other software of any type,</li>
                           <li>upload any content or files that you do not own or control the appropriate copyright, patent, intellectual property or trade secret rights to,</li>
                           <li>collect or attempt to collect information about other users of the service or&nbsp;<strong>Recovery and Retreats</strong>&nbsp;member, including but not limited to e-mail addresses, fax numbers and mailing addresses, unless you are retrieving the information to contact a board-certified&nbsp;<strong>Recovery and Retreats</strong>&nbsp;member surgeon for the purposes of your personal medical consultation or services,</li>
                           <li>create a false identity for any purpose or restrict, impede or attempt to impact the ability of others to access the Services in a normal and secure manner.</li>
                        </ul>
                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsu</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </main>

      <footer id="footer">
            <div className="container">
               <div className="row align-items-center pb-2 border-bottom">
                  <div className="col-md-6">
                     <div className="copyright-menu">
                        <ul className="pl-0">
                           <li>
                              <a href="./">Home</a> <span className="pipe">|</span>
                           </li>
                           <li>
                              <a href="privacypolicy">Privacy Policy</a> <span className="pipe">|</span>
                           </li>
                           <li>
                              <a href="termscondition">Terms & Conditions</a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="float-right text-right">
                        <div className="footer-social-icons">
                           <ul className="social-icons">
                              <li><a href="" className="social-icon"> <i className="fa fa-facebook"></i></a></li>
                              <li><a href="" className="social-icon"> <i className="fa fa-twitter"></i></a></li>
                              <li><a href="" className="social-icon"> <i className="fa fa-google-plus"></i></a></li>
                              <li><a href="" className="social-icon"> <i className="fa fa-instagram"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container">
               <div className="copyright">
                  &copy; 2022 <a href="#">Linx </a>. All Rights Reserved.
               </div>
            </div>
         </footer>
            </div>
        );
    }
}
export default TermsCondition;