import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import countryCodeList from '../country-code-list.json';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class EditManageCommission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            n: !!props.params.n,
            user: {
                category_name: " ",
                driver_share: 0,
                admin_share: 0,
                service_charge: 0,
                tax: 0
            },
            isError:false,
            breadcrumbObj:[
                {
                    title:'Manage Commission',
                    breadCrumbLink:'./admin#/managecommission',
                    active:false
                },
                {
                    title:'Edit Commission',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });

        if (!!this.state.id && this.state.id !== "new") {
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/commission/${this.state.id}`, {
                params: {}
            });
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.result.data
                    }
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }




    async _save() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            this.state.user.tax = isNaN(this.state.user.tax) ? 0: this.state.user.tax;
            this.state.user.service_charge = isNaN(this.state.user.service_charge) ? 0 : this.state.user.service_charge;
            this.state.user.admin_share = isNaN(this.state.user.admin_share) ? 0 : this.state.user.admin_share;
            this.state.user.driver_share = isNaN(this.state.user.driver_share) ? 0  : this.state.user.driver_share;
            if(this.state.user.tax <= 0 || this.state.user.service_charge <= 0 || this.state.user.admin_share <= 0 || this.state.user.driver_share <= 0) {
                this.state.isError = true;
            } else {
                this.state.isError = false;
            }
            if(this.state.isError === false) {
            let response = await axios.post(`./admin/commission/${this.state.id}`, this.state.user);
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.data
                    },
                    id: response.data.data._id
                };
            }, () => {
                toast.success("Commission updated successfully");
                history.push("/managecommission")
            });
        } else {
            this.setState({
                loading:false
            })
            toast.error("Please set commission values between 1 to 100")
        }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left"}>
                                    <div className={"row"}>
                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Category Name</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        name='category_name'
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.user.category_name}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        category_name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Driver's Share</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="number"
                                                        name="driver_share"
                                                        value={this.state.user.driver_share}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        driver_share: parseInt(event.target.value)
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Admin's Share</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="number"
                                                        name="admin_share"
                                                        value={this.state.user.admin_share}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        admin_share: parseInt(event.target.value)
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Service Charge</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="number"
                                                        name="service_charge"
                                                        value={this.state.user.service_charge}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        service_charge: parseInt(event.target.value)
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Tax</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control"}
                                                        type="number"
                                                        value={this.state.user.tax}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        tax: parseInt(event.target.value)
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    >
                                                    </input >
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                        </div>
                                    </div>
                                </form >
                            </div >
                        </div >
                    </div >
                </div >

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}
