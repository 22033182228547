import React, { Component } from 'react';
import LINX from '../assests/Biglogo.png';

export default class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cssManage: false,
      activeClassManage: true,
      activeUrl: '',
      customerOpen: false,
      currentUrl:window.location.hash
    }
  }
  handleChangeUrl = (url) => {
    window.location.hash= url
    this.setState({
      currentUrl:url
    })
  }

  render() {
    return (
      <div className={"col-md-3 left_col"}>
        <div className={"left_col scroll-view"}>
          <div className={"navbar nav_title"} style={{ border: 0 }}>
            <a href="#/" className={"site_title"}><img src={LINX} /></a>
          </div>

          <div className={"clearfix"}></div>
          <br />
          <div id="sidebar-menu" className={"main_menu_side hidden-print main_menu"}>
            <div className={"menu_section"}>
              <ul className={"nav side-menu"}>
                <li className={`${this.state.currentUrl === '#/' ? 'active' : '' ? 'active' : ''}`}><a href="#/" onClick={() => {this.handleChangeUrl('#/')}}><i className={"fa fa-dashboard"}></i> Dashboard</a></li>

                <li className={`${(
                  window.location.hash === '#/customers' ||
                  window.location.hash.split('/')[1] === 'view' ||
                  this.state.activeUrl === '#/customers' ||
                  window.location.hash === '#/drivers' ||
                  window.location.hash.split('/')[1] === 'driverview' ||
                  window.location.hash === '#/driver-cancel-order' ||
                  window.location.hash.split('/')[1] === 'users' ||
                  window.location.hash.split('/')[1] === 'drivers' ||
                  window.location.hash.split('/')[1] === 'customers' ||
                  window.location.hash.split('/')[1] === 'order' ||
                  window.location.hash.split('/')[1] === 'trip'
                ) ? 'active' : ''}`} onClick={() => {
                  this.setState(prevState => ({
                    customerOpen: !prevState.customerOpen,
                    currentUrl:''
                  }))
                }}><a><i className={"fa fa-user"}></i> Manage Users <span className={"fa fa-chevron-down"} /></a>
                  <ul className={` nav child_menu`} style={{
                    display: (
                      this.state.customerOpen === true ||
                      window.location.hash.split('/')[1] === 'view' ||
                      this.state.activeUrl === '#/customers' ||
                      window.location.hash === '#/customers' ||
                      window.location.hash === '#/drivers' ||
                      window.location.hash.split('/')[1] === 'driverview' ||
                      window.location.hash === '#/driver-cancel-order' ||
                      window.location.hash.split('/')[1] === 'users' ||
                      window.location.hash.split('/')[1] === 'drivers' ||
                      window.location.hash.split('/')[1] === 'customers' ||
                      window.location.hash.split('/')[1] === 'order' ||
                      window.location.hash.split('/')[1] === 'trip'
                    ) ? 'block' : 'none'
                  }}>
                    <li className={`${(
                      window.location.hash === '#/customers' ||
                      window.location.hash.split('/')[1] === 'view' ||
                      this.state.activeUrl === '#/customers' ||
                      window.location.hash.split('/')[1] === 'users' ||
                      window.location.hash.split('/')[1] === 'customers' ||
                      window.location.hash.split('/')[1] === 'order'
                    ) ? 'active' : ''}`}><a href="#/customers" onClick={() => {
                      this.setState(prevState => ({
                        activeClassManage: true,
                        customerOpen: !prevState.customerOpen,
                        activeUrl: '#/customers'
                      }));
                    }}>Customers</a></li>
                    <li className={`${(
                      window.location.hash === '#/drivers' ||
                      window.location.hash.split('/')[1] === 'driverview' ||
                      this.state.activeUrl === '#/drivers' ||
                      window.location.hash.split('/')[1] === 'drivers' ||
                      window.location.hash.split('/')[1] === 'trip'
                    ) ? 'active' : ''}`}><a href="#/drivers" onClick={() => {
                      this.setState(prevState => ({
                        cssManage: !prevState.cssManage,
                        activeClassManage: true,
                        customerOpen: !prevState.customerOpen,
                        activeUrl: '#/drivers'
                      }));
                    }}>Drivers</a>
                      <ul className={'nav child_menu'} style={{ display: (this.state.cssManage == true || window.location.hash === '#/driver-cancel-order') ? 'block' : 'none' }}>
                        <li className={`${window.location.hash === '#/driver-cancel-order' ? 'active' : ''}`}><a href="#/driver-cancel-order" style={{ color: this.state.cssManage == true && this.state.activeClassManage === true ? '#e7e7e7' : '#00e0b0' }} onClick={() => {
                          this.setState(prevState => ({
                            activeClassManage: false
                          }));
                        }} >Cancel Order</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className={`${this.state.currentUrl === '#/manufacturers' || this.state.currentUrl.split('/')[1] === 'manufacturers' ? 'active' : ''}`}><a href='#/manufacturers' onClick={() => {this.handleChangeUrl('#/manufacturers')}}><i className={"fa fa-car"}></i> Manage Vehicles</a></li>

                <li className={`${this.state.currentUrl === '#/promo' || this.state.currentUrl.split('/')[1] === 'promoview' || this.state.currentUrl.split('/')[1] === 'promo' ? 'active' : ''}`}><a href="#/promo" onClick={() => {this.handleChangeUrl('#/promo')}}><i className={"fa fa-tag fa-lg"} />Manage Promocode</a></li>

                <li className={`${(this.state.currentUrl === '#/reports/orders' || this.state.currentUrl.split('/')[1] === 'orders') ? 'active' : ''}`}><a href="#/reports/orders" onClick={() => {this.handleChangeUrl('#/reports/orders')}}><i className={"fa fa-clone"} />Manage Bookings</a>
                  <ul className={"nav child_menu"}>
                  </ul>
                </li>

                <li className={`${(this.state.currentUrl === '#/reports/businessOrders' || this.state.currentUrl.split('/')[2] === 'businessSubOrders' || this.state.currentUrl.split('/')[3] === 'assigndriver') ? 'active' : ''}`}><a href="#/reports/businessOrders" onClick={() => {this.handleChangeUrl('#/reports/businessOrders')}}><i className={"fa fa-clone"} /><span>Manage Business Bookings</span></a>
                </li>
                <li className={`${(this.state.currentUrl === '#/managecommission' || this.state.currentUrl.split('/')[1] === 'managecommission') ? 'active' : ''}`}><a href="#/managecommission" onClick={() => {this.handleChangeUrl('#/managecommission')}}><i className={"fa fa-percent"} />Manage Commissions</a></li>
                <li className={`${(this.state.currentUrl === '#/manageearnings' || this.state.currentUrl.split('/')[1] === 'managedriverearnings' || this.state.currentUrl.split('/')[1] === 'managedriverearninglist') ? 'active' : ''}`}><a href="#/manageearnings" onClick={() => {this.handleChangeUrl('#/manageearnings')}}><i className={"fa fa-usd"} />Manage Earnings</a></li>
                <li className={`${(this.state.currentUrl === '#/managereviews' || this.state.currentUrl.split('/')[1] === 'managereviews') ? 'active' : ''}`}><a href="#/managereviews" onClick={() => {this.handleChangeUrl('#/managereviews')}}><i className={"fa fa-star"} />Manage Reviews</a></li>
                <li className={`${(this.state.currentUrl === '#/cancellationpolicy' || this.state.currentUrl.split('/')[1] === 'cancellationpolicy') ? 'active' : ''}`}><a href="#/cancellationpolicy" onClick={() => {this.handleChangeUrl('#/cancellationpolicy')}}><i className={"fa fa-times"} />Cancellation Policy</a></li>
                <li className={`${(this.state.currentUrl === '#/settingradius' || this.state.currentUrl.split('/')[1] === 'editradius') ? 'active' : ''}`}><a href="#/settingradius" onClick={() => {this.handleChangeUrl('#/settingradius')}}><i className={"fa fa-cog"} />Manage Search Radius</a></li>
                <li className={`${(this.state.currentUrl === '#/help' || window.location.hash.split('/')[1] === 'help') ? 'active' : ''}`}><a href="#/help" onClick={() => {this.handleChangeUrl('#/help')}}><i className={"fa fa-question-circle"} /> Help</a></li>

              </ul>
            </div>

          </div>

          <div className={"sidebar-footer hidden-small"}>
          </div>

        </div>
      </div>
    );
  }

}
