import React, { Component } from 'react';
import Profile from '../assests/user_Profile.png'
import { withRouter } from 'react-router';
import { render } from 'react-dom';
import { Button, Modal, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { removeNotifications } from '../actions/notificationActions';
import { store } from "../index";

const removelocalStorage = (key) => {
    localStorage.removeItem(key)

}
class TopMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            notifications: []
        };
    }

logout = () => {
    removelocalStorage("token")
    this.props.history.push('./admin/login')
}

componentDidMount(){
    this.unsubscribe = store.subscribe(() => {
        this.setState(previousState => {
            return {
                ...previousState,
                notifications: store.getState().notifications.notifications
            };
        });
    });
}

componentWillUnmount() {
    this.unsubscribe();
}

_onNotificationClick(k){
    store.dispatch(removeNotifications());
}



    render() {
        return (
            <div className={"top_nav"}>
                <div className={"nav_menu"}>
                    <nav>
                        <div className={"nav toggle"}>
                            <a id={"menu_toggle"}><i className={"fa fa-bars"}></i></a>
                        </div>

                        <ul className={"nav navbar-nav navbar-right"}>
                            <li className={""}>
                                <a href="javascript:;" className={"user-profile dropdown-toggle"} data-toggle="dropdown" aria-expanded="false" >
                                    <img src={Profile} alt="" />
                                    <span className={" fa fa-angle-down"}></span>
                                </a>
                                <ul className={"dropdown-menu dropdown-usermenu pull-right"}>
                                    <li><a href="#/admin/profile"><i className={"fa fa-sign-out pull-right"}></i> My profile</a></li>
                                    <li><a onClick={this.logout} href='./admin/login'><i className={"fa fa-sign-out pull-right"}></i> Log out</a></li>
                                </ul>
                            </li>
                            <a href='#/notificationList' onClick={() => {this._onNotificationClick();}}>
                            <li className='notification-icon'>
                                <i className='fa fa-bell'></i>
                                {this.state.notifications.map((v, k, a) => {
                                    return (
                                        <span className='count-num'>{v.count}</span>
                                    )   
                                })}
                               
                            </li>
                            </a>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }

}
export default withRouter(TopMenu);