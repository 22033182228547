import Axios from 'axios';
import React, { Component } from 'react';
import axios from 'axios';

import increaseGraph from '../assests/increase-graph-profit.png'
import tabLine from '../assests/table-line.png'

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    CartesianGrid,
    BarChart,
    ResponsiveContainer,
    Bar,
} from "recharts";

import moment from 'moment';


class ManageDriverEarning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DriverEarning: {
                totalEarning: 0,
                compare: 0,
                status: 3
            },
            Order: {
                totalOrders: 0,
                totalcompare: 0,
                status: 3
            },
            filter: {
                range: "3",
                start_date: null,
                start_date_str: moment().format("YYYY/MM/DD"),
                end_date_str: moment().format("YYYY/MM/DD"),
                end_date: null,
                a2: []
            },
        }
    }

    componentDidMount() {
        this._loadList();
        this._totalEarnings();
        this._totalOrders();

    }

    async _loadList() {
        let param = {
            range: this.state.filter.range
        }
        let response = await axios.get(`./admin/statistics/users?driver=${1}`, {
            params: param

        });
        if (!!response.data.errors && response.data.errors.length > 0) {
            response.data.errors.map((err, i) => {
                toast.error(err);
            });
        }
        let a1 = [];
        response.data.data.map((v, k, a) => {
            var t = moment(v.created_at).format("MM/DD")
            const { start_date_str, end_date_str } = this.state;
            if (this.state.filter.range == 1 || (this.state.filter.range == 6)) {
                t = moment(v.created_at).format("HH:mm")
            }
            if (this.state.filter.range == 6 && start_date_str == end_date_str) {
                t = moment(v.created_at).format("MM/DD")
            }
            if (this.state.filter.range == 6 && this.state.filter.start_date_str == this.state.filter.end_date_str) {
                t = moment(v.created_at).format("HH:mm")
            }
            if (this.state.filter.range == 6 && start_date_str !== end_date_str) {
                t = moment(v.created_at).format("MM/DD")
            }
            a1.push({
                name: t,
                earnings: v.serviceFee ? (v.serviceFee).toFixed(2) : '-'
            });
        });
        this.setState({
            a2: a1,
        })
    
    }

    async _totalEarnings(event) {
        let response = await axios.get(`./admin/reports/totalEarning?range=${this.state.DriverEarning.status}`)
        if (!!response.data.errors && response.data.errors.length > 0) {
            response.data.errors.map((err, i) => {
                toast.error(err);
            });
        } else {
            this.setState({
                DriverEarning: {
                    totalEarning: response.data.data.DriverEarning.totalEarning,
                    compare: response.data.data.DriverEarning.compare
                }
            })
        }
    }

    async _totalOrders(event) {
        let response = await axios.get(`./admin/reports/totalOrders?range=${this.state.Order.status}`)
        if (!!response.data.errors && response.data.errors.length > 0) {
            response.data.errors.map((err, i) => {
                toast.error(err);
            });
        } else {
            this.setState({
                Order: {
                    totalOrders: response.data.data.totalOrders,
                    totalcompare: response.data.data.compare
                }
            })
        }
    }

    render() {
        const { a2 } = this.state;
        const { totalEarning } = this.state.DriverEarning;
        const { compare } = this.state.DriverEarning;
        const { totalOrders } = this.state.Order;
        const { totalcompare } = this.state.Order;

        return (
            <div className="manage-earning">
                <div className="x_panel order-detail-panel">
                    <div className="x_title">
                        <h2 style={{ color:"#00e0b0" }}>Manage Driver Earnings</h2>
                        <div className="form-group">
                            <a href='#/manageearnings' className="btn btn-success">See your earning</a></div>
                    </div>
                    <div className="row tile_count">
                        <div className="col-md-4 col-sm-4 col-xs-6 tile_stats_count">
                            <div className="d-flex">
                                <span className="count_top">Total Earning</span>
                                <select
                                    onChange={event => {
                                        event.persist();
                                        this.setState(previousState => {
                                            return {
                                                ...previousState,
                                                DriverEarning: {
                                                    ...previousState.DriverEarning,
                                                    status: event.target.value
                                                }
                                            };
                                        }, () => {
                                            this._totalEarnings(event)
                                        })
                                    }}
                                >
                                    <option value="3">This Month</option>
                                    <option value="1">Today</option>
                                    <option value="2">Current Week</option>

                                </select>
                            </div>

                            <div className="count"><b>$</b>{totalEarning}</div>
                            {compare > 0 ? (
                                <small><i className="fa fa-long-arrow-up" aria-hidden="true"></i><b>{compare.toFixed(2)}%</b> compare to last month</small>
                            ) :
                                <small><i className="fa fa-long-arrow-down" aria-hidden="true"></i><b>{Math.abs(compare.toFixed(2))}%</b> compare to last month</small>
                            }
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-6 tile_stats_count">
                            <div className="d-flex">
                                <span className="count_top">Total Orders</span>
                                <select
                                    onChange={event => {
                                        event.persist();
                                        this.setState(previousState => {
                                            return {
                                                ...previousState,
                                                Order: {
                                                    ...previousState.Order,
                                                    status: event.target.value
                                                }
                                            };
                                        }, () => {
                                            this._totalOrders(event)
                                        })
                                    }}
                                >
                                    <option value="3">This Month</option>
                                    <option value="1">Today</option>
                                    <option value="2">Current Week</option>

                                </select>
                            </div>

                            <div className="count">{totalOrders}</div>
                            {totalcompare > 0 ? (
                                <small><i className="fa fa-long-arrow-up" aria-hidden="true"></i><b>{totalcompare.toFixed(2)}%</b> compare to last month</small>
                            ) :
                                <small><i className="fa fa-long-arrow-down" aria-hidden="true"></i><b>{Math.abs(totalcompare.toFixed(2))}%</b> compare to last month</small>

                            }
                        </div>

                    </div>
                </div>
                <div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="dashboard_graph">
                                <div className=" x_title">
                                    <div className={"col-lg-2 form-group pull-right no-padding"} style={{ textAlign: "right", maxWidth: "165px" }}>
                                        <div className={""}>
                                            <div className={"col-md-12 no-padding"}>
                                                <div className={"form-group"}>

                                                    <select
                                                        value={this.state.filter.range}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    filter: {
                                                                        ...previousState.filter,
                                                                        range: event.target.value,
                                                                    }
                                                                };
                                                            }, () => {
                                                                this._loadList()
                                                            })
                                                        }}
                                                    >
                                                        <option value="1">Today</option>
                                                        <option value="2">This Week</option>
                                                        <option value="3">Last Week</option>
                                                        <option value="4">This Month</option>
                                                        <option value="5">Last Month</option>

                                                    </select>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                        <header className="tabs-nav">
                                            <ul>
                                                <li className="active"><a href="#tab1"><img src={increaseGraph} /></a></li>
                                                <li><a href="#/managedriverearninglist"><img src={tabLine} /></a></li>

                                            </ul>
                                        </header>

                                </div>

                                    <div id="container">
                                        {/* <ResponsiveContainer width={1600} height={500}> */}
                                        <div style={{width:"100%", height:"500px"}} className="graph-section">
                                            <BarChart width={1565} height={500} data={a2}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Bar dataKey="earnings" fill="#82ca9d" />
                                                <CartesianGrid stroke="#ccc" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip cursor={{ fill: "grey" }} />
                                            </BarChart>
                                        </div>
                                        {/* </ResponsiveContainer> */}
                                    </div>


                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div><br />
                    <div className="row"></div>
                </div>
            </div>
        );
    }
}
export default ManageDriverEarning