import React, { Component } from 'react';
import { render } from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router";
import axios from "axios/index";
// import axios from '../utilities/http';
import LINX from '../assests/main-logo.png';


var regExp = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

const setlocalStorage = (key, value) => {
    localStorage.setItem(key, value)
}


export default class AdminLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isError: {
                email: '',
                password: ''
            }
        };
    }

    async submit(e) {
        e.preventDefault();
        const { email, password, isError } = this.state

        email === "" ? isError.email = "Email Address is required" : ""
        password === "" ? isError.password = "Password is required" : ""
        email !== "" && password === ""
            ? isError.password = "Password is required"
            : email === "" && password !== "" ? isError.email = "Email Address is required" : ""

        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });

        try {
            let response = await axios.post(`./login`, this.state);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.err(err)
                });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        email: '',
                        password: ''
                    };
                }, () => {
                    if (response.data.token) {
                        toast.success("Login successfull.");
                        setlocalStorage("token", response.data.token)
                        setTimeout(() => {
                            document.location.href = '../admin';
                        }, 1000);
                    } else {
                        /* alert("hello") */
                        document.location.href = '/';
                    }
                });
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors) {
                e.response.data.errors.map((err, i) => {
                    if (err === "User not found" || err === "Incorrect password") {
                        toast.error(err)
                    }
                });
            } else {

            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }


    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        const { email, password } = this.state

        let isError = { ...this.state.isError };
        switch (name) {
            case "email":
                email.length === 1 ? isError.email = " " :
                    isError.email = regExp.test(value)
                        ? ""
                        : "Email address is invalid";
                break;
            case "password":
                password.length === 1 ? isError.password = " " :
                    isError.password =
                    value.length < 6 ? "Atleast 6 characters required" : "";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    };


    render() {
        const { isError } = this.state;
        return (
            <div className="form-signin">
                <img style={{ width: "150px", height: "52px", marginBottom: "49px", marginLeft: "63px" }} src={LINX} />
                <h2 className="form-signin-heading">Please Sign In </h2>
                <form onSubmit={(e) => { this.submit(e) }}>
                    <label className="sr-only">Email address</label>
                    <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email address"
                        required={true}
                        autoFocus
                        value={this.state.email}
                        onChange={event => {
                            event.persist();
                            this.setState(previousState => {
                                return {
                                    ...previousState,
                                    email: event.target.value
                                };
                            })

                            this.formValChange(event)
                        }}

                    />

                    {isError.email.length > 0 ? <p className='text-danger'>{isError.email}</p> : ""}
                    <label className="sr-only">Password</label>
                    <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        required={true}
                        value={this.state.password}
                        onChange={event => {
                            event.persist();
                            this.setState(previousState => {
                                return {
                                    ...previousState,
                                    password: event.target.value
                                };
                            })
                            this.formValChange(event)
                        }}
                    />
                    {isError.password.length > 0 && (
                        <p className='text-danger'>{isError.password}</p>
                    )}
                    <button className="btn btn-lg btn-primary btn-block" type='submit' disabled={isError.email.length > 0 ? true : false}>Sign in
                    </button>
                </form>
                {/* <button className="btn btn-lg btn-primary btn-block" onClick={() => {
                    this.submit();
                }}>Sign in
                </button> */}

                <a style={{ color: "#fff", marginTop: "15px", display: "block", textAlign: "right", fontFamily: "Gilroy-Regular", letterSpacing: "1px" }} href="./forgot-password">Forgot Password?</a>
                <ToastContainer />
            </div>
        );
    }
}