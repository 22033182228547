import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
// import { history } from '../../index';
import { ITEMS_PER_PAGE } from "../components/Constants";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

const Name = {
    wait_accept_delivery:"on the way" ,
    in_progress:"in progress",
    finished:"completed",
    wait_pick_up: "wait for pick up",
    new:"new",
    hold:"schedule",
    canceled:"cancelled"

}
export default class CustomerTrips extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            type: "user",
            loading: true,
            id: props.params.id,
            activePage: 1,
            total: 0,
            q: "",
            items: [],
            filter: {
                orderType: "1",
                start_date: new Date(),
                end_date: new Date(),
                start_date_str: moment().format("DD/MM/YYYY"),
                end_date_str: moment().format("DD/MM/YYYY")
            },
            breadcrumbObj:[
                {
                    title:'Customers',
                    breadCrumbLink:'./admin#/customers',
                    active:false
                },
                {
                    title:'Order History',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }


   

    componentDidMount() {
        // this._loadList();
        if (!!this.state.id && this.state.id !== "new") {
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            const  orderTypes =  this.state.filter.orderType
            let response = await axios.get(`./admin/user/${this.state.id}?orderType=${orderTypes}`);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                }); 
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    items: response.data.data.orders
                };
            });
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }


    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this_load()
        });
    }

    _renderRow(item, i) {
        // const abc = item.
        return (
            <tr key={i}>
                <td className={"text-center"}>{item.orderId}</td>
                <td className={"text-center"}>{item.owner_name}</td>
                <td className={"text-center"}>{item.owner.email}</td>
                <td className={"text-center"}>{item.status === "finished" ? "completed" : Name[item.status]}</td>
                <td className={"text-center"}>
                    <Link to={`/order/${item._id}`}><i className={"fa fa-eye"} /></Link>
                </td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
        );
    }

    render() { 
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>

                            {this._renderTitle()}

                           
                            <div className={"clearfix"}>
                                <div className="form-group" style={{marginTop:"5px"}}>

                            <select
                     style={{marginLeft:"15px"}}
                    // className={"form-control"}
                    value={this.state.filter.orderType}
                    onChange={event => {
                        event.persist();
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                filter:{
                                    ...previousState.filter,
                                    orderType: event.target.value
                                }
                            };
                        }, () => {
                            this._load()
                        })
                    }}
                >
                    <option value="1">Active</option>
                    <option value="2">Completed</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Unassigned</option>
                    <option value="">All</option>
                    
                </select>
                </div>
                            </div>
                   
                            <div className={"clearfix"}></div>
                        </div>
                        <div className={"x_content"}>
                            {this.state.items.length > 0 ? (
                            <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th className={"text-center"}>Order ID</th>
                                        <th className={"text-center"}>Full Name</th>
                                        <th className={"text-center"}>Email</th>
                                        <th className={"text-center"}>Status</th>
                                        <th className={"text-center"}>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.items.map((item, i) => {
                                        return this._renderRow(item, i);
                                    })}
                                </tbody>
                            </table>
                              ):
                              <h4 style={{marginLeft:"700px"}}>No Data Found</h4>
                            }    
                        </div>
                    </div>

                    {!this.state.loading && <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.round(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div>}

                </div>

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}
            </div>
        );
    }

}