import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class CustomerViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            n: !!props.params.n,
            user: {
                first_name: "",
                // last_name: "",
                email: "",
                pay_type: "",
                phone: "",
                status: "active",
                // role: "user",
                // type: "user",
                // new_password: "",
                new_balance: 0,
                new_courierLimit: 0,
                visible: false,
                in_progress: false,
                linxFee: 0,
                // avatar_url: "",
                linx_business_commission: {
                    fee_type: 'amount',
                    fee_value: 0
                },
            },
            breadcrumbObj:[
                {
                    title:'Customers',
                    breadCrumbLink:'./admin#/customers',
                    active:false
                },
                {
                    title:'Customer Details',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });

        if (!!this.state.id && this.state.id !== "new") {
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/user/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.data
                    }
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _preUpload(event) {
        if (this.fileInput.files.length === 0) {
            return;
        }
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        let formdata = new FormData();
        formdata.append('image', this.fileInput.files[0]);
        try {
            let response = await axios.post(`./admin/ajax/uploads_img`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                this.fileInput.value = '';
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        user: {
                            ...previousState.user,
                            avatar_url: response.data.data.image_url,
                            avatar: response.data.data.image
                        }
                    };
                });
            }
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            this.state.user.new_courierLimit = parseFloat(this.state.user.new_courierLimit);
            this.state.user.new_balance = parseFloat(this.state.user.new_balance);
            this.state.user.linx_business_commission.fee_value = parseInt(this.state.user.linx_business_commission.fee_value);
            let response = await axios.post(`./admin/user/`, this.state.user);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                if (this.state.id === "new") {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item created!");
                        history.push('/users/' + response.data.data._id);
                    });
                } else {
                    if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                        response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
                    }
                    response.data.data.visible = !!response.data.data.visible;
                    response.data.data.in_progress = !!response.data.data.in_progress;
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item updated!");
                    });
                }
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _resetFee() {
        if (confirm("Reset Any Delivery fee ?")) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: true
                };
            });
            try {
                let response = await axios.post(`./admin/user/reset_linx_fee/${this.state.id}`, {});
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            }
                        };
                    }, () => {
                        toast.success("LINX fee reset!");
                    });
                }
            } catch (e) {
                if (!!e.response.data.errors && e.response.data.errors.length > 0) {
                    e.response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    toast.error(e.message);
                }
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false
                    };
                });
            }
        }
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                            <div className='col-md-4'>
                                <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/>
                                {this.state.user._id && <b>Customer ID : {this.state.user.linxid} </b>}
                                    {/* {this.state.user._id && <small> (Payment default from: {this.state.user.pay_type})</small>} */}
                                    {!this.state.user._id && <h2 className='badge'>New Customer</h2>}
                                </h2> 


                                </div>
                               
                               
                                
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left"}>

                                    <div className={"row"}>

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Full Name</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.user.first_name}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        first_name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Email</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="email"
                                                        value={this.state.user.email}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        email: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Phone</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="phone"
                                                        value={this.state.user.phone}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        phone: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Status</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <select
                                                        disabled
                                                        className={"form-control"}
                                                        value={this.state.user.status}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        status: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    >
                                                        <option value="active">Active</option>
                                                        {/* <option>Inactive</option> */}
                                                        <option value="blocked">Inactive</option>
                                                    </select >
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                    <div className={"form-group"}>
                              
                                        <div className={"col-lg-6"}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                            {/* <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button> */}
                                        </div>
                                    </div>

                                </form >
                            </div >
                        </div >
                    </div >
                </div >

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}
