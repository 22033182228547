import React, { Component } from "react";
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import CustomBreadCrumbs from "../components/CustomBreadcrumbs";

export default class ManageSearchRadius extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            items:[],
            breadcrumbObj:[
                {
                    title:'Manage Search Radius',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        }
    }

    componentDidMount() {
        this._loadList();
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`./admin/admin_settings`);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            this.setState({
                loading: false,
                items: response.data.data
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }


    _renderRow(item, i) {
        return (
            <tbody key={i}>
            <tr>
                <td className={"text-center"}>{item.miles}</td>
                <td className={"text-center"}>{item.admin_assign_miles}</td>
                <td className={"text-center"}>
                    <Link to={`/editradius/${item.miles}/${item.admin_assign_miles}/${item._id}`}><i className={"fa fa-pencil"} /></Link>
                </td>
            </tr>
            </tbody>
        );
    }

    _renderTitle() {
        return (
            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>
        );
    }

    render() {
        return (
            <div className="row">
                  <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>
                            {this._renderTitle()}
                        </div>
                        <div className={"x_content"}>
                        <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th className={"text-center"}>Application Order Search Radius Miles</th>
                                            <th className={"text-center"}>Admin Order Search Radius Miles</th>
                                            <th className={"text-center"}>Action</th>
                                        </tr>
                                    </thead>
                                        {this.state.items.length > 0 ? 
                                        this.state.items.map((item, i) => {
                                            return this._renderRow(item, i)
                                        }) : <h4 style={{marginLeft:"700px"}}>No Data Found</h4>}
                                </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}