const initialState = {
    connect: false,
    token: null
};

export default function socket(state = initialState, action) {
    switch (action.type) {
        case 'SOCKET_CONNECT':
            return {
                ...state,
                connect: action.payload.connect
            };
        case 'SOCKET_TOKEN':
            return {
                ...state,
                token: action.payload.token
            };
        default:
            return state;
    }
}