
export default function GetSocketUrl(url) {
    if(url.includes('qa')){
        return 'http://dev2.spaceo.in:8009';
    }

    if(url.includes('package_delivery_web')){
        return 'http://dev2.spaceo.in:9001';
    }

    if(url.includes('staging.linxpackage.com')){
        return 'https://staging.linxpackage.com';
    }

    if(url.includes('linxpackage.com')){
        return 'https://linxpackage.com';
    }

    return 'http://localhost:3030';
} 