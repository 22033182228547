import axios from "axios";
import React, { Component } from "react";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { ITEMS_PER_PAGE } from "../components/Constants";
import CustomBreadCrumbs from "../components/CustomBreadcrumbs";

export default class DriverCancelOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            q: "",
            items: [],
            breadcrumbObj: [
                {
                    title: 'Drivers',
                    breadCrumbLink: '#/drivers',
                    active: false
                },
                {
                    title: 'Cancel Order',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        };
    }

    componentWillMount() {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    componentDidMount() {
        this._loadList();
    }



    async _loadList() {
        this.setState({
            loading: true
        });
        let param = {
            q: this.state.q,
        }

        try {
            let response = await axios.get(`./admin/cancel_order/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                params: param
            });

            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.orders,
                total: response.data.data.count
            });
        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td>{item.orderId ? item.orderId.orderId : '-'}</td>
                <td>{item.driverId ? item.driverId.email : '-'}</td>
                <td>{item.driver_name ? item.driver_name : '-'}</td>
                <td>{item.reason ? item.reason : '-'}</td>
                <td>{item.description ? item.description : '-'}</td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
        );
    }

    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>

                            {this._renderTitle()}
                            <div className={"clearfix"}></div>
                            <div className={"title_right"}>
                                <div className={"col-md-9 col-sm-12 col-xs-12 form-group pull-left top_search"} style={{ margin: "0" }}>
                                    <div className={"input-group"} style={{ margin: "0" }}>
                                        <input
                                            type="text"
                                            className={"form-control"}
                                            placeholder="Search by name..."
                                            value={this.state.q}
                                            onChange={event => {
                                                this.setState({
                                                    activePage: 1,
                                                    q: event.target.value
                                                }, () => {
                                                    this._loadList()
                                                });
                                            }}
                                        />
                                        <span className={"input-group-btn"}>
                                            <button className={"btn btn-default"} type="button" onClick={() => {
                                                this.setState({
                                                    activePage: 1,
                                                    q: ""
                                                }, () => {
                                                    this._loadList()
                                                });
                                            }}>Cancel</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={"clearfix"}></div>
                        </div>

                        {this.state.items.length > 0 ? (
                            <div className={"x_content"}>
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap table_scroll order_table"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>Email</th>
                                            <th>Driver Name</th>
                                            <th>Reason</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.items.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}
                                    </tbody>
                                </table>


                            </div>
                        ) : <h4 style={{ marginLeft: "700px" }}>No Data Found</h4>}
                    </div>

                    {!this.state.loading && <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.ceil(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div>}

                </div>
            </div>
        );
    }
}