import React, { Component } from "react";
import { RingLoader } from "react-spinners";

export default class RingLoaderComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <RingLoader
                    style={{ margin: 'auto' }}
                    color={'#00e0b0'}
                    loading={this.props.loading}
                />
            </div>
        )
    }
}