import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateData } from "../../actions/statisticActions";
import Users from '../../assests/user.png';
import Drivers from '../../assests/driver.png';
import Booking from '../../assests/booking.png';
import Earning from '../../assests/profit.png';



class TotalStatInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            statistic: this.props.statistic
        };
    }

    componentDidMount() {
        this.props.loadDate();
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(newProps) {

        if (newProps.statistic && newProps.statistic) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    statistic: newProps.statistic
                };
            });
        }
    }

    render() {
        return (
            <div className={"row tile_count"}>
                <div className={"col-md-3 col-sm-4 col-xs-6 tile_stats_count"}>
                    <span className={"count_top"}><img src={Users} />Total Users</span>
                    <div className={"count"}>{this.state.statistic.total_users}</div>
                    {/* <span className={"count_bottom"}>
                    { this.state.statistic.percent_total_users > 0 && <i className={"green"}><i className={"fa fa-sort-asc"}/>{this.state.statistic.percent_total_users.toFixed(2)}% </i> }
                    { this.state.statistic.percent_total_users < 0 && <i className={"red"}><i className={"fa fa-sort-desc"}/>{this.state.statistic.percent_total_users.toFixed(2)}% </i> }
                    { this.state.statistic.percent_total_users === 0 && <i>{this.state.statistic.percent_total_users}% </i> }
                    From last Week</span> */}
                </div>
                <div className={"col-md-3 col-sm-4 col-xs-6 tile_stats_count"}>
                    <span className={"count_top"}><img src={Drivers} />Total Drivers</span>
                    <div className={"count"}>{this.state.statistic.total_drivers}</div>
                    {/* <span className={"count_bottom"}>
                    { this.state.statistic.percent_total_couriers > 0 && <i className={"green"}><i className={"fa fa-sort-asc"}/>{this.state.statistic.percent_total_couriers.toFixed(2)}% </i> }
                    { this.state.statistic.percent_total_couriers < 0 && <i className={"red"}><i className={"fa fa-sort-desc"}/>{this.state.statistic.percent_total_couriers.toFixed(2)}% </i> }
                    { this.state.statistic.percent_total_couriers === 0 && <i>{this.state.statistic.percent_total_couriers}% </i> }
                    From last Week</span> */}
                </div>
                <div className={"col-md-3 col-sm-4 col-xs-6 tile_stats_count"}>
                    <span className={"count_top"}><img src={Booking} />Total Completed Bookings</span>
                    <div className={"count"}>{this.state.statistic.total_orders}</div>
                    {/* <span className={"count_bottom"}>
                    { this.state.statistic.percent_total_orders > 0 && <i className={"green"}><i className={"fa fa-sort-asc"}/>{this.state.statistic.percent_total_orders.toFixed(2)}% </i> }
                    { this.state.statistic.percent_total_orders < 0 && <i className={"red"}><i className={"fa fa-sort-desc"}/>{this.state.statistic.percent_total_orders.toFixed(2)}% </i> }
                    { this.state.statistic.percent_total_orders === 0 && <i>{this.state.statistic.percent_total_orders}% </i> }
                    From last Week</span> */}
                </div>
                <div className={"col-md-3 col-sm-4 col-xs-6 tile_stats_count"}>
                    <span className={"count_top"}><img src={Earning} />Total Earnings</span>
                    <div className={"count"}>$ {this.state.statistic.linx_earning.toFixed(2)}</div>
                    {/* <span className={"count_bottom"}>
                    { this.state.statistic.percent_linx_earning > 0 && <i className={"green"}><i className={"fa fa-sort-asc"}/>{this.state.statistic.percent_linx_earning.toFixed(2)}% </i> }
                    { this.state.statistic.percent_linx_earning < 0 && <i className={"red"}><i className={"fa fa-sort-desc"}/>{this.state.statistic.percent_linx_earning.toFixed(2)}% </i> }
                    { this.state.statistic.percent_linx_earning === 0 && <i>{this.state.statistic.percent_linx_earning}% </i> }
                    From last Week</span> */}
                </div>
                {/* <div className={"col-md-2 col-sm-4 col-xs-6 tile_stats_count"}>
                <span className={"count_top"}><i className={"fa fa-user"}/> Courier's Earning</span>
                <div className={"count"}>{this.state.statistic.couriers_earning.toFixed(2)}</div>
                <span className={"count_bottom"}>
                    { this.state.statistic.percent_couriers_earning > 0 && <i className={"green"}><i className={"fa fa-sort-asc"}/>{this.state.statistic.percent_couriers_earning.toFixed(2)}% </i> }
                    { this.state.statistic.percent_couriers_earning < 0 && <i className={"red"}><i className={"fa fa-sort-desc"}/>{this.state.statistic.percent_couriers_earning.toFixed(2)}% </i> }
                    { this.state.statistic.percent_couriers_earning === 0 && <i>{this.state.statistic.percent_couriers_earning}% </i> }
                    From last Week</span>
            </div> */}
                {/* <div className={"col-md-2 col-sm-4 col-xs-6 tile_stats_count"}>
                <span className={"count_top"}><i className={"fa fa-user"}/> User's Debt</span>
                <div className={"count"}>{this.state.statistic.users_debt.toFixed(2)}</div>
                <span className={"count_bottom"}>
                    { this.state.statistic.percent_users_debt > 0 && <i className={"green"}><i className={"fa fa-sort-asc"}/>{this.state.statistic.percent_users_debt.toFixed(2)}% </i> }
                    { this.state.statistic.percent_users_debt < 0 && <i className={"red"}><i className={"fa fa-sort-desc"}/>{this.state.statistic.percent_users_debt.toFixed(2)}% </i> }
                    { this.state.statistic.percent_users_debt === 0 && <i>{this.state.statistic.percent_users_debt}% </i> }
                    From last Week</span>
            </div> */}
            </div>
        );
    }

}

export default connect(state => ({
    statistic: state.statistic
}), dispatch => ({
    loadDate: () => {
        dispatch(updateData())
    }
}))(TotalStatInfo);