import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import notifications from "./notifications";
import socket from "./socket";
import statistic from "./statistic";
import duePayment from "./duePayment";

export default combineReducers({
    routing: routerReducer,
    notifications: notifications,
    socket: socket,
    statistic: statistic,
    duePayment: duePayment
});