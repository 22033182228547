const initialState = {
    duePaymentInfo: []
};

export default function duePaymentInfo(state = initialState, action) {
    switch (action.type) {

        case 'DUE_PAYMENT':
        return Object.assign({}, ...state, {
            duePaymentInfo: action.duePaymentInfo
          })
        default:
            return state;
    }
}