export function addNotification(notification) {
    return {
        type: 'ADD_NOTIFICATION',
        payload: {
            notification: notification
        }
    };
}

export function removeNotifications(key) {
    return {
        type: 'REMOVE_NOTIFICATION',
        payload: {
            key: key
        }
    };
}
