import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { RingLoader } from 'react-spinners';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import theme from 'react-images/lib/theme';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';

export default class ManufacturerFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            imageupload: {},
            isError: false,
            isNotMulti: false,
            manufacture: {
                name: '',
                multiplier: 1,
                measurement:'inch',
                types: [],
                status:"inactive"
                // avatar_url:'',
            },
            start_size: {
                l: 0,
                b: 0,
                h: 0
            },
            end_size: {
                l: 0,
                b: 0,
                h: 0
            },
            breadcrumbObj:[
                {
                    title:'Manage Vehicles',
                    breadCrumbLink:'./admin#/manufacturers',
                    active:false
                },
                {
                    title: props.params.id === 'new' ?  'Add Vehicle Type' :'Edit Vehicle Type',
                    breadCrumbLink:'',
                    active:true
                }
            ]
            
            
        };
    }

    componentDidMount() {
        if (!!this.state.id && this.state.id !== "new") {
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _preUpload(event) {
        if (this.fileInput.files.length === 0) {
            return;
        }
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        let formdata = new FormData();
        formdata.append('image', this.fileInput.files[0]);
        try {
            let response = await axios.post(`./admin/ajax/uploads_img`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                this.fileInput.value = '';
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        manufacture: {
                            ...previousState.manufacture,
                            // icon_url: response.data.data.image_url,
                            // types: response.data.data.icon
                            avatar_url: response.data.data.image_url,
                            avatar: response.data.data.image
                        }
                    };
                });
            }
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/manufacture/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    manufacture: response.data.data,
                    start_size: response.data.data.start_size,
                    end_size: response.data.data.end_size,
                    status: response.data.data.status
                };
            });
            
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let message = "";
            this.state.start_size = {
                l: Math.round(this.state.start_size.l),
                b: Math.round(this.state.start_size.b),
                h: Math.round(this.state.start_size.h),
            };
            this.state.end_size = {
                l: Math.round(this.state.end_size.l),
                b: Math.round(this.state.end_size.b),
                h: Math.round(this.state.end_size.h),
            }
            this.state.manufacture['start_size'] = this.state.start_size
            this.state.manufacture['end_size'] = this.state.end_size
            // this.state.manufacture['status'] = this.state.status
            // this.state.manufacture.status =  this.state.manufacture.status == "true"?true:false
            this.state.manufacture.types.length > 0 && this.state.manufacture.types.map((data, index) => {
                if(!data.name && !data.ppm && !data.base_charge && !data.hourly_charge){
                    this.state.isError = true;
                    message = "Please enter vehicle model details."
                } else if (data.base_charge < 0.5) {
                    this.state.isError = true;
                    message = "Base Charge must be greater then 0.5"
                } else if(data.ppm < 0.5) {
                    this.state.isError = true;
                    message = "Price/Mile must be greater then 0.5"
                }
            })
            if((!this.state.manufacture.multiplier) || (this.state.manufacture.multiplier > 1000 || this.state.manufacture.multiplier <= 0)){
                this.state.isNotMulti = true;
            }
            if(this.state.isNotMulti == true){
                toast.error("Please enter multiplier value must be between 1 to 1000")
            }
            
            if(this.state.isError == true) {
                toast.error(message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    isError: false,
                    isNotMulti:false,
                };
            });
            if(this.state.isError === false && this.state.isNotMulti == false){
            let response = await axios.post(`./admin/manufacture`, this.state.manufacture);

            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                if (this.state.id === "new") {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            manufacture: response.data.data,
                            // end_size: response.data.data.end_size,
                            // id: response.data.data._id,
                            // loading: false
                        };
                    }, () => {
                        toast.success("Item created!");
                        history.push('#/manufacturers/');
                    });
                } else {
                    this.setState(previousState => {

                        return {
                            ...previousState,
                            loading: false,
                            manufacture: response.data.data,
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item updated!");
                        history.push('/manufacturers');
                    });
                }
            }
        }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    _addType() {
        this.state.manufacture.types.map((v, k, a) => {
            this.state.manufacture.types[k].editable = false;
        });
        this.state.manufacture.types.push({
            name: '',
            ppm: '',
            base_charge: '',
            hourly_charge: '',
            // icon: '',
            // size: 1,
            // editable: true
        });
        this.setState(previousState => {
            return {
                ...previousState,
                manufacture: {
                    ...previousState.manufacture,
                    types: this.state.manufacture.types
                }
            };
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td>
                    {!item.editable && item.name}
                    {item.editable &&
                        <input
                            type="text"
                            required="required"
                            className={"form-control col-md-7 col-xs-12"}
                            value={this.state.manufacture.types[i].name}
                            onChange={event => {
                                event.persist();
                                this.state.manufacture.types[i].name = event.target.value;
                                this.setState(previousState => {
                                    return {
                                        ...previousState,
                                        manufacture: {
                                            ...previousState.manufacture,
                                            types: this.state.manufacture.types
                                        }
                                    };
                                })
                            }}
                        />}
                </td>
                <td>
                    {!item.editable && item.ppm}
                    {item.editable &&
                        <input
                            type="number"
                            required="required"
                            className={"form-control col-md-7 col-xs-12"}
                            value={this.state.manufacture.types[i].ppm}
                            onChange={event => {
                                event.persist();
                                this.state.manufacture.types[i].ppm = event.target.value;
                                this.setState(previousState => {
                                    return {
                                        ...previousState,
                                        manufacture: {
                                            ...previousState.manufacture,
                                            types: this.state.manufacture.types
                                        }
                                    };
                                })
                            }}
                        />}
                </td>
                <td>
                    {!item.editable && item.base_charge}
                    {item.editable && <input
                        type="number"
                        required="required"
                        className={"form-control col-md-7 col-xs-12"}
                        value={this.state.manufacture.types[i].base_charge}
                        onChange={event => {
                            event.persist();
                            this.state.manufacture.types[i].base_charge = event.target.value;
                            this.setState(previousState => {
                                return {
                                    ...previousState,
                                    manufacture: {
                                        ...previousState.manufacture,
                                        types: this.state.manufacture.types
                                    }
                                };
                            })
                        }}
                    />}
                </td>
                {/* <td>
                    {!item.editable && item.hourly_charge}
                    {item.editable && <input
                        type="number"
                        required="required"
                        className={"form-control col-md-7 col-xs-12"}
                        value={this.state.manufacture.types[i].hourly_charge}
                        onChange={event => {
                            event.persist();
                            this.state.manufacture.types[i].hourly_charge = event.target.value;
                            this.setState(previousState => {
                                return {
                                    ...previousState,
                                    manufacture: {
                                        ...previousState.manufacture,
                                        types: this.state.manufacture.types
                                    }
                                };
                            })
                        }}
                    />}
                </td> */}
                {/* <td>
                    <input
                        type="file"
                        required="required"
                        name="icon"
                        className={"form-control col-md-7 col-xs-12"}
                        ref={input => {
                            this.fileInput = input;
                        }}
                        value={this.state.manufacture.types[i].icon}
                        // onChange={event => {
                        //     this._preUpload.bind(this)
                        //     event.persist();
                        //     this.state.manufacture.types[i].icon = event.target.value;
                        //     this.setState(previousState => {
                        //         return {
                        //             ...previousState,
                        //             manufacture: {
                        //                 ...previousState.manufacture,
                        //                 types: this.state.manufacture.types
                        //             }
                        //         };
                        //         // this._preUpload.bind(this)
                        //     })}}
                        onChange={this._preUpload.bind(this)}

                    />
                </td> */}
                <td className={"text-center"} style={{ width: 120 }}>
                    <Button bsStyle="link" onClick={() => {
                        this.state.manufacture.types[i].editable = !this.state.manufacture.types[i].editable;
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                manufacture: {
                                    ...previousState.manufacture,
                                    types: this.state.manufacture.types
                                }
                            };
                        });
                    }} >
                        {!item.editable && <i className={"fa fa-pencil"} />}
                        {item.editable && <i className={"fa fa-check"} />}
                    </Button>
                    <Button bsStyle="link" onClick={() => {
                        this.state.manufacture.types.splice(i, 1);
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                manufacture: {
                                    ...previousState.manufacture,
                                    types: this.state.manufacture.types
                                }
                            };
                        });
                    }} ><i className={"fa fa-trash"} /></Button>
                </td>
            </tr>
        );
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel edit-vehicle"}>
                            <div className={"x_title"}>
                                {this.state.id !== 'new' && <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>}
                                {this.state.id === 'new' && <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>}
                                <div className={"clearfix"}>
                                <div className="form-group" style={{marginTop:"5px"}}>

                                {/* <select
                                    style={{marginLeft:"15px"}}
                                    // className={"form-control"}
                                   value={this.state.manufacture.status}
                                   onChange={event => {
                                   event.persist();
                                   this.setState(previousState => {
                                  return {  
                                    ...previousState, 
                                    manufacture:{
                                        ...previousState.manufacture,
                                        status: event.target.value,
                                    }
                                   };
                                      }, )
                                                  }}
                                        >
                                     <option value="active">Active</option>
                                     <option value="inactive">Inactive</option>
                                  </select> */}
                                  </div>
                                </div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left"}>

                                    <div className={"row"}>

                                        <div className={"col-md-6 col-sm-6 col-xs-6 vehicle-type"}>
                                            <h3>Vehicle Type</h3>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12 no-padding"}>Name
                                                </label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="text"
                                                        required="required"
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.manufacture.name}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    manufacture: {
                                                                        ...previousState.manufacture,
                                                                        name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12 no-padding"}>Inch/Feet:
                                                </label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <div className='col-md-4' style={{marginTop:"18px", marginLeft:'30px'}}>
                                                        <label className={"control-label col-md-3 col-sm-3 col-xs-12 no-padding"} htmlFor="Inch">Inch
                                                        </label>
                                                    </div>
                                                <div className='col-md-4'>
                                                    <input
                                                        type="radio"
                                                        id="inch"
                                                        name="measurement"
                                                        className={""}
                                                        value='inch'
                                                        checked={this.state.manufacture.measurement == 'inch'}
                                                        onChange={event => {
                                                            event.persist()
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    manufacture:{
                                                                        ...previousState.manufacture,
                                                                        measurement: "inch"
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                </div>
                                               
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <div className='col-md-4' style={{marginTop:"18px"}}>
                                                        <label className={"control-label col-md-3 col-sm-3 col-xs-12 no-padding"} htmlFor="Feet">Feet
                                                        </label>
                                                    </div>
                                                        <div className='col-md-4'>
                                                            <input
                                                                type="radio"
                                                                name="measurement"
                                                                id="feet"
                                                                className={""}
                                                                value="feet"
                                                                checked={this.state.manufacture.measurement == "feet"}
                                                                onChange={event => {
                                                                    event.persist()
                                                                    this.setState(previousState => {
                                                                        return {
                                                                            ...previousState,
                                                                            manufacture:{
                                                                                ...previousState.manufacture,
                                                                                measurement: "feet"
                                                                            }
                                                                        };
                                                                    })
                                                                }}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Start Size(l*b*h):
                                                </label>
                                                <div className={"col-3 col-3 col-4"}>
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"col-md-3 col-xs-12"}
                                                        value={this.state.start_size.l}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    start_size: {
                                                                        ...previousState.start_size,
                                                                        l: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                    &nbsp;
                                                    -
                                                    &nbsp;
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"col-md-3 col-xs-12"}
                                                        value={this.state.start_size.b}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    start_size: {
                                                                        ...previousState.start_size,
                                                                        b: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                    &nbsp;
                                                    -
                                                    &nbsp;
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"col-md-3 col-xs-12"}
                                                        value={this.state.start_size.h}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    start_size: {
                                                                        ...previousState.start_size,
                                                                        h: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>End Size(l*b*h):
                                                </label>
                                                <div className={"col-3 col-3 col-4"}>
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"col-md-3 col-xs-12"}
                                                        value={this.state.end_size.l}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    end_size: {
                                                                        ...previousState.end_size,
                                                                        l: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                    &nbsp;
                                                    -
                                                    &nbsp;
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"col-md-3 col-xs-12"}
                                                        value={this.state.end_size.b}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    end_size: {
                                                                        ...previousState.end_size,
                                                                        b: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                    &nbsp;
                                                    -
                                                    &nbsp;
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"col-md-3 col-xs-12"}
                                                        value={this.state.end_size.h}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    end_size: {
                                                                        ...previousState.end_size,
                                                                        h: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12 no-padding"}>Multiplier
                                                </label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="number"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.manufacture.multiplier}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    manufacture: {
                                                                        ...previousState.manufacture,
                                                                        multiplier: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            <h3>Vehicle Models</h3>
                                            <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Price/Mile</th>
                                                        <th>Base Charge</th>
                                                        {/* <th>Hourly Charge</th> */}
                                                        {/* <th>Image</th> */}
                                                        <th style={{ width: 120 }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.manufacture.types.map((item, i) => {
                                                        return this._renderRow(item, i);
                                                    })}
                                                </tbody>
                                            </table>
                                            <div className="col-lg-12" style={{textAlign:"right",marginTop:'10px'}}>
                                            <Button onClick={this._addType.bind(this)} bsStyle="success">Add type</Button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"ln_solid"}></div>
                                    <div className={"form-group"}>
                                    <div className={"col-lg-6"}>

                                    </div>
                                        <div className={"col-lg-6"} style={{textAlign:"right"}}>
                                            <a className={"btn btn-primary"} href={"#/manufacturers/"}>Cancel</a>
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                        </div>
                                      
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
{/* 
                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoader
                        style={{ margin: 'auto' }}
                        color={'#0019bc'}
                        loading={this.state.loading}
                    />
                </div>} */}
            </div>
        );
    }

}