import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { history } from '../index';

export default class NotificationCreate extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            type: 0,
            customer: 0,
            driver: 0,
            notification: {
                title: "",
                text: "",
            }
        }
    }

    async _save(e) {
        try {
            if (this.state.customer == 1 && this.state.driver == 2) {
                this.state.type = 3
            } else if (this.state.customer == 1) {
                this.state.type = this.state.customer
            } else if (this.state.driver == 2) {
                this.state.type = this.state.driver
            }
            if ((this.state.notification.text != null && this.state.notification.text.trim() !== '') && (this.state.notification.title != null && this.state.notification.title.trim() !== '') && this.state.type !== 0) {
                let response = await axios.post(`./admin/send_notification?type=${this.state.type}`, this.state.notification);
                if (response.status === 200) {
                    history.push("/notificationList")
                    toast.success("Notification created successfully!")
                } else {
                    toast.error("Error");
                }
            } else {
                toast.error("Please enter all fields!!");
            }
        } catch (error) {
            toast.error("Error")
        }

    }


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="x_panel notifaction-create">
                            <div className="x_title">
                                <h2>Create Custom Notifaction</h2>
                            </div>
                            <div className="x_content"><br />
                                <form data-parsley-validate="true" className="form-horizontal form-label-left">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-6">
                                            <div className="form-group"><label className="control-label col-md-3 col-sm-5 col-xs-12">Title</label>
                                                <div className="col-md-9 col-sm-6 col-xs-12">
                                                    <input type="text" maxLength={65} placeholder="Enter Title" className="form-control col-md-7 col-xs-12" value={this.state.notification.title}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    notification: {
                                                                        ...previousState.notification,
                                                                        title: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="form-group"><label className="control-label col-md-3 col-sm-5 col-xs-12">Description</label>
                                                <div className="col-md-9 col-sm-6 col-xs-12">
                                                    <textarea placeholder="Enter Description" maxLength={200} value={this.state.notification.text}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    notification: {
                                                                        ...previousState.notification,
                                                                        text: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-md-3 col-sm-5 col-xs-12">Send to</label>
                                                <div className="col-md-9 col-sm-6 col-xs-12">
                                                    <div className="checkbox-wrapper">
                                                        <div className="checkbox-group">
                                                            <div className="custom-checkbox">
                                                                <input type="checkbox" id="driver" value={2}
                                                                    onChange={event => {

                                                                        event.persist();
                                                                        this.setState(previousState => {
                                                                            return {
                                                                                ...previousState,
                                                                                driver: event.target.checked ? 2 : 0
                                                                            };
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="driver">Driver</label>
                                                            </div>
                                                            {/* <b>Or</b>
                                                                    <div className="form-group">
                                                                        <input type="text" placeholder="Search driver zone/location" className="form-control col-md-7 col-xs-12" value="" />
                                                                    </div> */}
                                                        </div>
                                                        <div className="checkbox-group ">
                                                            <div className="custom-checkbox">
                                                                <input type="checkbox" id="customer" value={1}
                                                                    onChange={event => {

                                                                        event.persist();
                                                                        this.setState(previousState => {
                                                                            return {
                                                                                ...previousState,
                                                                                customer: event.target.checked ? 1 : 0
                                                                            };
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="customer">Customer</label>
                                                            </div>
                                                            {/* <b>Or</b>
                                                                    <div className="form-group">
                                                                        <input type="text" placeholder="Search Customer zone/location" className="form-control col-md-7 col-xs-12" value="" />
                                                                    </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ln_solid"></div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-lg-6" style={{ textAlign: "right", paddingRight: "0px" }}>
                                            <a className="btn btn-primary" href="#/notificationList">Cancel</a>
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Send</Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};