const initialState = {
    total_users: 0,
    total_couriers: 0,
    total_orders: 0,
    linx_earning: 0,
    couriers_earning: 0,
    users_debt: 0,

    percent_total_users: 0,
    percent_total_couriers: 0,
    percent_total_orders: 0,
    percent_linx_earning: 0,
    percent_couriers_earning: 0,
    percent_users_debt: 0
};

export default function statistic(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_STATISTIC_DATE':
            return {
                ...action.payload.data
            };
        default:
            return state;
    }
}