import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import countryCodeList from '../country-code-list.json';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class UserFormPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            n: !!props.params.n,
            user: {
                first_name: "",
                // last_name: "",
                email: "",
                pay_type: "",
                phone: "",
                full_number: " ",
                country_isd_code: " ",
                status: "active",
                // role: "user",
                // type: "user",
                // new_password: "",
                new_balance: 0,
                new_courierLimit: 0,
                visible: false,
                in_progress: false,
                linxFee: 0,
                // avatar_url: "",
                linx_business_commission: {
                    fee_type: 'amount',
                    fee_value: 0
                },
            },
            breadcrumbObj: [
                {
                    title: 'Customers',
                    breadCrumbLink: './admin#/customers',
                    active: false
                },
                {
                    title: 'Edit Customer',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });

        if (!!this.state.id && this.state.id !== "new") {
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/user/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.data
                    }
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save(_t) {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            this.state.user.new_courierLimit = parseFloat(this.state.user.new_courierLimit);
            this.state.user.new_balance = parseFloat(this.state.user.new_balance);
            this.state.user.linx_business_commission.fee_value = parseInt(this.state.user.linx_business_commission.fee_value);
            this.state.user.country_isd_code = this.state.user.country_isd_code;
            let response = await axios.post(`./admin/user/`, this.state.user);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                if (this.state.id === "new") {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item created!");
                        history.push('/users/' + response.data.data._id);
                    });
                } else {
                    if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                        response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
                    }
                    response.data.data.visible = !!response.data.data.visible;
                    response.data.data.in_progress = !!response.data.data.in_progress;
                    response.data.data.country_isd_code = this.state.user.country_isd_code;
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            // country_isd_code:this.state.user.country_isd_code,
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Customer updated successfully");
                        history.push("/customers")
                    });
                }
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _resetFee() {
        if (confirm("Reset Any Delivery fee ?")) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: true
                };
            });
            try {
                let response = await axios.post(`./admin/user/reset_linx_fee/${this.state.id}`, {});
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            }
                        };
                    }, () => {
                        toast.success("LINX fee reset!");
                    });
                }
            } catch (e) {
                if (!!e.response.data.errors && e.response.data.errors.length > 0) {
                    e.response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    toast.error(e.message);
                }
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false
                    };
                });
            }
        }
    }

    handleCountryCodeDrowdownChange(_e) {
        const { user } = this.state;
        user.country_isd_code = _e.target.value;
        this.setState({ user })

    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                <div className='col-md-4'>
                                    <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} />
                                        {this.state.user._id && <b>Customer ID : {this.state.user.linxid} </b>}
                                        {/* {this.state.user._id && <small> (Payment default from: {this.state.user.pay_type})</small>} */}
                                        {!this.state.user._id && <h2 className='badge'>New Customer</h2>}
                                    </h2>
                                </div>
                                <div className='col-md-4' style={{ marginTop: "5px", marginLeft: "-95px" }}>


                                </div>
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left"}>

                                    <div className={"row"}>

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Full Name</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.user.first_name}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        first_name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Email</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="email"
                                                        value={this.state.user.email}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        email: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Country Code</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <select
                                                        value={this.state.user.country_isd_code}
                                                        onChange={(e) => { this.handleCountryCodeDrowdownChange(e) }}
                                                        name="country_isd_code"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                    >

                                                        {countryCodeList.map((item, index) => {
                                                            return <option key={index} value={item.country_isd_code} >{item.country_isd_code}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Phone</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="phone"
                                                        value={this.state.user.phone}
                                                        maxLength={13}
                                                        minLength={8}
                                                        onChange={event => {
                                                            event.persist();
                                                            const re = /^[0-9\b]+$/;
                                                            if (event.target.value === '' || re.test(event.target.value)) {
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            phone: event.target.value
                                                                        }
                                                                    };
                                                                })
                                                            }

                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Role <span className={"required"}>*</span></label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <select
                                                        className={"form-control"}
                                                        value={this.state.user.role}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        role: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    >
                                                        <option>user</option>
                                                        <option>admin</option>
                                                    </select>
                                                </div>
                                            </div> */}

                                            {/* <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Type <span className={"required"}>*</span></label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <select
                                                        className={"form-control"}
                                                        value={this.state.user.type}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        type: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    >
                                                        <option>user</option>
                                                        <option>business</option>
                                                    </select>
                                                </div>
                                            </div> */}

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Status</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <select
                                                        className={"form-control"}
                                                        value={this.state.user.status}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        status: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    >
                                                        <option value="active">Active</option>
                                                        <option value="blocked">Inactive</option>
                                                    </select >
                                                </div >
                                            </div >

                                            {/* <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>New password
                                                </label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"date-picker form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        value={this.state.user.new_password}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        new_password: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                        </div >

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            {/* 
                                            <div className={"form-group"}>
                                                <div className={"text-center"}>
                                                    {this.state.user.avatar !== "" && <img className={"img-circle profile_img"} style={{ maxHeight: 100, width: "auto", margin: 20 }} src={this.state.user.avatar_url} />}
                                                    {this.state.user.avatar === "" && <img className={"img-circle profile_img"} style={{ maxHeight: 100, width: "auto", margin: 20 }} src="/images/empty.png" />}
                                                </div>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Photo</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="file"
                                                        name="avatar"
                                                        ref={input => {
                                                            this.fileInput = input;
                                                        }}
                                                        onChange={this._preUpload.bind(this)}
                                                    />
                                                </div>
                                            </div> */}

                                            {/* <div className={"ln_solid"}></div> */}
                                            {/* <div className={"form-group"}>
                                                <div className={"row"}>
                                                    <div className={"col-lg-6"}>
                                                        <div>Balance: {this.state.user.balance}</div>
                                                        <div className={"form-group"}>
                                                            <label className={"control-label col-lg-6"}>Add balance (SAR)</label>
                                                            <div className={"col-lg-6"}>
                                                                <input
                                                                    className={"form-control col-md-7 col-xs-12"}
                                                                    type="text"
                                                                    name="avatar"
                                                                    value={this.state.user.new_balance}
                                                                    onChange={event => {
                                                                        event.persist();
                                                                        this.setState(previousState => {
                                                                            return {
                                                                                ...previousState,
                                                                                user: {
                                                                                    ...previousState.user,
                                                                                    new_balance: event.target.value
                                                                                }
                                                                            };
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.user.type === 'user' && <div className={"col-lg-6"}>
                                                        <div>Courier limit: {this.state.user.courierLimit}</div>
                                                        <div className={"form-group"}>
                                                            <label className={"control-label col-lg-6"}>Add courier limit (SAR)</label>
                                                            <div className={"col-lg-6"}>
                                                                <input
                                                                    className={"form-control col-md-7 col-xs-12"}
                                                                    type="text"
                                                                    name="avatar"
                                                                    value={this.state.user.new_courierLimit}
                                                                    onChange={event => {
                                                                        event.persist();
                                                                        this.setState(previousState => {
                                                                            return {
                                                                                ...previousState,
                                                                                user: {
                                                                                    ...previousState.user,
                                                                                    new_courierLimit: event.target.value
                                                                                }
                                                                            };
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div> */}
                                            {/* <div className={"ln_solid"}></div> */}
                                            {/* {this.state.user.type === 'business' && <div>
                                                <div className={"form-group"}>
                                                    <label className={"control-label col-lg-6"}>Any Delivery commission fee Type: </label>
                                                    <div className={"col-lg-6"}>
                                                        <select
                                                            className={"form-control"}
                                                            value={this.state.user.linx_business_commission.fee_type}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            linx_business_commission: {
                                                                                fee_type: event.target.value,
                                                                                fee_value: 0
                                                                            }
                                                                        }
                                                                    };
                                                                })
                                                            }}
                                                        >
                                                            <option>amount</option>
                                                            <option>percentage</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                            {/* <div className={"form-group"}>
                                                    <label className={"control-label col-lg-6"}>Any Delivery commission fee: </label>
                                                    <div className={"col-lg-6"}>
                                                        {this.state.user.linx_business_commission.fee_type === "amount" ? <input
                                                            className={"form-control col-md-7 col-xs-12"}
                                                            type="number"
                                                            name="amount"
                                                            min="0"
                                                            oninput="validity.valid||(value='');"
                                                            value={this.state.user.linx_business_commission.fee_value}
                                                            onChange={event => {
                                                                event.persist();
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            linx_business_commission: {
                                                                                ...previousState.user.linx_business_commission,
                                                                                fee_value: parseInt(event.target.value) > 0 ?
                                                                                    event.target.value : "0"
                                                                            }
                                                                        }
                                                                    };
                                                                })
                                                            }} /> :
                                                            <input
                                                                className={"form-control col-md-7 col-xs-12"}
                                                                type="number"
                                                                name="amount"
                                                                min="0"
                                                                max="100"
                                                                value={this.state.user.linx_business_commission.fee_value}
                                                                onChange={event => {
                                                                    event.persist();
                                                                    this.setState(previousState => {
                                                                        return {
                                                                            ...previousState,
                                                                            user: {
                                                                                ...previousState.user,
                                                                                linx_business_commission: {
                                                                                    ...previousState.user.linx_business_commission,
                                                                                    fee_value: parseInt(event.target.value) > 0 ?
                                                                                        (parseInt(event.target.value) >= 100 ? "100" :
                                                                                            event.target.value) : "0"
                                                                                }
                                                                            }
                                                                        };
                                                                    })
                                                                }} />
                                                        }
                                                    </div >
                                                </div > */}
                                            {/* <div className={"ln_solid"}></div>
                                            </div >

                                            <div className={"form-group"}>
                                                <label className={"control-label col-lg-6"}>Any Delivery fee: {this.state.user.linxFee.toFixed(2)} (SAR)</label>
                                                <div className={"col-lg-6"}>
                                                    <button className={"btn btn-warning"} type="button" onClick={this._resetFee.bind(this)}>Reset Any Delivery fee</button>
                                                </div>
                                            </div > */}
                                            {/* <div className={"ln_solid"}></div> */}

                                            {
                                                this.state.user.type === 'user' && <div className={"row"}>
                                                    {/* <div className={"col-lg-6"}>
                                                    <div className={"form-group"}>
                                                        <Checkbox
                                                            checkboxClass="icheckbox_flat-green"
                                                            increaseArea="20%"
                                                            label=" In Progress"
                                                            disabled
                                                            checked={this.state.user.in_progress}
                                                            onChange={event => {
                                                                event.persist();
                                                                console.log(event.target.checked);
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            in_progress: !!event.target.checked
                                                                        }
                                                                    };
                                                                })}}
                                                        />
                                                    </div>
                                                </div> */}
                                                    {/* <div className={"col-lg-6"}>
                                                    <div className={"form-group"}>
                                                        <Checkbox
                                                            checkboxClass="icheckbox_flat-green"
                                                            increaseArea="20%"
                                                            label=" Visible"
                                                            disabled
                                                            checked={this.state.user.visible}
                                                            onChange={event => {
                                                                event.persist();
                                                                console.log(event.target.checked);
                                                                this.setState(previousState => {
                                                                    return {
                                                                        ...previousState,
                                                                        user: {
                                                                            ...previousState.user,
                                                                            visible: !!event.target.checked
                                                                        }
                                                                    };
                                                                })}}
                                                        />
                                                    </div>
                                                </div> */}
                                                </div>
                                            }
                                        </div >

                                    </div >

                                    {/* <div className={"ln_solid"}></div> */}
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                        </div>
                                        {/* <div className={"col-lg-6"}>
                                            {this.state.id !== 'new' && <div className={"form-group"}>
                                                <a className={"btn btn-warning"} href={"#/userBank/" + this.state.id}>Bank</a>
                                                {this.state.user.type === 'user' && <a className={"btn btn-warning"} href={"#/userVehicle/" + this.state.id}>Vehicle</a>}
                                                <a className={"btn btn-warning"} href={"#/userLicense/" + this.state.id}>License</a>
                                                <a className={"btn btn-warning"} href={"#/userMessages/" + this.state.id}>Messages</a>
                                                {this.state.user.type === 'business' && <a className={"btn btn-warning"} href={"#/businessCouriers/" + this.state.id}>Couriers</a>}
                                            </div>}
                                            {this.state.id !== 'new' && <div className={"form-group"}>
                                                {this.state.user.type === 'user' && <a className={"btn btn-warning"} href={"#/userOrders/" + this.state.id + "/false"}>Customer orders</a>}
                                                {this.state.user.type === 'user' && <a className={"btn btn-warning"} href={"#/userOrders/" + this.state.id + "/true"}>Courier orders</a>}
                                            </div>}
                                        </div> */}
                                    </div>

                                </form >
                            </div >
                        </div >
                    </div >
                </div >

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}
