import React, { Component } from 'react';
import { render } from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios/index";
import LINX from '../../assests/main-logo.png';

var regExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)

export default class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmpassword: '',
            isError: {
                password: '',
                confirmpassword: ''
            }
        };
    }

    componentDidMount() {
        this.resetToken();
    }


    async submit() {
        this.setState(previousState => {
            return {
                ...previousState,
                // loading: true
            };
        });

        const { password, confirmpassword, isError } = this.state
        password === "" ? isError.password = "Password is required" : ""
        confirmpassword === "" ? isError.confirmpassword = "Confirm Password is required" : ""

        try {
            this.state = {
                params: {
                    password: this.state.password,
                    confirm_password: this.state.confirmpassword
                }
            }
            const token = this.props.token;
            let response = await axios.post(`${"../../api/setPassword"}/${token}`, this.state);
            if (!!response.data.result.error && response.data.result.error.length > 0) {
                response.data.result.error.map((err, i) => {
                    if (err.message === "Password and confirm password doesn't match") {
                        toast.error(err.message);
                    }
                });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        password: '',
                        confirmpassword: ''
                    };
                }, () => {
                    toast.success("Password has been changed successfully.");
                    setTimeout(() => {
                        document.location.href = '../../';
                    }, 1000);
                });
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                };
            });
        }
    }

    async resetToken() {
        this.setState(previousState => {
            return {
                ...previousState,
            };
        });

        try {
            this.state = {
                params: {},
            }
            const token = this.props.token;
            let response = await axios.post(`${"../../api/verifyToken"}/${token}`, this.state);
            if (!response.data.result.error.length > 0) {
                this.setState(previousState => {
                    return {
                        ...previousState,
                    };
                },
                );
            } else {
                toast.error("Invalid token.");
                setTimeout(() => {
                    document.location.href = '../../';
                }, 1000);
            }


        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                };
            });
        }

    }


    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        const { password, confirmpassword } = this.state

        let isError = { ...this.state.isError };
        switch (name) {
            case "password":
                password.length === 1 ? isError.password = " " :
                    isError.password =
                    regExp.test(value) ? "" : "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
                break;
            case "confirmpassword":

                confirmpassword.length === 1 ? isError.confirmpassword = " " :
                    isError.confirmpassword = regExp.test(value) ? "" : "Password must have at least eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    };

    render() {
        const { token } = this.props;
        const { isError, password, confirmpassword } = this.state
        confirmpassword === password ? "" : isError.confirmpassword = "Password does not match";
        return (
            <div className="form-signin">
                <img style={{ width: "150px", height: "52px", marginBottom: "49px", marginLeft: "63px" }} src={LINX} />
                <h2 className="form-signin-heading">Please enter new password </h2>
                <label className="sr-only">Password</label>
                <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    required
                    autoFocus
                    value={this.state.password}
                    onChange={event => {
                        event.persist();
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                password: event.target.value
                            };
                        })
                        this.formValChange(event)
                    }}
                />
                {isError.password.length > 0 && (
                    <p className='text-danger'>{isError.password}</p>
                )}
                <label className="sr-only">Confirm Password</label>
                <input
                    type="password"
                    name='confirmpassword'
                    className="form-control"
                    placeholder="Confirm Password"
                    required
                    value={this.state.confirmpassword}
                    onChange={event => {
                        event.persist();
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                confirmpassword: event.target.value
                            };
                        })
                        this.formValChange(event)
                    }}
                />
                {isError.confirmpassword.length > 0 && (
                    <p className='text-danger'>{isError.confirmpassword}</p>
                )}
                <button className="btn btn-lg btn-primary btn-block" onClick={() => {
                    this.submit();
                }}>Submit</button>
                <ToastContainer />
            </div>
        );
    }

}
