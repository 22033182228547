import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import { ITEMS_PER_PAGE } from "../components/Constants";
import moment from 'moment';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class UserMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { linxid: "" },
            loading: true,
            activePage: 1,
            total: 0,
            message: "",
            items: [],
            id: props.params.id
        };
    }

    componentWillMount() {
        this._loadList();
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`/admin/service_messages/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                params: {
                    user: this.state.id
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.items,
                total: response.data.data.total_count,
                user: response.data.data.user
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    async _save() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.post(`/admin/service_messages/`, {
                text: this.state.message,
                user: this.state.id
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        message: ''
                    };
                }, () => {
                    this._loadList();
                    toast.success("Message sent!");
                });
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td>{item.text}</td>
                <td>{moment(new Date(item.created_at)).format("DD-MM-YYYY hh:mm")}</td>
            </tr>
        );
    }

    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>
                            <h2>User Messages <small>{this.state.user.linxid} (Payment default from: {this.state.user.pay_type})</small></h2>
                            <div className={"clearfix"}></div>

                            <div className={"title_right"}>
                                <div className={"col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search"}>
                                    <div className={"input-group"}>
                                        <input
                                            type="text"
                                            className={"form-control"}
                                            placeholder="New message"
                                            value={this.state.message}
                                            onChange={event => {
                                                this.setState({
                                                    activePage: 1,
                                                    message: event.target.value
                                                });
                                            }}
                                        />
                                        <span className={"input-group-btn"}>
                                            <button className={"btn btn-default"} type="button" onClick={this._save.bind(this)}>Send</button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={"clearfix"}></div>
                        </div >
                        <div className={"x_content"}>

                            <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th>Message</th>
                                        <th>Date sent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.items.map((item, i) => {
                                        return this._renderRow(item, i);
                                    })}
                                </tbody>
                            </table>


                            <div className={"ln_solid"}></div>
                            <div className={"form-group"}>
                                <div className={"col-lg-6"}>
                                    <a className={"btn btn-primary"} href={"#/users/" + this.state.id}>Cancel</a>
                                </div>
                                <div className={"col-lg-6"}>

                                </div>
                            </div>

                        </div>
                    </div >

                    {!this.state.loading && <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.round(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div>
                    }

                </div>

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}