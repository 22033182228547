import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'icheck/skins/all.css';
import { history } from '../index';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';
export default class CancellationViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            n: !!props.params.n,
            user: {
                category_name: "",
                vehicle_type: "",
                charges: "",
                time_duration: "",
            },
            breadcrumbObj: [
                {
                    title: 'Cancellation Policy',
                    breadCrumbLink: './admin#/cancellationpolicy',
                    active: false
                },
                {
                    title: 'Edit',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        };
    }

    componentDidMount() {
        this._load();
    }

    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/cancellationPolicy/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.result.data
                    }
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            const apiData = {
                charges: Number(this.state.user.charges).toFixed(2),
                time_duration: this.state.user.time_duration

            }

            if (this.state.user.charges > 100 || this.state.user.charges < 1) {
                this.setState({
                    loading: false
                })
                toast.error("Please set charges min 1 to max 100.");
            } else {
                let response = await axios.post(`./admin/cancellationPolicy/${this.state.id}`, apiData);
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    if (this.state.id === "new") {
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                loading: false,
                                user: {
                                    ...previousState.user,
                                    ...response.data.result.data
                                },
                                id: response.data.result.data._id
                            };
                        }, () => {
                            toast.success("Item created!");
                            history.push('/users/' + response.data.data._id);
                        });
                    } else {
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                loading: false,
                                user: {
                                    ...previousState.user,
                                    ...response.data.result.data
                                },
                                id: response.data.result.data._id
                            };
                        }, () => {
                            toast.success("Cancellation policy updated.");
                            history.push('/cancellationpolicy')
                        });
                    }
                }
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    render() {
        const name = this.state.user.vehicle_type
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                                <div className='col-md-2' style={{ marginRight: '125px' }}>
                                    <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} />
                                        {name}
                                        {this.state.user._id && <b> {this.state.user.linxid} </b>}
                                        {/* {this.state.user._id && <small> (Payment default from: {this.state.user.pay_type})</small>} */}
                                        {!this.state.user._id && <small>New</small>}
                                    </h2>
                                </div>
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left"}>

                                    <div className={"row"}>

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            {/* <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Min Duration	</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.user.time_duration}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        time_duration: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Charges(%)</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type='number'
                                                        value={this.state.user.charges}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        charges: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                            <Button onClick={this._save.bind(this)} bsStyle="success">Submit</Button>
                                        </div>
                                    </div>

                                </form >
                            </div >
                        </div >
                    </div >
                </div >

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}
