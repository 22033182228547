import 'gentelella/vendors/datatables.net-bs/css/dataTables.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-buttons-bs/css/buttons.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-fixedheader-bs/css/fixedHeader.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-responsive-bs/css/responsive.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-scroller-bs/css/scroller.bootstrap.min.css';

import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'datatables.net-fixedheader';
import 'datatables.net-keytable';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'datatables.net-scroller';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ITEMS_PER_PAGE } from "../components/Constants";
import { Link } from 'react-router';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class DriverPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            status: "active",
            type: "business",
            q: "",
            items: [],
            breadcrumbObj:[
                {
                    title:'Drivers',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentWillMount() {
        this._loadList();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

    }

    componentDidMount() {
        $(document).trigger('TInitPanelToolBox');
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`./admin/users/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                params: {
                    // status: this.state.status,
                    type: this.state.type,
                    q: this.state.q,
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.items,
                total: response.data.data.total_count
            });

        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async deleteItem(id) {
        if (confirm('Delete this item?')) {
            this.setState({
                loading: true
            });
            try {
                let response = await axios.get(`./admin/user/delete/${id}`, {
                    params: {}
                });
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                }
                this._loadList();
            } catch (e) {
                if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                    e.response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    toast.error(e.message);
                }
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false
                    };
                });
            }
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td className={"text-center"}>{item.first_name}</td>
                {/* <td className={"text-center"}>{item.last_name}</td> */}
                <td className={"text-center"}>{item.email}</td>
                <td className={"text-center"}>{item.full_number}</td>
                <td className={"text-center"}>{item.status === "blocked" ? "Inactive": item.status === "active" ? "Active" : "Review"}</td>
                <td className={"text-center"}>
                <Link  className="td-link" to={`/drivers/trips/${item._id}`}>{item.orderCount}<i class="fa fa-external-link" aria-hidden="true"></i></Link>
                </td>
                {/* <td className={"text-center"} style={{ width: 70 }}>{item.visible && <i className={"fa fa-check"} />}</td>
                <td className={"text-center"} style={{ width: 70 }}>{item.in_progress && <i className={"fa fa-check"} />}</td>
                <td className={"text-center"} style={{ width: 70 }}>{item.socket_ids.length > 0 && <i className={"fa fa-check"} />}</td> */}
                <td className={"text-center"}>
                    <Link to={`/drivers/${item._id}`}><i className={"fa fa-pencil"} /></Link>
                    <Link style={{marginLeft:"10px"}} to={`/driverview/${item._id}`}><i className={"fa fa-eye"} /></Link>
                    <Button bsStyle="link" onClick={() => { this.deleteItem(item._id); }} ><i className={"fa fa-trash"} /></Button>
                </td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <div>
                <h2 style={{ marginTop: '10px' }}><CustomBreadCrumbs data={this.state.breadcrumbObj}/>
                    {/* <Button bsStyle="success" href={"#users/new"}>Create</Button> */}
                </h2>
                {/* <div className={"col-md-2"} style={{margin: "5px 0 6px"}}>
                <select
                   className={"form-control"}
                   value={this.state.status}
                   onChange={event => {
                       this.setState({
                           status: event.target.value,
                           activePage: 1,
                       }, () => {
                           this._loadList()
                       });
                   }}
                    >
                    <option>Active</option>
                    <option>Inactive</option>
                </select>
              </div> */}
                {/* <div className={"col-md-2"} style={{margin: "5px 0 6px"}}>
                <select
                   className={"form-control"}
                   value={this.state.type}
                   onChange={event => {
                       this.setState({
                           type: event.target.value,
                           activePage: 1,
                       }, () => {
                           this._loadList()
                       });
                   }}
                    >
                    <option>all</option>
                    <option>customers</option>
                    <option>couriers</option>
                </select>
              </div> */}
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>

                                {this._renderTitle()}

                                <div className={"title_right"}>
                                    <div className={"col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search"}>
                                        <div className={"input-group"}>
                                            <input
                                                type="text"
                                                className={"form-control"}
                                                placeholder="Search by Name or Mobile..."
                                                value={this.state.q}
                                                onChange={event => {
                                                    this.setState({
                                                        activePage: 1,
                                                        q: event.target.value
                                                    }, () => {
                                                        this._loadList()
                                                    });
                                                }}
                                            />
                                            <span className={"input-group-btn"}>
                                                <button className={"btn btn-default"} type="button" onClick={() => {
                                                    this.setState({
                                                        activePage: 1,
                                                        q: ""
                                                    }, () => {
                                                        this._loadList()
                                                    });
                                                }}>Cancel</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                            {this.state.total > 0 ? (
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th className={"text-center"}>Full Name</th>
                                            {/* <th className={"text-center"}>Last Name</th> */}
                                            <th className={"text-center"}>Email</th>
                                            <th className={"text-center"}>Mobile</th>
                                            <th className={"text-center"}>Status</th>
                                            <th className={"text-center"}>Trips</th>
                                            <th className={"text-center"}>Action</th>
                                            {/* <th className={"text-center"} style={{ width: 70 }}>Visible</th>
                                            <th className={"text-center"} style={{ width: 70 }}>In Progress</th>
                                            <th className={"text-center"} style={{ width: 70 }}>Socket</th> */}
                                            {/* <th></th> */}
                                        </tr>
                                    </thead>
                                   
                                    <tbody>
                                        {this.state.items.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}      
                                    </tbody>
                                   
                                </table>
                           ):
                           <h4 style={{marginLeft:"700px"}}>No Data Found</h4>
                        }
                            </div>
                        </div>

                        {!this.state.loading && <div>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                items={Math.round(this.state.total / ITEMS_PER_PAGE)}
                                maxButtons={5}
                                activePage={this.state.activePage}
                                onSelect={this.handlePagination.bind(this)}
                            />
                        </div>}

                    </div>

                    {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoaderComponent loading={this.state.loading}/>
                    </div>}
                </div>
            </div>
        
        );
    }

}