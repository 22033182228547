export function setConnect(connect) {
    return {
        type: 'SOCKET_CONNECT',
        payload: {
            connect: connect
        }
    };
}

export function setToken(token) {
    return {
        type: 'SOCKET_TOKEN',
        payload: {
            token: token
        }
    };
}
