import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import { ITEMS_PER_PAGE } from "../components/Constants";
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class ManageHelp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            q: "",
            data: [],
            breadcrumbObj: [
                {
                    title: 'Help',
                    breadCrumbLink: '',
                    active: true
                }
            ]
        };
    }

    componentWillMount() {
        this._loadList();
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`./admin/help_questions`);
            if (!!response.data.error && response.data.error.length > 0) {
                response.data.error.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                data: response.data.data,
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    async deleteItem(id) {
        if (confirm('Delete this item?')) {
            this.setState({
                loading: true
            });
            try {
                let response = await axios.post(`./admin/delete_help_question/${id}`, {
                    params: {}
                });

                this._loadList();
            } catch (e) {
                this.setState({
                    loading: false
                });
                toast.error(e.message);
            }
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td>{item.question}</td>
                <td className={"text-center"}>
                    {/* <Link to={`/help/${item._id}`}><i className={"fa fa-trash"} /></Link> */}
                    <Button bsStyle="link" onClick={() => { this.deleteItem(item._id); }} ><i className={"fa fa-trash"} /></Button>
                </td>
            </tr>
        );
    }

    _renderTitle() {
        return (
            <div>
                <div className='col'>
                    <h2><CustomBreadCrumbs data={this.state.breadcrumbObj} /></h2>
                </div>
                <div className='col-md-6' style={{ marginTop: '14px' }}>
                    <Button bsStyle="success" href={"#help/new"}>Create</Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>

                            {this._renderTitle()}

                            <div className={"clearfix"}></div>
                        </div>
                        <div className={"x_content"}>

                            <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} style={{ maxWidth: "450px" }} cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th style={{ width: "300px" }}>Question</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => {
                                        return this._renderRow(item, i);
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                <RingLoaderComponent loading={this.state.loading}/>
                </div>}
            </div>
        );
    }

}