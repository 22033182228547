import React, {Component} from 'react';
import { render } from 'react-dom';
import UserPage from "./UserPage";
import {ITEMS_PER_PAGE} from "../components/Constants";
import {Button, Modal, Alert, Pagination} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';

export default class NewCouriers extends UserPage {

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`/admin/newUsers/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                params: {
                    q: this.state.q,
                    sortKey: "",
                    reverse: ""
                }
            });
            if(!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.items,
                total: response.data.data.total_count
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    _renderTitle() {
        return (
            <h2>New couriers</h2>
        );
    }

}