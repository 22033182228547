import React, { Component } from "react";
import { Breadcrumb } from "react-bootstrap"

export default class CustomBreadCrumbs extends Component {
    constructor(props){
        super(props);
    }
    
    render() {
        
        return (
            <div>
            <Breadcrumb style={{  backgroundColor:"#101010", color:"white" }}>
            {this.props.data.map((linkItem, index) => {
                return  <Breadcrumb.Item className={linkItem.active ? 'active breadcrumbactive' : 'noactive'} style={{  backgroundColor:"#101010"}} key={index} href={`${linkItem.breadCrumbLink}`} >{linkItem.title}</Breadcrumb.Item>
            })}
            </Breadcrumb>
        </div>

    )
    }
}