import React, {Component} from 'react';
import TotalStatInfo from "./homePage/TotalStatInfo";
import ActivityUsers from "./homePage/ActivityUsers";
import NowData from "./homePage/NowData";

export default class MainContent extends Component{

    render() {
        return (
            <div>

                <TotalStatInfo/>

                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                       <ActivityUsers/>
                    </div>

                </div>
                <br />

                <NowData/>

            </div>
        );
    }

}