import 'gentelella/vendors/datatables.net-bs/css/dataTables.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-buttons-bs/css/buttons.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-fixedheader-bs/css/fixedHeader.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-responsive-bs/css/responsive.bootstrap.min.css';
import 'gentelella/vendors/datatables.net-scroller-bs/css/scroller.bootstrap.min.css';

import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'datatables.net-fixedheader';
import 'datatables.net-keytable';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'datatables.net-scroller';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ITEMS_PER_PAGE } from "../components/Constants";
import { Link } from 'react-router';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class ManageCommission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            total: 0,
            status: "active",
            type: "user",
            q: "",
            items: [],
            breadcrumbObj:[
                {
                    title:'Manage Commission',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentWillMount() {
        this._loadList();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }

    componentDidMount() {
        $(document).trigger('TInitPanelToolBox');
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`./admin/commission`, {
                
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.result.error.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.result.data,
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tbody key={i}>
            <tr>
                <td className={"text-center"}>{item.category_name}</td>
                <td className={"text-center"}>{item.driver_share}%</td>
                <td className={"text-center"}>{item.admin_share}%</td>
                <td className={"text-center"}>{item.service_charge}%</td>
                <td className={"text-center"}>{item.tax}%</td>
                <td className={"text-center"}>
                    <Link to={`/managecommission/${item._id}`}><i className={"fa fa-pencil"} /></Link>
                </td>
            </tr>
            </tbody>
        );
    }

    _renderTitle() {
        return (
            <div>
                <h2 style={{ marginTop: '10px' }}><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>

                                {this._renderTitle()}

                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                            {/* {this.state.total > 0 ? ( */}
                                <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th className={"text-center"}>Categories</th>
                                            <th className={"text-center"}>Driver's Share</th>
                                            <th className={"text-center"}>Admin's Share</th>
                                            <th className={"text-center"}>Service Charges</th>
                                            <th className={"text-center"}>Tax</th>
                                            <th className={"text-center"}>Action</th>
                                        </tr>
                                    </thead>
                                        {this.state.items.length && this.state.items.map((item, i) => {
                                            return this._renderRow(item, i);
                                        })}
                                </table>
                                   {/* ):
                                   <h4 style={{marginLeft:"700px"}}>No Data Found</h4>
                                } */}
                            </div>
                        </div>
                    </div>

                    {this.state.loading && <div className={"css-jxiqlq-wrap"}>
                    <RingLoaderComponent loading={this.state.loading}/>
                    </div>}
                </div>
            </div>
        );
    }

}