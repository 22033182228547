import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import { ITEMS_PER_PAGE } from "../components/Constants";
import moment from 'moment';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class UserOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { linxid: "" },
            loading: true,
            activePage: 1,
            total: 0,
            message: "",
            items: [],
            id: props.params.id,
            courier: props.params.courier === "true"
        };
    }

    componentWillMount() {
        this._loadList();
    }

    async _loadList() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`/admin/user_orders/${ITEMS_PER_PAGE}/${this.state.activePage}`, {
                params: {
                    user: this.state.id,
                    courier: this.state.courier
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            this.setState({
                loading: false,
                items: response.data.data.items,
                total: response.data.data.total_count,
                user: response.data.data.user
            });

        } catch (e) {
            this.setState({
                loading: false
            });
            toast.error(e.message);
        }
    }

    handlePagination(eventKey) {
        this.setState({
            activePage: eventKey
        }, () => {
            this._loadList()
        });
    }

    _renderRow(item, i) {
        return (
            <tr key={i}>
                <td>{item.orderId}</td>
                <td>{item.owner}</td>
                <td>{item.courier}</td>
                <td>{item.pickup_location}</td>
                <td>{item.drop_off_location}</td>
                <td>{item.route.toFixed(2)}</td>
                <td>{item.number_of_parcel}</td>
                <td>{moment(new Date(item.accept_time - item.created_at)).format("mm:ss")}</td>
                <td>{moment(new Date(item.accept_time)).format("DD-MM-YYYY")}</td>
                <td>{item.pay_type}</td>
                <td>{item.amount.toFixed(2)}</td>
                <td>{item.status}</td>
                <td> <Link to={`/order/${item._id}`}><i className={"fa fa-eye"} /></Link></td>
            </tr>
        );
    }

    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                    <div className={"x_panel"}>
                        <div className={"x_title"}>
                            {!this.state.courier && <h2>User orders (Customer) <small>{this.state.user.linxid} </small></h2>}
                            {this.state.courier && <h2>User orders (Courier) <small>{this.state.user.linxid} </small></h2>}
                            <div className={"clearfix"}></div>

                            <div className={"title_right"}>
                                <div className={"col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search"}>
                                    <div className={"input-group"}>

                                    </div>
                                </div>
                            </div>

                            <div className={"clearfix"}></div>
                        </div >
                        <div className={"x_content"}>

                            <table id="datatable-responsive" className={"table table-striped table-bordered dt-responsive nowrap"} cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Owner</th>
                                        <th>Courier</th>
                                        <th>Pickup location</th>
                                        <th>Drop off location</th>
                                        <th>Distance (km)</th>
                                        <th>Number of parcels</th>
                                        <th>Accepted time (min)</th>
                                        <th>Date</th>
                                        <th>Payment Method</th>
                                        <th>Amount (SAR)</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.items.map((item, i) => {
                                        return this._renderRow(item, i);
                                    })}
                                </tbody>
                            </table>


                            <div className={"ln_solid"}></div>
                            <div className={"form-group"}>
                                <div className={"col-lg-6"}>
                                    <a className={"btn btn-primary"} href={"#/users/" + this.state.id}>Cancel</a>
                                </div>
                                <div className={"col-lg-6"}>

                                </div>
                            </div>

                        </div>
                    </div >

                    {!this.state.loading && <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            items={Math.round(this.state.total / ITEMS_PER_PAGE)}
                            maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.handlePagination.bind(this)}
                        />
                    </div>
                    }

                </div>

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}