import React, { Component } from 'react';

export default class Footer extends Component{

    render(){
        return(
            <footer>
            <div className="" style={{color:'#169F85'}}>
                <p>Copyright © 2022 Linx - Life On Demand. All rights reserved</p>
            </div>
        </footer>
        );
    }
}