import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router';
import 'icheck/skins/all.css';
import { Checkbox, Radio } from 'react-icheck';
import { history } from '../index';
import CustomBreadCrumbs from '../components/CustomBreadcrumbs';
import RingLoaderComponent from '../components/RingLoaderComponent';

export default class DriverViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.params.id,
            n: !!props.params.n,
            user: {
                first_name: "",
                // last_name: "",
                email: "",
                pay_type: "",
                phone: "",
                status: "active",
                // role: "user",
                // type: "user",
                // new_password: "",
                new_balance: 0,
                new_courierLimit: 0,
                visible: false,
                in_progress: false,
                linxFee: 0,
                // avatar_url: "",
                linx_business_commission: {
                    fee_type: 'amount',
                    fee_value: 0
                },
                vehicle:{
                    model:" ",
                    name:" ",
                    number:" ",
                    color:" ",
                    image_front:" ",
                    image_back:" ",
                    image_side:" ",
                    license_number:" ",
                    images_insurance: "",
                    category:{
                        name: ""
                    },
                    type:{
                        name: "",
                    },
                },
                license:{
                    DOB:"",
                    front_image:" ",
                    back_image:" "
                }
            },
            breadcrumbObj:[
                {
                    title:'Drivers',
                    breadCrumbLink:'./admin#/drivers',
                    active:false
                },
                {
                    title:'Driver Details',
                    breadCrumbLink:'',
                    active:true
                }
            ]
        };
    }

    componentDidMount() {
        $('input.flat').iCheck({
            checkboxClass: 'icheckbox_flat-green',
            radioClass: 'iradio_flat-green'
        });

        if (!!this.state.id && this.state.id !== "new") {
            this._load();
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }
    
    async _load() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            let response = await axios.get(`./admin/user/${this.state.id}`, {
                params: {}
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            }

            if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false,
                    user: {
                        ...previousState.user,
                        ...response.data.data
                    }
                };
            });

        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _preUpload(event) {
        if (this.fileInput.files.length === 0) {
            return;
        }
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        let formdata = new FormData();
        formdata.append('image', this.fileInput.files[0]);
        try {
            let response = await axios.post(`./admin/ajax/uploads_img`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                this.fileInput.value = '';
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false,
                        user: {
                            ...previousState.user,
                            avatar_url: response.data.data.image_url,
                            avatar: response.data.data.image
                        }
                    };
                });
            }
        } catch (e) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
            toast.error(e.message);
        }
    }

    async _save() {
        this.setState(previousState => {
            return {
                ...previousState,
                loading: true
            };
        });
        try {
            this.state.user.new_courierLimit = parseFloat(this.state.user.new_courierLimit);
            this.state.user.new_balance = parseFloat(this.state.user.new_balance);
            this.state.user.linx_business_commission.fee_value = parseInt(this.state.user.linx_business_commission.fee_value);
            let response = await axios.post(`./admin/user/`, this.state.user);
            if (!!response.data.errors && response.data.errors.length > 0) {
                response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                if (this.state.id === "new") {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item created!");
                        history.push('/users/' + response.data.data._id);
                    });
                } else {
                    if (!!response.data.data.avatar && response.data.data.avatar !== "") {
                        response.data.data.avatar_url = "/uploads/user_avatars/" + response.data.data.avatar;
                    }
                    response.data.data.visible = !!response.data.data.visible;
                    response.data.data.in_progress = !!response.data.data.in_progress;
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            },
                            id: response.data.data._id
                        };
                    }, () => {
                        toast.success("Item updated!");
                    });
                }
            }
        } catch (e) {
            if (!!e.response && !!e.response.data.errors && e.response.data.errors.length > 0) {
                e.response.data.errors.map((err, i) => {
                    toast.error(err);
                });
            } else {
                toast.error(e.message);
            }
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: false
                };
            });
        }
    }

    async _resetFee() {
        if (confirm("Reset Any Delivery fee ?")) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    loading: true
                };
            });
            try {
                let response = await axios.post(`./admin/user/reset_linx_fee/${this.state.id}`, {});
                if (!!response.data.errors && response.data.errors.length > 0) {
                    response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            loading: false,
                            user: {
                                ...previousState.user,
                                ...response.data.data
                            }
                        };
                    }, () => {
                        toast.success("LINX fee reset!");
                    });
                }
            } catch (e) {
                if (!!e.response.data.errors && e.response.data.errors.length > 0) {
                    e.response.data.errors.map((err, i) => {
                        toast.error(err);
                    });
                } else {
                    toast.error(e.message);
                }
                this.setState(previousState => {
                    return {
                        ...previousState,
                        loading: false
                    };
                });
            }
        }
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"x_panel"}>
                            <div className={"x_title"}>
                            <div className='col-md-2'>
                                <h2><CustomBreadCrumbs data={this.state.breadcrumbObj}/></h2>    
                               
                                
                                <h2>
                                    {this.state.user._id && <b>Driver ID : {this.state.user.linxid} </b>}
                                    {!this.state.user._id && <h2 className='badge'>New Driver</h2>}
                                </h2>
                                </div>
                                <div className={"clearfix"}></div>
                            </div>
                            <div className={"x_content"}>
                                <br />
                                <form data-parsley-validate className={"form-horizontal form-label-left driver-vehicle-detail"}>

                                    <div className={"row"}>

                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Full Name</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        required="required"
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        value={this.state.user.first_name}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        first_name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Email</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="email"
                                                        value={this.state.user.email}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        email: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Phone</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="phone"
                                                        value={this.state.user.phone}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        phone: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Status</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <select
                                                        disabled
                                                        className={"form-control"}
                                                        value={this.state.user.status}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        status: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    >
                                                        <option value="active">Active</option>
                                                        <option value="blocked">Inactive</option>
                                                        <option value="review">Review</option>

                                                    </select >
                                                </div >
                                            </div >
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>DOB</label>
                                                <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="DOB"
                                                        value={this.state.user.license.DOB ?this.state.user.license.DOB : "-"}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        DOB: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                      </div >

                                      <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                          <h2>Vehicle Detail</h2>
                                          <div className='col-md-6'>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Model</label>
                                                <div className={"col-md-9 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="model"
                                                        value={this.state.user.vehicle.model ? this.state.user.vehicle.model : `-`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        model: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>Make</label>
                                                <div className={"col-md-9 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="name"
                                                        value={this.state.user.vehicle.name ? this.state.user.vehicle.name : `-`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        name: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"}>License Plate</label>
                                                <div className={"col-md-9 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="number"
                                                        value={this.state.user.vehicle.license_number ? this.state.user.vehicle.license_number : `-`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        license_number: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"} style={{marginRight:"10px"}}>Identification Number</label>
                                                <div className={"col-md-9 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="number"
                                                        value={this.state.user.vehicle.number ? this.state.user.vehicle.number : `-`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        number: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"} style={{marginRight:"10px"}}>Color</label>
                                                <div className={"col-md-9 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="number"
                                                        value={this.state.user.vehicle.color ? this.state.user.vehicle.color : `-`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        color: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"} style={{marginRight:"10px"}}>Type</label>
                                                <div className={"col-md-9 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="number"
                                                        value={this.state.user.vehicle.type ? this.state.user.vehicle.type.name : "-" }
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            type:{
                                                                                name: event.target.value
                                                                            } 
                                                                        }

                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-3 col-sm-3 col-xs-12"} style={{marginRight:"10px"}}>Compatibility</label>
                                                <div className={"col-md-9 col-sm-6 col-xs-12"}>
                                                    <input
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="text"
                                                        name="number"
                                                        value={this.state.user.vehicle.category ? this.state.user.vehicle.category.name : '-'}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.user,
                                                                        vehicle: {
                                                                            ...previousState.user.vehicle,
                                                                            category:{
                                                                                name:event.target.value
                                                                            } 
                                                                        }

                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-6 col-xs-6"}>
                                            {
                                                this.state.user.type === 'user' && <div className={"row"}>
                                                </div>
                                            }
                                        </div >

                                    </div >
                                    <div className='col-md-12'>
                                    <hr />
                                    <div className='col-md-12 vehicle-image'>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-12 col-sm-12 col-xs-12"}>Vehicle Front Image</label>
                                                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                                    <img
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="image"
                                                        name="image_front"
                                                        alt="Image not found"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://teja9.kuikr.com/images/car/default-cars.jpeg";
                                                          }}
                                                        src={this.state.user.vehicle.image_front ? this.state.user.vehicle.image_front : `https://teja9.kuikr.com/images/car/default-cars.jpeg`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        image_front: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-12 col-sm-12 col-xs-12"}>Vehicle Back Image</label>
                                                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                                    <img
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="image"
                                                        name="image_back"
                                                        alt="Image not found"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://teja9.kuikr.com/images/car/default-cars.jpeg";
                                                          }}
                                                        src={this.state.user.vehicle.image_back? this.state.user.vehicle.image_back : `https://teja9.kuikr.com/images/car/default-cars.jpeg`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        image_back: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-12 col-sm-12 col-xs-12"}>Vehicle Side Image</label>
                                                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                                    <img
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="image"
                                                        name="image_side"
                                                        alt="Image not found"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://teja9.kuikr.com/images/car/default-cars.jpeg";
                                                          }}
                                                        src={this.state.user.vehicle.image_side? this.state.user.vehicle.image_side : `https://teja9.kuikr.com/images/car/default-cars.jpeg`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        image_side: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-12 col-sm-12 col-xs-12"}>Vehicle Insurance Image</label>
                                                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                                    <img
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="image"
                                                        name="image_side"
                                                        alt="Image not found"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://teja9.kuikr.com/images/car/default-cars.jpeg";
                                                          }}
                                                        src={this.state.user.vehicle.images_insurance? this.state.user.vehicle.images_insurance : `https://teja9.kuikr.com/images/car/default-cars.jpeg`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.vehicle,
                                                                        images_insurance: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                         
                                        </div>
                                        <div className='col-md-12 vehicle-image'>
                                      
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-12 col-sm-12 col-xs-12"}>License Front Image</label>
                                                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                                    <img
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="image"
                                                        name="front_image"
                                                        alt="Image not found"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://us.123rf.com/450wm/cowpland/cowpland1603/cowpland160300009/55160387-stock-vector-document-vector-illustration-outline-stroke-business-icon-.jpg?ver=6";
                                                          }}
                                                        src={this.state.user.license.front_image ? this.state.user.license.front_image : `https://us.123rf.com/450wm/cowpland/cowpland1603/cowpland160300009/55160387-stock-vector-document-vector-illustration-outline-stroke-business-icon-.jpg?ver=6`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.license,
                                                                        front_image: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group"}>
                                                <label className={"control-label col-md-12 col-sm-12 col-xs-12"}>License Back Image</label>
                                                <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                                    <img
                                                        disabled
                                                        className={"form-control col-md-7 col-xs-12"}
                                                        type="image"
                                                        name="back_image"
                                                        alt="Image not found"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://us.123rf.com/450wm/cowpland/cowpland1603/cowpland160300009/55160387-stock-vector-document-vector-illustration-outline-stroke-business-icon-.jpg?ver=6";
                                                          }}
                                                        src={this.state.user.license.back_image ? this.state.user.license.back_image : `https://us.123rf.com/450wm/cowpland/cowpland1603/cowpland160300009/55160387-stock-vector-document-vector-illustration-outline-stroke-business-icon-.jpg?ver=6`}
                                                        onChange={event => {
                                                            event.persist();
                                                            this.setState(previousState => {
                                                                return {
                                                                    ...previousState,
                                                                    user: {
                                                                        ...previousState.license,
                                                                        back_image: event.target.value
                                                                    }
                                                                };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                       </div>
                                       



                                    </div>

                                    {/* <div className={"ln_solid"}></div> */}
                                    <div className={"form-group"}>
                                        <div className={"col-lg-6"}>
                                            <Button onClick={() => {
                                                history.go(-1);
                                            }} bsStyle="success">Cancel</Button>
                                        </div>
                                     </div>
                                  </div>
                                </form >
                            </div >
                        </div >
                    </div >
                </div >

                {
                    this.state.loading && <div className={"css-jxiqlq-wrap"}>
                        <RingLoaderComponent loading={this.state.loading}/>
                    </div>
                }
            </div >
        );
    }

}
