import openSocket from 'socket.io-client';
import axios from "axios/index";
import { store } from "../index";
import { setConnect, setToken } from "../actions/socketActions";
import { addNotification } from "../actions/notificationActions";
import GetSocketUrl from './GetSocketUrl';
import { toast } from 'react-toastify';


export class SocketService {

    static socket = null;
    static init() {
        if (SocketService.socket === null) {
            SocketService.socket = openSocket(GetSocketUrl(window.location.href));
            SocketService._init();
        }
    }

    static _init() {

        SocketService.socket.on('connect', () => {
            console.log('socket connect');
        });

        SocketService.socket.on('init', async () => {
            console.log('socket init');
            try {
                let response = await axios.get(`./admin/getToken/`, {
                    params: {
                    }
                });
                if (response.data.data.token) {
                    store.dispatch(setToken(response.data.data.token));
                    SocketService.socket.emit('admin_auth', { token: response.data.data.token });
                }
            } catch (e) {
                toast.error(e)
            }
        });

        SocketService.socket.on('auth', () => {
            console.log('socket auth');
            store.dispatch(setConnect(true));
        });

        SocketService.socket.on('adminNotification', (data) => {
            store.dispatch(addNotification(data));
        });

        SocketService.socket.on('disconnect', () => {
            console.log('socket disconnect');
            store.dispatch(setConnect(false));
        });
    }

    static getSocket() {
        SocketService.init();
        return SocketService.socket;
    }
}
