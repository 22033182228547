import 'jquery';
import 'moment';
import 'gentelella/vendors/fastclick/lib/fastclick.js';
import 'gentelella/vendors/nprogress/nprogress.js';
import 'gentelella/vendors/Chart.js/dist/Chart.min.js';
import 'gentelella/vendors/gauge.js/dist/gauge.min.js';
import 'gentelella/vendors/iCheck/icheck.min.js';

import 'gentelella/vendors/Flot/jquery.flot.js';
import 'gentelella/vendors/Flot/jquery.flot.pie.js';
import 'gentelella/vendors/Flot/jquery.flot.time.js';
import 'gentelella/vendors/Flot/jquery.flot.stack.js';
import 'gentelella/vendors/Flot/jquery.flot.resize.js';
import 'gentelella/vendors/flot.orderbars/js/jquery.flot.orderBars.js';
import 'gentelella/vendors/flot-spline/js/jquery.flot.spline.min.js';

import '../../js/admin/ui/theme.js';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';

import 'gentelella/vendors/bootstrap/dist/css/bootstrap.min.css';
import 'gentelella/vendors/font-awesome/css/font-awesome.min.css';
import 'gentelella/vendors/nprogress/nprogress.css';
import 'gentelella/vendors/iCheck/skins/flat/green.css';
import 'gentelella/vendors/bootstrap-progressbar/css/bootstrap-progressbar-3.3.4.min.css';
import 'gentelella/vendors/bootstrap-daterangepicker/daterangepicker.css';
import 'gentelella/build/css/custom.min.css';

import 'react-select/dist/react-select.css';
import './styles/main.css';
import './styles/custom.css';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import reducer from './reducers';

export const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
export const history = syncHistoryWithStore(hashHistory, store);

import MainPage from "./pages/MainPage";
import LeftMenu from "./components/LeftMenu";
import TopMenu from "./components/TopMenu";
import Footer from './components/Footer.js';
import UserPage from "./pages/UserPage";
import UserFormPage from "./pages/UserFormPage";

import UserMessages from "./pages/UserMessages";
import NewCouriers from "./pages/NewCouriers";
import PromoPage from "./pages/PromoPage";
import ManufacturerPage from './pages/ManufacturerPage';
import ManufacturerFormPage from "./pages/ManufacturerFormPage";
import PromoFormPage from "./pages/PromoFormPage";

import AdminProfilePage from "./pages/AdminProfilePage";
import OrdersReportPage from "./pages/reports/OrdersReportPage";
import UserOrders from "./pages/UserOrders";
import OrderDetailPage from "./pages/OrderDetailPage";
import { SocketService } from "./components/SocketService";
import { removeNotifications } from "./actions/notificationActions";
import NotificationBar from "./components/NotificationBar";
import AdminLogin from "./components/AdminLogin";
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/homePage/ResetPassword';
import DriverPage from './pages/DriverPage';
import ViewPage from './pages/CustomerViewPage';
import DriverFormPage from './pages/DriverFormPage.js';
import DriverViewPage from './pages/DriverViewPage.js';
import CustomerTrips from './pages/CustomersTrips';
import DriverTrips from './pages/DriverTrips.js';
import TripDetailPage from './pages/TripsDetailPage.js';
import PromoViewPage from './pages/PromoViewPage.js';
import ManageCommission from './pages/ManageCommission.js';
import EditManageCommission from './pages/EditManageCommission.js';
import ManageReviews from './pages/ManageReviews.js';
import ManageReviewView from './pages/ManageReviewView';
import LinxSite from './components/LinxSite.js';
import Privacypolicy from './components/PrivacyPolicy.js';
import TermsCondition from './components/homePage/TermsCondition.js';
import CancellationPolicy from './pages/CancellationPolicy.js';
import CancellationViewPage from './pages/CancellationViewPolicy.js';
import ManageHelp from './pages/ManageHelp.js';
import AddHelp from './pages/AddHelp.js';
import ManageEarnings from './pages/ManageEarnings'
import ManageDriverEarning from './pages/ManageDriverEarnings.js';
import ManageDriverEarningList from './pages/ManageDriverEarningList.js';
import NotificationList from './pages/NotificationList.js';
import NotificationCreate from './pages/NotificationCreate.js';
import AssignDriversList from './pages/AssignDriversList.js';
import ManageSearchRadius from './pages/ManageSearchRadius.js';
import EditSearchRadius from './pages/EditSearchRadius.js';
import DriverCancelOrder from './pages/DriverCancelOrder.js';
import SmallBusinessOrderReportPage from './pages/reports/SmallBusinessOrderReportPage.js';
import SmallBusinessSubOrderList from './pages/reports/SmallBusinessSubOrderList.js';


const getlocalStorage = (key) => {
    return localStorage.getItem(key)
}


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            show: false,
            selectedUser: null,
            lightboxIsOpen: false,
            notifications: [],
            // resetToken:''
        };
        SocketService.init();
    }

    componentDidMount() {
        if (!getlocalStorage("token")) {
            document.location.href = './';
        }
        $(document).trigger('TTinit');
        $("#preload").fadeOut('slow');
    }


    render() {
        return (
            <div className="App">
                <LeftMenu />
                <TopMenu />
                <div className={"right_col"} role="main">

                    {/* <NotificationBar /> */}

                    <Provider store={store}>
                        <Router history={history}>
                            <Route path="/" component={MainPage} />
                            <Route path="/customers" component={UserPage} />
                            <Route path="/customers/orders/:id" component={CustomerTrips} />
                            <Route path="/drivers/trips/:id" component={DriverTrips} />

                            <Route path="/drivers" component={DriverPage} />
                            <Route path="/admin/profile" component={AdminProfilePage} />
                            <Route path="/users/:id" component={UserFormPage} />
                            <Route path="/drivers/:id" component={DriverFormPage} />
                            <Route path="/view/:id" component={ViewPage} />
                            <Route path="/driverview/:id" component={DriverViewPage} />
                            <Route path="/users/:id/:n" component={UserFormPage} />
                            <Route path="/userMessages/:id" component={UserMessages} />
                            <Route path="/userOrders/:id/:courier" component={UserOrders} />

                            <Route path="/newCouriers" component={NewCouriers} />

                            <Route path="/promo" component={PromoPage} />
                            <Route path="/promo/:id" component={PromoFormPage} />
                            <Route path="/promoview/:id" component={PromoViewPage} />

                            <Route path="/managecommission" component={ManageCommission} />
                            <Route path="/managecommission/:id" component={EditManageCommission} />


                            <Route path="/cancellationpolicy" component={CancellationPolicy} />
                            <Route path="/cancellationpolicy/:id" component={CancellationViewPage} />


                            <Route path="/manufacturers" component={ManufacturerPage} />
                            <Route path="/manufacturers/:id" component={ManufacturerFormPage} />



                            <Route path="/reports/orders" component={OrdersReportPage} />
                            <Route path="/reports/businessOrders" component={SmallBusinessOrderReportPage} />
                            <Route path="/reports/businessSubOrders/:id" component={SmallBusinessSubOrderList} />
                            {/* <Route path="/reports/orders/assigndriver/:id/:lat/:long" component={DriverAssign} /> */}
                            <Route path="/reports/orders/assigndriver/:id" component={AssignDriversList} />


                            <Route path="/managereviews" component={ManageReviews} />
                            <Route path="/managereviews/:id" component={ManageReviewView} />


                            <Route path="/order/:id" component={OrderDetailPage} />
                            <Route path="/trip/:id" component={TripDetailPage} />
                            <Route path="/driver-cancel-order" component={DriverCancelOrder} />


                            <Route path="/help" component={ManageHelp} />
                            <Route path="/help/new" component={AddHelp} />
                            <Route path="/settingradius" component={ManageSearchRadius} />
                            <Route path="/editradius/:radius/:adminradius/:id" component={EditSearchRadius} />

                            <Route path="/manageearnings" component={ManageEarnings} />
                            <Route path="/managedriverearnings" component={ManageDriverEarning} />
                            <Route path="/managedriverearninglist" component={ManageDriverEarningList} />

                            <Route path="/notificationList" component={NotificationList} />
                            <Route path="/notificationCreate" component={NotificationCreate} />



                        </Router>
                    </Provider>
                </div>
                <ToastContainer />
                <Footer />

            </div>

        );
    }

}

if (document.getElementById('root')) {
    render(
        <App />,
        document.getElementById('root')
    );
}

if (document.getElementById('adminLogin')) {
    render(
        <AdminLogin />,
        document.getElementById('adminLogin')
    );
}
if (document.getElementById('forgotpassword')) {
    render(
        <ForgotPassword />,
        document.getElementById('forgotpassword')
    );
}
if (document.getElementById('resetpassword')) {
    var resetToken = document.getElementById("resetpassword").getAttribute('data-id')
    render(
        <ResetPassword token={resetToken} />,
        document.getElementById('resetpassword')
    );
}
if (document.getElementById('linxsite')) {
    render(
        <LinxSite />,
        document.getElementById('linxsite')
    );
}
if (document.getElementById('privacypolicy')) {
    render(
        <Privacypolicy />,
        document.getElementById('privacypolicy')
    );
}
if (document.getElementById('termscondition')) {
    render(
        <TermsCondition />,
        document.getElementById('termscondition')
    );
}